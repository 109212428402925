import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { AppDispatch, AppState } from '../../config/storeConfig';
import { RequestState } from '../../requestState';
import { getRolesAPI } from '../role/roleAPI';
import User from '../user/user';
import Role from './role';

export interface UserRoleState extends RequestState {
  data: {
    roles: Array<Role>;
    user: User;
  } | null;
}

export interface RolesListState extends RequestState {
  data: Array<Role> | null;
}

export interface RoleState extends RequestState {
  data: Array<Role>;
}

const initRoleState: RoleState = {
  loading: false,
  errorMsg: null,
  data: [],
};

const getRoles = createAsyncThunk<
  Array<Role>,
  boolean,
  { dispatch: AppDispatch; state: AppState; rejectValue: string }
>('role/getRoles', async (showPermission, { rejectWithValue }) => {
  try {
    return await getRolesAPI(showPermission);
  } catch (reason) {
    return rejectWithValue(reason);
  }
});
// const getUserRoles = createAsyncThunk<
//   { roles: Array<Role>; user: User },
//   { showPermissions: boolean; uuid: string },
//   { dispatch: AppDispatch; state: AppState; rejectValue: string }
// >(
//   'role/getUserRoles',
//   async ({ uuid, showPermissions }, { rejectWithValue }) => {
//     try {
//       return await getUserRolesAPI(uuid, showPermissions);
//     } catch (reason) {
//       return rejectWithValue(reason);
//     }
//   }
// );

const roleSlice = createSlice({
  name: 'role',
  initialState: initRoleState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoles.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getRoles.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload ?? 'An error has occured.';
      });
    // .addCase(getUserRoles.pending, (state, action) => {
    //   state.user.loading = true;
    // })
    // .addCase(getUserRoles.fulfilled, (state, action) => {
    //   state.user.loading = false;
    //   state.user.data = action.payload;
    // })
    // .addCase(getUserRoles.rejected, (state, action) => {
    //   state.user.loading = false;
    //   state.user.errorMsg = action.payload ?? 'An error has occured.';
    // });
  },
});

export const selectRolesList = createSelector(
  (state: AppState) => state.roles.data,
  (list) => {
    return list/* .map((role) => {
      const roleName = role.name;
      const split = roleName.split('_');
      const formatted =
        split.length > 1
          ? split[0].charAt(0).toUpperCase() +
            split[0].slice(1) +
            ' ' +
            split[1].charAt(0).toUpperCase() +
            split[1].slice(1)
          : split[0].charAt(0).toUpperCase() + split[0].slice(1);
      return { ...role, name: formatted };
    }); */
  }
);

// export const selectUserRoles = createSelector(
//   (state: AppState) => state.roles.user.data?.roles,
//   (roles) => roles
// );

const asyncActions = {
  getRoles,
  // getUserRoles,
};

export const roleActions = { ...roleSlice.actions, ...asyncActions };

export default roleSlice.reducer;
