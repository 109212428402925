import { Box } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import MaterialTable from 'material-table';
import React from 'react';
import { useSelector } from 'react-redux';

import SectionHeading from '../../components/SectionHeading';
import tableIcons from '../../config/tableIcons';
import { selectRolesList } from '../role/roleSlice';
import useUsersSlice from '../user/useUsersSlice';
import Cohort from './cohort';

interface CohortMembersTableProps {
  cohort: Cohort;
}

export default function CohortMembersTable(props: CohortMembersTableProps) {
  const { cohort } = props;
  const roles = useSelector(selectRolesList);
  const usersSlice = useUsersSlice({ cohort_id: cohort.id }, false);
  const { data: members, isLoading } = usersSlice.state;
  const { refresh } = usersSlice.actions;

  return (
    <MaterialTable
      isLoading={isLoading}
      title={<SectionHeading heading={cohort.name + ' Members'} />}
      icons={tableIcons}
      components={{ Container: Box }}
      columns={[
        {
          title: 'Study Inititials',
          field: 'studyInitials',
          filtering: false,
        },
        {
          title: 'Study ID',
          field: 'studyID',
          filtering: false,
        },
        {
          title: 'Display Name',
          field: 'displayName',
          filtering: false,
        },
        {
          title: 'Role',
          field: 'role',
          lookup: roles.reduce<{ [x: string]: string }>((accum, curr) => {
            accum[curr.name] = curr.name;
            return accum;
          }, {}),
        },
        {
          title: 'Email',
          field: 'email',
          filtering: false,
        },
      ]}
      data={members.map(member => ({
        studyID: member.study_id,
        studyInitials: member.study_initials,
        displayName: Boolean(member.settings.display_name)
          ? member.settings.display_name
          : 'n/a',
        role: member.roles[0] === '' ? 'n/a' : member.roles[0],
        email: member.email,
      }))}
      actions={[
        {
          icon: RefreshIcon,
          tooltip: 'Refresh Data',
          isFreeAction: true,
          onClick: () => refresh(),
        },
      ]}
      options={{
        filtering: true,
        exportButton: true,
        exportAllData: true,
        exportFileName: `${cohort.name}_members`,
      }}
    />
  );
}
