import { Box, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';

interface SelectionSearchProps<T> {
  label?: string;
  loading?: boolean;
  options: Array<T>;
  getOptionLabel: (option: T) => string;
  onSelect?: (option: string| T | null) => void;
}

export default function SelectionSearch<T>(props: SelectionSearchProps<T>) {
  const { label, loading, options, getOptionLabel, onSelect } = props;

  const handleChange = (event: any, newValue: string | T | null) => {
    if (onSelect) onSelect(newValue);
  };

  return (
    <Box width="100%">
      <Autocomplete
        // id="selection-search"
        loading={loading}
        freeSolo
        options={options}
        getOptionLabel={getOptionLabel}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="filled"
            multiline={undefined}
            margin="normal"
          />
        )}
      />
    </Box>
  );
}
