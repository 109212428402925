import { axios } from '../../config/axiosConfig';
import PowerUp from './powerUp';
export type PowerUpUploadArgs = Omit<PowerUp, 'id'>;

export interface GetPowerUpsFilter {
  topic_id?: number;
  user_uuid?: string;
  is_not_interested?: boolean;
  has_tried_it?: boolean;
  type?: string;
}

export async function createPowerUpAPI(reqArgs: PowerUpUploadArgs) {
  try {
    const response = await axios.post<{
      power_up: PowerUp;
    }>('/api/v1/power_up/create', reqArgs, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data.power_up;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function updatePowerUpAPI(id: number, reqArgs: PowerUpUploadArgs) {
  try {
    const response = await axios.put<{
      power_up: PowerUp;
    }>(`/api/v1/power_up/${id}`, reqArgs, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data.power_up;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function deletePowerUpAPI(id: number) {
  try {
    const response = await axios.delete<{
      power_up: PowerUp;
    }>(`/api/v1/power_up/${id}/delete`);
    return response.data.power_up;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getPowerUpsListAPI(filter: GetPowerUpsFilter) {
  try {
    const response = await axios.get<{
      power_ups: Array<PowerUp>;
    }>('/api/v1/power_ups', {
      params: filter,
    });
    return response.data.power_ups;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
