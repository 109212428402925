import { Box, Typography, Button } from '@material-ui/core';
import React from 'react';
import { useAppDispatch } from '../config/storeConfig';
import { authActions, selectAuthUser } from '../features/auth/authSlice';
import { useSnackbar } from 'notistack';
import { useHistory, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function NotAuthorized() {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const authUser = useSelector(selectAuthUser);

  const handleLogout = async () => {
    const result = await dispatch(authActions.logout());
    if (typeof result.payload === 'string') {
      enqueueSnackbar(result.payload, { variant: 'error' });
      history.push('/login');
    } else {
      enqueueSnackbar('Logged out.');
    }
  };

  if (authUser === null) {
    return <Redirect to="/login" />;
  }

  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
    >
      <img src="/logo.png" alt="logo" width={160} height={160} />
      <Box m={1}>
        <Typography align="center" variant="h2" paragraph>
          Access Denied
        </Typography>
        <Typography align="center" variant="h5" paragraph>
          Your role is not authorized to proceed
        </Typography>
        <Box width="100%" display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleLogout}
            disableElevation
          >
            Logout
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
