import React from 'react';
import { Gains } from './gains';
import GainsAPI from './gainsAPI';
import { useSnackbar } from 'notistack';

interface GainsState {
  isLoading: boolean;
  error: Error | null;
  data: Gains | null;
}

export default function useProgresssSlice(user_uuid: any) {
  const [state, setState] = React.useState<GainsState>({
    isLoading: false,
    error: null,
    data: null,
  });

  const { enqueueSnackbar } = useSnackbar();

  const refresh = React.useCallback(async () => {
    try {
      setState(prev => ({ ...prev, isLoading: true }));
      const gains = await GainsAPI({ user_uuid });
      setState(prev => ({ ...prev, isLoading: false, data: gains }));
    } catch (reason) {
      const error = new Error(reason);
      setState(prev => ({ ...prev, isLoading: false, error }));
      enqueueSnackbar(reason, { variant: 'error' });
    }
  }, [user_uuid, enqueueSnackbar]);

  React.useEffect(() => {
    refresh();
  }, [user_uuid, refresh]);

  return { state, actions: { refresh } };
}
