import { Box, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useSelector } from 'react-redux';

import CollapsableSection from '../../components/CollapsableSection';
import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import { useAppDispatch } from '../../config/storeConfig';
import SelectionSearch from '../../containers/SelectionSearch';
import QuizForm from '../quiz/QuizForm';
import PowerUpTable from './PowerUpTable';
import Topic from './topic';
import TopicAnimationManager from './TopicAnimationManager';
import { deleteTopicAPI } from './topicAPI';
import TopicForm from './TopicForm';
import { getTopicsList, selectTopicsList, topicActions } from './topicSlice';

export default function EditTopic() {
  const [selectedTopic, setSelectedTopic] = React.useState<Topic | null>(null);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const topics = useSelector(selectTopicsList);

  const handleSelection = (topic: string | Topic | null) => {
    if (typeof topic !== 'string') setSelectedTopic(topic);
  };

  const handleTopicDelete = () => {
    if (selectedTopic) {
      deleteTopicAPI(selectedTopic.id)
        .then(topic => {
          setSelectedTopic(null);
          return dispatch(getTopicsList());
        })
        .then(result => {
          if (typeof result.payload === 'string') {
            enqueueSnackbar(result.payload, { variant: 'error' });
          } else {
            enqueueSnackbar('Topic DELETED.', { variant: 'success' });
          }
        });
    }
  };

  const handleTopicUpdateSuccess = (topic: Topic) => {
    setSelectedTopic(topic);
  };

  React.useEffect(() => {
    dispatch(topicActions.getTopicsList());
  }, [dispatch]);

  return (
    <CollapsableSection
      heading={<SectionHeading heading="Select a Topic to Update" />}
      initiallyCollapsed
    >
      {topics && (
        <Box width="100%" my={2}>
          <SelectionSearch
            label="Search Topics"
            options={topics}
            getOptionLabel={option => option.name}
            onSelect={handleSelection}
          />
        </Box>
      )}
      {selectedTopic && (
        <Box>
          <Section variant="outlined">
            <SectionHeading heading="Edit Topic Info" />
            <TopicForm
              topic={selectedTopic}
              onSuccess={handleTopicUpdateSuccess}
            />
          </Section>

          <Section variant="outlined">
            <PowerUpTable
              topic={selectedTopic}
              type="power_up"
              title="Topic Power Ups"
            />
          </Section>
          <Section variant="outlined">
            <PowerUpTable
              topic={selectedTopic}
              type="quest"
              title="Topic Daily Question"
            />
          </Section>
          <TopicAnimationManager topic={selectedTopic} />
          <QuizForm topicID={selectedTopic.id} topicName={selectedTopic.name} />
          <Box width="100%" display="flex" justifyContent="flex-end">
            <Button
              color="secondary"
              variant="contained"
              onClick={handleTopicDelete}
              disableElevation
            >
              Delete Topic
            </Button>
          </Box>
        </Box>
      )}
    </CollapsableSection>
  );
}
