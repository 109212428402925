import { Box } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import MaterialTable from 'material-table';
import React from 'react';

import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import tableIcons from '../../config/tableIcons';
import User from '../user/user';
import useGainsSlice from './useGainsSlice';

interface GainsColumn {
  topic: string;
  rating: number | string;
  details: string;
}

export default function GainsTable(props: { user: User }) {
  const gainsSlice = useGainsSlice(props.user.uuid);
  const { data: gains, isLoading } = gainsSlice.state;

  // Returns star rating based off amount of checkins and if completion section is true
  const checkinRating = () => {
    let details = {
      rating: 0,
      completion: false,
    };
    if (gains?.check_ins.check_ins_completed) {
      let checkinsAmount = gains?.check_ins.check_ins_completed;
      if (checkinsAmount < 7) {
        details.rating = 0;
      }
      if (checkinsAmount >= 7 && checkinsAmount < 14) {
        details.rating = 1;
      }
      if (checkinsAmount >= 14 && checkinsAmount <= 21) {
        details.rating = 2;
      }
      if (checkinsAmount >= 21) {
        details.rating = 3;
      }
      if (checkinsAmount >= 30) {
        details.completion = true;
      }
    }
    return details;
  };

  // Rating for frequent flyer
  const frequentflyerRating = () => {
    let rating: number = 0;
    if (gains?.frequent_flyer.max_consecutive_days) {
      let checkinsAmount = gains?.frequent_flyer.max_consecutive_days;
      if (checkinsAmount < 3) {
        rating = 0;
      }
      if (checkinsAmount >= 3 && checkinsAmount < 7) {
        rating = 1;
      }
      if (checkinsAmount >= 7 && checkinsAmount < 14) {
        rating = 2;
      }
      if (checkinsAmount >= 14) {
        rating = 3;
      }
    }
    return rating;
  };

  //Rating for abstainer
  const abstainerRating = () => {
    let rating: number = 0;
    if (gains?.abstainer.max_consecutive_days) {
      let abstainerAmount = gains?.abstainer.max_consecutive_days;
      if (abstainerAmount < 7) {
        rating = 0;
      }
      if (abstainerAmount >= 7 && abstainerAmount < 14) {
        rating = 1;
      }
      if (abstainerAmount >= 14 && abstainerAmount < 21) {
        rating = 2;
      }
      if (abstainerAmount >= 21) {
        rating = 3;
      }
    }
    return rating;
  };

  //Rating for groundNow app being opened
  const groundNowRating = () => {
    let details = {
      rating: 0,
      string: 'times',
    };
    if (gains?.ground_now_app.ground_now_app_opened) {
      let groundNows = gains?.ground_now_app.ground_now_app_opened;
      if (groundNows < 1) {
        details.rating = 0;
      }
      if (groundNows >= 1 && groundNows < 2) {
        details.rating = 1;
        details.string = 'time';
      }
      if (groundNows >= 2 && groundNows < 3) {
        details.rating = 2;
      }
      if (groundNows >= 3) {
        details.rating = 3;
      }
    }
    return details;
  };

  //Rating for visionary
  const visionairyRating = () => {
    let rating: number = 0;
    if (gains?.visionary) {
      if (gains?.visionary.has_set_up_remember_why === true) {
        rating += 1;
      }
      if (gains?.visionary.has_set_up_strengths === true) {
        rating += 1;
      }
      if (gains?.visionary.has_set_up_vision_board === true) {
        rating += 1;
      }
    }
    return rating;
  };

  //Returns true or false
  const goalGetterRating = () => {
    let result: boolean = false;
    if (gains?.goal_getter) {
      if (gains?.goal_getter.emotional_goals >= 10) {
        result = true;
      }
    }
    return result;
  };

  //Returns true or false
  const fastLearnerRating = () => {
    let result: boolean = false;
    if (gains?.fast_learner) {
      if (gains?.fast_learner.articles_with_notes >= 5) {
        result = true;
      }
    }
    return result;
  };

  // create Table for Gains
  const createRatingTable = () => {
    let rows: GainsColumn[] = [
      {
        topic: 'Checkins',
        rating: checkinRating().rating,
        details: `${gains?.check_ins.check_ins_completed} check in days completed`,
      },
      {
        topic: 'Frequet Flyer',
        rating: frequentflyerRating(),
        details: `Checked-in for ${gains?.frequent_flyer.most_recent_consecutive_days} consecutive days`,
      },
      {
        topic: 'Abstainer',
        rating: abstainerRating(),
        details: `Abstained for ${gains?.abstainer.most_recent_consecutive_days} consecutive days`,
      },
      {
        topic: 'Visionary',
        rating: visionairyRating(),
        details: `Completed: ${
          gains?.visionary.has_set_up_remember_why ? 'Set Up Remember Why' : ''
        }${gains?.visionary.has_set_up_strengths ? ', Set Up Strengths' : ''}${
          gains?.visionary.has_set_up_vision_board
            ? ', Set Up Vision Board'
            : ''
        }`,
      },
      {
        topic: 'Seeking Safety App',
        rating: groundNowRating().rating,
        details: `Used Seeking Safety App ${
          gains?.ground_now_app.ground_now_app_opened
        } ${groundNowRating().string}`,
      },
      {
        topic: 'Goal Getter',
        rating: goalGetterRating() ? 'Complete' : 'Incomplete',
        details: goalGetterRating()
          ? 'Recovered a lot from emotional goals'
          : 'Has not recovered a lot from emotional goals',
      },
      {
        topic: 'Schedule Coach',
        rating: gains?.schedule_coach.has_reached_out
          ? 'Complete'
          : 'Incomplete',
        details: gains?.schedule_coach.has_reached_out
          ? 'Reached out to a coping coach'
          : 'Has not reached out to a coping coach',
      },
      {
        topic: 'Fast Learner',
        rating: fastLearnerRating() ? 'Complete' : 'Incomplete',
        details: `${gains?.fast_learner.articles_with_notes} Articles with notes`,
      },
      {
        topic: 'Certificate of Achievement',
        rating: checkinRating().completion ? 'Completed' : 'Incomplete',
        details: `${gains?.check_ins.check_ins_completed} check in days completed`,
      },
    ];
    return rows;
  };

  return (
    <Section variant="outlined">
      <MaterialTable
        title={<SectionHeading heading="Gains" />}
        isLoading={isLoading}
        components={{ Container: Box }}
        icons={tableIcons}
        columns={[
          {
            title: 'Topic',
            field: 'topic',
          },

          {
            title: 'Rating',
            field: 'rating',
            render: rowData => {
              if (typeof rowData.rating === 'string') {
                return rowData.rating;
              }
              return (
                <Rating
                  name="read-only"
                  max={3}
                  value={rowData.rating}
                  readOnly
                />
              );
            },
          },

          {
            title: 'Details',
            field: 'details',
          },
        ]}
        data={createRatingTable()}
        options={{
          exportButton: true,
          exportAllData: true,
          exportFileName: 'Gains table',
          search: false,
          emptyRowsWhenPaging: false,
          pageSize: 10,
        }}
      />
    </Section>
  );
}
