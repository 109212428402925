import { axios } from '../../../config/axiosConfig';
import { Attendees } from './attendance';

export async function getAttendeesListAPI(meeting_id: number) {
  try {
    const endpoint = `/meeting/${meeting_id}/attendees`;
    const response = await axios.get<{
      attendees: Attendees[];
    }>(`/api/v1/${endpoint}`);
    return response.data.attendees;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function updateAttendeesListsAPI(
  meeting_id: number,
  attendees: { user_uuid: string; attendance: string | null }[] | null
) {
  try {
    const response = await axios.put<{ attendees: Attendees[] }>(
      `/api/v1/meeting/${meeting_id}/attendees`,
      { attendees },
      { headers: { 'Content-Type': 'application/json' } }
    );
    return response.data.attendees;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
