import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import MaterialTable from 'material-table';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import tableIcons from '../../config/tableIcons';
import {dateToISOLikeButLocal} from '../../util/dateToISOLikeButLocal';
import Cohort from '../cohort/cohort';
import Topic from '../topic/topic';
import { selectTopicsMap } from '../topic/topicSlice';
import MeetingAttendance from './attendance/meetingAttendance';
import { getMeetingHostKeyAPI } from './meetingAPI';
import useMeetingsSlice from './useMeetingsSlice';
import ZoomRecording from './ZoomRecording';

export interface ZoomCallFormData {
  startDate: string;
  feedbackURL: string;
  topicID: number;
}

interface ZoomCallFormProps {
  topics: Topic[];
  cohort: Cohort;
}

export default function ZoomCallSetup(props: ZoomCallFormProps) {
  const { topics, cohort } = props;
  // const [selectedDate, setSelectedDate] = React.useState(
  //   new Date().toISOString().split('.')[0]
  // );
  const [hostKey, setHostKey] = React.useState('');

  const filter = React.useMemo(() => ({ cohort_id: cohort.id }), [cohort]);

  const meetingsSlice = useMeetingsSlice(filter);
  const { data: meetings, /* error, */ isLoading } = meetingsSlice.state;

  const actions = meetingsSlice.actions;

  const topicsMap = useSelector(selectTopicsMap);

  const {
    register,
    handleSubmit,
    errors,
    control,
  } = useForm<ZoomCallFormData>();

  const _onSubmit = handleSubmit(formData => {
    actions.createMeeting({
      topic_id: formData.topicID,
      cohort_id: cohort.id,
      start_time: new Date(formData.startDate).toISOString(),
      feedback_url: formData.feedbackURL,
      approval_type: 0,
    });
  });

  React.useEffect(() => {
    getMeetingHostKeyAPI()
      .then(key => setHostKey(key))
      .catch(reason => console.error(reason));
  }, [setHostKey]);

  return (
    <React.Fragment>
      <Section variant="outlined">
        <SectionHeading heading="Weekly Zoom Call" />
        <Typography variant="body2" paragraph>
          Host Key: {hostKey}
        </Typography>
        <Section variant="outlined">
          <form onSubmit={_onSubmit}>
            <SectionHeading heading="Create Meeting" />
            <TextField
              fullWidth
              margin="normal"
              variant="filled"
              label="Select a Date"
              type="datetime-local"
              name="startDate"
              defaultValue={dateToISOLikeButLocal(new Date())}
              inputRef={register({ required: false })}
              helperText={errors.startDate}
              error={Boolean(errors.startDate)}
            />
            <TextField
              fullWidth
              label="Feedback URL (Optional)"
              variant="filled"
              name="feedbackURL"
              margin="normal"
              inputRef={register({ required: false })}
              helperText={errors.feedbackURL?.message}
              error={Boolean(errors.feedbackURL)}
            />
            <Box width="100%" my={2}>
              <FormControl
                variant="filled"
                fullWidth
                error={Boolean(errors.topicID)}
              >
                <InputLabel id="select-topic-label">Select Topic</InputLabel>
                <Controller
                  as={
                    <Select labelId="select-topic-label" id="select-topic">
                      {topics.map(topic => (
                        <MenuItem key={topic.name} value={topic.id}>
                          {topic.name}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  name="topicID"
                  rules={{ required: 'Selection Required' }}
                  control={control}
                  defaultValue={''}
                />
                {errors.topicID && <FormHelperText>Required</FormHelperText>}
              </FormControl>
            </Box>

            <Box width="100%" my={2}>
              <Button
                color="primary"
                variant="contained"
                style={{ margin: '0 auto', display: 'block' }}
                type="submit"
                disableElevation
              >
                Create
              </Button>
            </Box>
          </form>
        </Section>
        {topicsMap && (
          <Section variant="outlined">
            <MaterialTable
              // title="Upcoming and Past Meetings"
              title={<SectionHeading heading="Upcoming and Past Meetings" />}
              icons={tableIcons}
              components={{ Container: Box }}
              isLoading={isLoading}
              columns={[
                { title: 'Topic', field: 'topic', editable: 'never' },
                {
                  title: 'Start Time',
                  field: 'start',
                  type: 'datetime',
                  defaultSort: 'desc',
                  editComponent: props => (
                    <TextField
                      label="Start Time"
                      type="datetime-local"
                      value={dateToISOLikeButLocal(new Date(props.value))}
                      onChange={e => props.onChange(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  ),
                  render: rowData => new Date(rowData.start).toLocaleString(),
                },
                { title: 'Zoom URL', field: 'url', editable: 'never' },
                { title: 'Password', field: 'password' },
                { title: 'Meeting ID', field: 'id', editable: 'never' },
                {
                  title: 'Dial-In Number',
                  field: 'dial_number',
                  editable: 'never',
                },
                { title: 'Feedback URL', field: 'feedbackURL' },
              ]}
              data={meetings.map(meet => ({
                topic: topicsMap[meet.topic_id].name,
                cohort: meet.cohort_id,
                // start: dateToISOLikeButLocal(new Date(meet.details?.start_time)),
                start: new Date(meet.details?.start_time).toISOString(),
                url: meet.details?.join_url,
                password: meet.details?.password,
                feedbackURL: meet.feedback_url,
                id: meet.id,
                dial_number:
                  meet.details?.settings.global_dial_in_numbers[0].number,
                topic_id: meet.topic_id,
              }))}
              editable={{
                onRowUpdate: async (newData, oldData) => {
                  try {
                    await actions.updateMeeting(oldData!.id, {
                      topic_id: oldData!.topic_id,
                      cohort_id: oldData!.cohort,
                      // start_time: newData.start.toISOString(),
                      start_time: new Date(newData.start).toISOString(),
                      password: newData.password,
                      feedback_url: newData.feedbackURL,
                      approval_type: 0,
                    });
                  } catch (reason) {
                    console.error(reason);
                  }
                },
                onRowDelete: async oldData => {
                  try {
                    await actions.deleteMeeting(oldData.id);
                    actions.refresh();
                  } catch (reason) {
                    console.error(reason);
                  }
                },
              }}
              actions={[
                {
                  icon: RefreshIcon,
                  tooltip: 'Refresh Data',
                  isFreeAction: true,
                  onClick: () => actions.refresh(),
                },
              ]}
              options={{
                exportButton: true,
                exportAllData: true,
                exportFileName: `${cohort.name}_meetings`,
              }}
            />
          </Section>
        )}
      </Section>
      {meetings.length > 0 ? (
        <Section variant="outlined">
          <ZoomRecording meetings={meetings} />
          <MeetingAttendance
            topics={topics}
            meetings={meetings}
            cohort={cohort}
          />
        </Section>
      ) : null}
    </React.Fragment>
  );
}
