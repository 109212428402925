import React from 'react';

import PrepareForTriggerTable from './prepareForTriggerTable';
import TriggerIncidentTable from './triggerIncidentTable';

// container for a scheduled(prepare for) trigger and trigger incidents
export default function TriggersContainer(props: any) {
  const userUUID = props.user.uuid;

  return (
    <div>
      <PrepareForTriggerTable userUUID={userUUID} />
      <TriggerIncidentTable userUUID={userUUID} />
    </div>
  );
}
