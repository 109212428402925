import { Box, Button, createStyles, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { AppContext } from '../../AppContext';
import ContentContainer from '../../components/ContentContainer';
import RouteTitle from '../../components/RouteTitle';
import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import SelectionSearch from '../../containers/SelectionSearch';
import { dateToISOLikeButLocal } from '../../util/dateToISOLikeButLocal';
import { selectAuthUser } from '../auth/authSlice';
import User from '../user/user';
import useUsersSlice from '../user/useUsersSlice';
import ScheduleEvent from './event';
import { createScheduledEventAPI, deleteScheduleEventAPI, getFilteredScheduledEventsAPI } from './scheduleAPI';

const useStyles = makeStyles(theme =>
  createStyles({
    mainGrid: {
      width: '100%',
      padding: theme.spacing(2, 0),
    },
    gridItem: {
      padding: theme.spacing(1),
    },
  })
);

export default function OfficeHours() {
  const [selectedUser, setSelectedUser] = React.useState<User | null>(null);
  const [selectedDate, setSelectedDate] = React.useState(
    dateToISOLikeButLocal(new Date(), false, true, false)
  );
  const [selectedStartTime, setSelectedStartTime] = React.useState(
    // dateToISOLikeButLocal(new Date(), false, false, true)
    '12:00'
  );
  const [selectedEndTime, setSelectedEndTime] = React.useState(
    // dateToISOLikeButLocal(new Date(), false, false, true)
    '13:00'
  );
  const [scheduleList, setScheduleList] = React.useState<Array<ScheduleEvent>>(
    []
  );

  const authUser = useSelector(selectAuthUser);
  const isLeader = authUser?.roles[0] === 'leader';

  const usersSlice = useUsersSlice({ role: 'leader' }, false);
  const { data: leaders /* error, isLoading */ } = usersSlice.state;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { app } = React.useContext(AppContext);

  const handleLeaderSelect = React.useCallback(
    (user: string | User | null) => {
      if (typeof user !== 'string') {
        setSelectedUser(user);
        if (user) {
          getFilteredScheduledEventsAPI({
            resource_type: 'leader office hours',
            invitees: [{ invitee_type: 'user', invitee_id: user.uuid }],
          })
            .then(events => {
              setScheduleList(events);
            })
            .catch(reason => enqueueSnackbar(reason, { variant: 'error' }));
        }
      }
    },
    [setSelectedUser, enqueueSnackbar]
  );

  const handleSelectedDate = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSelectedDate(e.target.value);
  };

  const handleSelectedStartTime = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSelectedStartTime(e.target.value);
  };
  const handleSelectedEndTime = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSelectedEndTime(e.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedDate && selectedStartTime && selectedEndTime && selectedUser) {
      const start = new Date(selectedDate + 'T' + selectedStartTime);
      const end = new Date(selectedDate + 'T' + selectedEndTime);

      createScheduledEventAPI({
        start_at: start.toISOString(),
        end_at: end.toISOString(),
        resource_type: 'leader office hours',
        resource_id: 'anything',
        invitees: [{ invitee_type: 'user', invitee_id: selectedUser.uuid }],
      })
        .then(event => {
          enqueueSnackbar('Available time slot CREATED', {
            variant: 'success',
          });
          return getFilteredScheduledEventsAPI({
            resource_type: 'leader office hours',
            invitees: [{ invitee_type: 'user', invitee_id: selectedUser.uuid }],
          });
        })
        .then(events => {
          setScheduleList(events);
        })
        .catch(reason => enqueueSnackbar(reason, { variant: 'error' }));
    }
  };

  const handleDelete = (schedule: ScheduleEvent) => async () => {
    try {
      await deleteScheduleEventAPI(schedule.id);
      if (selectedUser) {
        const events = await getFilteredScheduledEventsAPI({
          resource_type: 'leader office hours',
          invitees: [{ invitee_type: 'user', invitee_id: selectedUser.uuid }],
        });
        setScheduleList(events);
        enqueueSnackbar('Time slot DELETED.', { variant: 'success' });
      }
    } catch (reason) {
      enqueueSnackbar(reason, { variant: 'error' });
    }
  };

  React.useEffect(() => {
    if (isLeader) {
      handleLeaderSelect(authUser);
    }
  }, [isLeader, authUser, handleLeaderSelect]);

  if (app !== 'ss') {
    return <Redirect to="/" />;
  }

  return (
    <ContentContainer maxWidth="lg">
      <RouteTitle title="Leader Office Hours" />
      {leaders && !isLeader && (
        <Section variant="outlined">
          <SectionHeading heading="Select a Leader" />
          <Box width="100%" my={2}>
            <SelectionSearch
              label="Search Leaders"
              options={leaders}
              getOptionLabel={option =>
                `<ID: [${option.study_id}]>  <Initials: [${
                  option.study_initials
                }]>  [${
                  !Boolean(option.settings.display_name)
                    ? 'Anonymous'
                    : option.settings.display_name
                }]`
              }
              onSelect={handleLeaderSelect}
            />
          </Box>
        </Section>
      )}
      {selectedUser && (
        <React.Fragment>
          <Section variant="outlined">
            <SectionHeading heading="Set Availability" />
            <form onSubmit={handleSubmit}>
              <Grid className={classes.mainGrid} container justify="center">
                <Grid className={classes.gridItem} item xs={12} md={6}>
                  <Typography variant="subtitle2" align="center" paragraph>
                    Date
                  </Typography>
                  <TextField
                    fullWidth
                    type="date"
                    variant="filled"
                    label="Select Date"
                    value={selectedDate}
                    onChange={handleSelectedDate}
                  />
                </Grid>
                <Grid className={classes.gridItem} item xs={12} md={6}>
                  <Typography variant="subtitle2" align="center" paragraph>
                    Time Frame
                  </Typography>
                  <Grid container spacing={2} direction="column">
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        type="time"
                        variant="filled"
                        label="Pick a Start Time"
                        value={selectedStartTime}
                        onChange={handleSelectedStartTime}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        type="time"
                        variant="filled"
                        label="Pick an End Time"
                        value={selectedEndTime}
                        onChange={handleSelectedEndTime}
                      />
                    </Grid>
                  </Grid>
                  <Box width="100%" my={2}>
                    <Button
                      color="primary"
                      variant="contained"
                      style={{ margin: '0 auto', display: 'block' }}
                      type="submit"
                      disableElevation
                    >
                      Add
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Section>
          <Section variant="outlined">
            <SectionHeading heading="Upcoming Office Hours" />
            {scheduleList.map((schedule, index) => (
              <Section key={index} variant="outlined">
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography gutterBottom>
                    <b>Start: </b>
                    {`${new Date(schedule.start_at).toLocaleString()}`}
                  </Typography>
                  <Typography gutterBottom>
                    <b>End: </b>
                    {`${new Date(schedule.end_at).toLocaleString()}`}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleDelete(schedule)}
                    disableElevation
                  >
                    Delete
                  </Button>
                </Box>
              </Section>
            ))}
          </Section>
        </React.Fragment>
      )}
    </ContentContainer>
  );
}
