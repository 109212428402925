import React from 'react';

import ModerationTable from '../../components/ModerationTable';
import SectionHeading from '../../components/SectionHeading';
import {
  getGroupChatMessagesByAdminAPI,
  GroupChatMessagesByAdminQuery,
  hideGroupChatMessageAPI,
} from './groupChatAPI';

export default function GroupChatMessageModerationTable(props: {
  group_chat_uuid: string;
}) {
  const { group_chat_uuid } = props;
  return (
    <ModerationTable
      initCollapse
      title={<SectionHeading heading="Group Chat Messages" />}
      data={async tableQuery => {
        const query: GroupChatMessagesByAdminQuery = {
          is_flagged: true,
          order_by: 'count_flags',
          is_hidden: false,
          page: tableQuery.page + 1,
          group_chat_uuid,
          per_page: tableQuery.pageSize,
        };
        const response = await getGroupChatMessagesByAdminAPI(query);
        const data = response.group_chat_messages.map(msg => ({
          created: msg.created,
          message: msg.message,
          count_flags: msg.count_flags,
          identifier: msg.uuid,
        }));
        return {
          data,
          page: response.page - 1,
          totalCount: response.group_chat_messages.length,
        };
      }}
      deleteAction={identifier => {
        if (typeof identifier === 'string') hideGroupChatMessageAPI(identifier);
      }}
    />
  );
}
