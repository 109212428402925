import { Box, Typography } from '@material-ui/core';
import React from 'react';

import Section from '../../../components/Section';
import WeeklyCommitmentsTable from '../../commitment/WeeklyCommitmentsTable';
import UserHelpCopeList from '../../help_cope/UserHelpCopeList';
import ProgressTable from '../../progress/progressTable';
import DailySafetyCheckTable from '../../safety_check/DailySafetyCheckTable';
import DailySafetyPlanTables from '../../safety_plan/DailySafetyPlanTables';
import PowerUpTable from '../../topic/PowerUpTable';
import TriggersContainer from '../../trigger/triggersContainer';
import TrustedPeopleTable from '../../trusted_person/TrustedPeopleTable';
import User from '../user';
import UserInterestGroupsList from '../UserInterestGroupsList';

export default function SSUserSettings(props: { user: User }) {
  const { user } = props;

  return (
    <Box>
      <Section variant="outlined">
        <Typography variant="h5" gutterBottom>
          Short About Me
        </Typography>
        <Typography variant="body2" paragraph>
          {user.settings.about_me_short ?? 'N/A'}
        </Typography>
      </Section>
      <Section variant="outlined">
        <Typography variant="h5" gutterBottom>
          Long About Me
        </Typography>
        <Typography variant="body2" paragraph>
          {user.settings.about_me_long ?? 'N/A'}
        </Typography>
      </Section>
      <Section variant="outlined">
        <Typography variant="h5" paragraph>
          Interest Group
        </Typography>
        <UserInterestGroupsList user={user} />
      </Section>
      <Section variant="outlined">
        <TrustedPeopleTable user={user} />
      </Section>
      <Section variant="outlined">
        <Typography variant="h5" paragraph>
          Help Me Cope
        </Typography>
        <UserHelpCopeList user={user} />
      </Section>
      <Section variant="outlined">
        <WeeklyCommitmentsTable user={user} />
      </Section>
      <Section variant="outlined">
        <PowerUpTable user={user} type="power_up" showTopicName />
      </Section>
      <Section variant="outlined">
        {/* <PowerUpTable user={user} type="power_up" showTopicName/> */}
        <DailySafetyCheckTable user_uuid={user.uuid} />
      </Section>
      <Section variant="outlined">
        {/* <SectionHeading heading="Safety Wizard Response" /> */}
        <DailySafetyPlanTables user={user} />
      </Section>
      <Section variant="outlined">
        <Typography variant="h5" paragraph>
          Trigger
        </Typography>
        <TriggersContainer user={user} />
      </Section>
      {/* <SectionHeading heading="Safety Wizard Response" /> */}
      <ProgressTable user={user} />
    </Box>
  );
}
