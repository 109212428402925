import React from 'react';

import profilePlaceholder from '../../assets/images/profile-pic-placeholder.png';
import User from '../user/user';
import useAvatarSlice from './useAvatarSlice';
import { Box, CircularProgress } from '@material-ui/core';

interface UserAvatarProps {
  user: User;
  sa?:boolean;
}

export default function UserAvatar(props: UserAvatarProps) {
  const { user, sa } = props;
  const avatarSlice = useAvatarSlice(user.settings.avatar_uuid, sa);
  const { data: url, /* error, */ isLoading } = avatarSlice.state;

  if (isLoading) {
    return (
      <Box
        width="100%"
        height={230}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" thickness={2} size={48} />
      </Box>
    );
  }

  return (
    <img
      src={url ?? profilePlaceholder}
      // width="100%"
      // width={250}
      // height={250}
      alt="profile pic"
      style={{margin: '0 auto', display: 'block', width:"100%", maxWidth: 250, maxHeight: 250}}
    />
  );
}
