import { useSnackbar } from 'notistack';
import React from 'react';

import { getDiagnosticListsURLsAPI } from './diagnosticAPI';
import DiagnosticFormData from './DiagnosticFormData';

interface DiagnosticsState {
  isLoading: boolean;
  error: Error | null;
  data: DiagnosticFormData;
}

export function useDiagnosticsSlice(cohort_id: any) {
  const [state, setState] = React.useState<DiagnosticsState>({
    isLoading: false,
    error: null,
    data: {
      diagnostic_assessments: [],
      error: null,
      status: '',
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const refresh = React.useCallback(async () => {
    try {
      setState(prev => ({ ...prev, isLoading: true }));
      const diagnostic = await getDiagnosticListsURLsAPI({cohort_id});
      setState(prev => ({ ...prev, isLoading: false, data: diagnostic }));
    } catch (reason) {
      const error = new Error(reason);
      setState(prev => ({ ...prev, isLoading: false, error }));
      enqueueSnackbar(reason, { variant: 'error' });
    }
  }, [enqueueSnackbar,cohort_id]);

  React.useEffect(() => {
    refresh();
  }, [refresh,cohort_id]);
  return { state, actions: { refresh } };
}