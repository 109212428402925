import { useSnackbar } from 'notistack';
import React from 'react';

import Topic from './topic';
import { createTopicAPI, getTopicsListAPI, updateTopicAPI } from './topicAPI';

interface TopicsState {
  isLoading: boolean;
  error: Error | null;
  data: Topic[];
}

type TopicsAction =
  | { type: 'request/getTopics' }
  | { type: 'success/getTopics'; payload: Topic[] }
  | { type: 'reject/getTopics'; payload: Error }
  | { type: 'request/createTopic' }
  | { type: 'success/createTopic'; payload: Topic }
  | { type: 'reject/createTopic'; payload: Error }
  | { type: 'request/updateTopic' }
  | { type: 'success/updateTopic'; payload: Topic }
  | { type: 'reject/updateTopic'; payload: Error }
  | { type: 'request/deleteTopic' }
  | { type: 'success/deleteTopic'; payload: Topic }
  | { type: 'reject/deleteTopic'; payload: Error };

function reducer(state: TopicsState, action: TopicsAction): TopicsState {
  switch (action.type) {
    case 'request/getTopics':
    case 'request/createTopic':
    case 'request/updateTopic':
    case 'request/deleteTopic':
      return { ...state, isLoading: true };

    case 'success/getTopics':
      return { ...state, isLoading: false, data: action.payload };

    case 'success/updateTopic':
    case 'success/deleteTopic':
      const index = state.data.findIndex(
        (topic) => topic.id === action.payload.id
      );
      if (index > -1) {
        return {
          ...state,
          isLoading: false,
          data:
            action.type === 'success/updateTopic'
              ? [
                  ...state.data.slice(0, index),
                  action.payload,
                  ...state.data.slice(index + 1),
                ]
              : [...state.data.slice(0, index), ...state.data.slice(index + 1)],
        };
      }
      return { ...state, isLoading: false };

    case 'reject/getTopics':
    case 'reject/createTopic':
    case 'reject/updateTopic':
    case 'reject/deleteTopic':
      return { ...state, isLoading: false, error: action.payload };
    default:
      throw new Error('Action does not exist on Topics Slice');
  }
}

export default function useTopicsSlice() {
  const [state, dispatch] = React.useReducer(reducer, {
    isLoading: false,
    error: null,
    data: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  const createTopic = React.useCallback(
    async (name: string, handout_url?: string, quiz_url?: string) => {
      try {
        dispatch({ type: 'request/createTopic' });
        const newTopic = await createTopicAPI(name, handout_url, quiz_url);
        dispatch({ type: 'success/createTopic', payload: newTopic });
        enqueueSnackbar(`Topic CREATED`, { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        dispatch({ type: 'reject/createTopic', payload: error });
        enqueueSnackbar(reason, { variant: 'error' });
        throw error;
      }
    },
    [dispatch, enqueueSnackbar]
  );

  const updateTopic = React.useCallback(
    async (
      id: number,
      newName: string,
      handout_url: string,
      quiz_url: string
    ) => {
      try {
        dispatch({ type: 'request/updateTopic' });
        const updatedTopic = await updateTopicAPI(
          id,
          newName,
          handout_url,
          quiz_url
        );
        dispatch({ type: 'success/updateTopic', payload: updatedTopic });
        enqueueSnackbar(`Topic UPDATED`, { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        dispatch({ type: 'reject/updateTopic', payload: error });
        enqueueSnackbar(reason, { variant: 'error' });
        throw error;
      }
    },
    [dispatch, enqueueSnackbar]
  );

  const refresh = React.useCallback(async () => {
    try {
      dispatch({ type: 'request/getTopics' });
      const topics = await getTopicsListAPI();
      dispatch({ type: 'success/getTopics', payload: topics });
    } catch (reason) {
      const error = new Error(reason);
      dispatch({ type: 'reject/getTopics', payload: error });
      enqueueSnackbar(reason, { variant: 'error' });
      throw error;
    }
  }, [dispatch, enqueueSnackbar]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return { state, actions: { createTopic, updateTopic, refresh } };
}
