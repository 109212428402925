import { axios } from '../../config/axiosConfig';
import MySafePlacePost from './mySafePlacePost';
import MySafePlaceComment from './mySafePlaceComment';

export interface SocialFeedQuery {
  cohort_id?: number;
  favs_only?: boolean;
  is_deleted?: boolean;
  is_flagged?: boolean;
  is_hidden?: boolean;
  order_by?: string;
  origin?: string;
  page?: number;
  per_page?: number;
  resource_type?: string;
  reverse?: boolean;
  user_uuid?: string;
}

export async function getSocialFeedAPI(query?: SocialFeedQuery) {
  try {
    const response = await axios.get<{
      msp_posts: MySafePlacePost[];
      page: number;
    }>('/api/v1/social_feed', { params: query });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function unflagMySafePlacePostAPI(id: number) {
  try {
    const response = await axios.put<{
      msp_post: MySafePlacePost;
    }>(`/api/v1/msp_post/${id}/unflag`);
    return response.data.msp_post;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function hideMySafePlacePostAPI(id: number) {
  try {
    const response = await axios.delete<{
      msp_post: MySafePlacePost;
    }>(`/api/v1/msp_post/${id}/hide`);
    return response.data.msp_post;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export type MySafePlacePostCommentsListByAdminQuery = {
  is_flagged?: boolean;
  is_hidden?: boolean;
  msp_post_id?: number;
  order_by?: string;
  page?: number;
  per_page?: number;
  reverse?: boolean;
  user_uuid?: string;
};

export async function getMySafePlacePostCommentsListByAdminAPI(
  query?: MySafePlacePostCommentsListByAdminQuery
) {
  try {
    const response = await axios.get<{
      comments: MySafePlaceComment[];
      page: number;
    }>(`/api/v1/msp_post/comments`, { params: query });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function unflagMySafePlacePostCommentAPI(id: number) {
  try {
    const response = await axios.put<{
      comment: MySafePlaceComment;
    }>(`/api/v1/msp_post/comment/${id}/unflag`);
    return response.data.comment;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function hideMySafePlacePostCommentAPI(id: number) {
  try {
    const response = await axios.delete<{
      comment: MySafePlaceComment;
    }>(`/api/v1/msp_post/comment/${id}/hide`);
    return response.data.comment;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
