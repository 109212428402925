import React from 'react';
import Commitment from './commitment';
import { getCommitmentsListAPI } from './commitmentAPI';
import { useSnackbar } from 'notistack';

interface CommitmentsState {
  isLoading: boolean;
  error: Error | null;
  data: Commitment[];
}

type CommitmentsAction =
  | { type: 'request/getCommitments' }
  | { type: 'success/getCommitments'; payload: Commitment[] }
  | { type: 'reject/getCommitments'; payload: Error };

function reducer(
  state: CommitmentsState,
  action: CommitmentsAction
): CommitmentsState {
  switch (action.type) {
    case 'request/getCommitments':
      return { ...state, isLoading: true };
    case 'success/getCommitments':
      return { ...state, isLoading: false, data: action.payload };
    case 'reject/getCommitments':
      return { ...state, isLoading: false, error: action.payload };
    default:
      throw new Error('Action type does not exist on CommitmentsSlice');
  }
}

export default function useCommitmentsSlice(filter: {
  topic_id?: number;
  cohort_id?: number;
  user_uuid?: string;
  is_deleted?: boolean;
}) {
  const [state, dispatch] = React.useReducer(reducer, {
    isLoading: false,
    error: null,
    data: [],
  });
  const { topic_id, cohort_id, user_uuid, is_deleted } = filter;

  const { enqueueSnackbar } = useSnackbar();

  const refresh = React.useCallback(async () => {
    try {
      if (
        cohort_id === undefined &&
        user_uuid === undefined &&
        is_deleted === undefined &&
        topic_id === undefined
      ) {
        const error = new Error(
          'useHelpCopeSlice(filter): filter param must have at least one filter defined'
        );
        dispatch({
          type: 'reject/getCommitments',
          payload: error,
        });
        throw error;
      } else {
        dispatch({ type: 'request/getCommitments' });
        const commitments = await getCommitmentsListAPI({
          user_uuid,
          topic_id,
          cohort_id,
          is_deleted,
        });
        dispatch({ type: 'success/getCommitments', payload: commitments });
      }
    } catch (reason) {
      const error = new Error(reason);
      dispatch({
        type: 'reject/getCommitments',
        payload: error,
      });
      enqueueSnackbar(reason, { variant: 'error' });
      throw error;
    }
  }, [dispatch, cohort_id, user_uuid, is_deleted, topic_id, enqueueSnackbar]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return { state, actions: { refresh } };
}
