import { Box, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import React from 'react';

import Section from '../../../components/Section';
import SectionHeading from '../../../components/SectionHeading';
import tableIcons from '../../../config/tableIcons';
import SelectionSearch from '../../../containers/SelectionSearch';
import userUsersSlice from '../../user/useUsersSlice';
import Meeting from '../meeting';
import useAttendeesSlice from './useAttendanceSlice';

export default function MeetingAttendance(props: {
  topics: any;
  meetings: any;
  cohort: any;
}) {
  const [selected, setSelected] = React.useState<Meeting | null>(null);
  const usersSlice = userUsersSlice({ cohort_id: props.cohort.id }, false);
  // const { data: users } = usersSlice.state;
  const attendeesSlice = useAttendeesSlice(selected?.details.id);
  const { data, isLoading } = attendeesSlice.state;
  const actions = attendeesSlice.actions;

  //return all the meetings
  const meetings = React.useMemo(() => {
    return [...props.meetings];
  }, [props.meetings]);

  //sets meeting selected as selected
  const handleChange = React.useCallback(
    (meeting: Meeting | null | string) => {
      if (typeof meeting !== 'string') {
        if (meeting === null) {
          setSelected(null);
        } else {
          setSelected(meeting);
        }
      }
    },
    [setSelected]
  );
  //end Select a meeting

  // Create data object
  const createAttendeesData = (
    user_uuid: string,
    studyInitials: string,
    displayName: string | null,
    attendance: any
  ) => {
    if (displayName === null) {
      displayName = 'No Input';
    }
    return { user_uuid, studyInitials, displayName, attendance };
  };

  // create Attendee Table
  const createAttendeesTable = () => {
    let rows: any = [];
    if (usersSlice.state.data.length > 0) {
      if (data.length > 0) {
        data.forEach(data => {
          let currentUser = usersSlice.state.data.filter(user => {
            return user.uuid === data.user_uuid;
          });

          if (currentUser[0]) {
            rows.push(
              createAttendeesData(
                data.user_uuid,
                currentUser[0].study_initials,
                currentUser[0].settings.display_name,
                `${data.attendance}`
              )
            );
          }
        });
      }
    }
    return rows;
  };

  //  it should check if there are new users or more users than attendees on the list. if so then add the new user witha null attendance status.
  // const setEverything = React.useMemo(() => {
  //   if (selected) {
  //     if (users.length > data.length && data.length === 0) {
  //       actions.updateAttendees(
  //         selected?.id,
  //         users.map(user => {
  //           return {
  //             user_uuid: user.uuid,
  //             attendance: null,
  //           };
  //         })
  //       );
  //     } else if (users.length > data.length && data.length !== 0) {
  //       let addMissingUsersArray: any = [];
  //       users.forEach(user => {
  //         addMissingUsersArray.push({ user_uuid: user.uuid, attendance: null });
  //       });

  //       addMissingUsersArray.forEach((person: any) => {
  //         data.forEach(attendee => {
  //           if (attendee.user_uuid === person.user_uuid) {
  //             return (person.attendance = attendee.attendance);
  //           }
  //         });
  //       });

  //       actions.updateAttendees(
  //         selected?.id,
  //         addMissingUsersArray.map((user: any) => {
  //           return {
  //             user_uuid: user.user_uuid,
  //             attendance: user.attenance,
  //           };
  //         })
  //       );
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [users, data]);

  //update multiple rows attendance
  const bulkUpdate = async (data: any) => {
    let updateData: any = [];
    Object.values(data).forEach((row: any) => {
      let userAttendance: string | null = '';
      if (row.newData.attendance === 'null') {
        userAttendance = null;
      } else {
        userAttendance = row.newData.attendance;
      }
      updateData.push({
        user_uuid: row.oldData.user_uuid,
        attendance: userAttendance,
      });
    });
    try {
      if (updateData.length > 0) {
        if (selected) {
          await actions.updateAttendees(selected.id, updateData);
        }
      }
    } catch (reason) {
      console.error(reason);
    }
  };

  return (
    <React.Fragment>
      <Section variant="outlined">
        <Typography variant="body2" paragraph></Typography>
        <SectionHeading heading="Meeting Attendance" />
        {meetings.length > 0 ? (
          <Box width="100%" my={2} key={meetings[0].id}>
            <SelectionSearch
              label="Search Meeting"
              options={meetings}
              getOptionLabel={option =>
                `${option.details.topic}, Date: ${new Date(
                  option.details.start_time
                ).toLocaleString()}`
              }
              onSelect={handleChange}
            />
          </Box>
        ) : null}

        {selected ? (
          <Section variant="outlined">
            <MaterialTable
              title="Set Meeting Attendance"
              components={{ Container: Box }}
              isLoading={isLoading}
              icons={tableIcons}
              columns={[
                {
                  title: 'Study Initials',
                  field: 'studyInitials',
                  defaultSort: 'asc',
                  editable: 'never',
                },
                {
                  title: 'Display Name',
                  field: 'displayName',
                  editable: 'never',
                },
                {
                  title: 'user_uuid',
                  field: 'user_uuid',
                  hidden: true,
                  editable: 'never',
                },
                {
                  title: 'Attendance',
                  field: 'attendance',
                  lookup: { null: 'None', half: 'Partial', full: 'Full' },
                },
              ]}
              data={createAttendeesTable()}
              editable={{
                onBulkUpdate: bulkUpdate,
                onRowUpdate: async (newData, oldData) => {
                  try {
                    await actions.updateAttendees(selected.id, [
                      {
                        user_uuid: oldData!.user_uuid,
                        attendance:
                          newData.attendance === 'null'
                            ? null
                            : newData.attendance,
                      },
                    ]);
                  } catch (reason) {
                    console.error(reason);
                  }
                },
              }}
              options={{
                search: false,
                exportButton: true,
                exportAllData: true,
                exportFileName: 'Meeting Attendance',
                pageSize: 10,
                emptyRowsWhenPaging: false,
                rowStyle: { width: '30px' },
              }}
            />
          </Section>
        ) : null}
      </Section>
    </React.Fragment>
  );
}
