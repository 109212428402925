import React from 'react';
import { Typography, useTheme } from '@material-ui/core';

export default function RouteTitle(props: { title: React.ReactNode }) {
  const theme = useTheme();
  return (
    <Typography
      variant="h3"
      align="center"
      style={{ margin: theme.spacing(3, 0) }}
    >
      {props.title}
    </Typography>
  );
}
