import { axios } from '../../config/axiosConfig';

const emoURI: string = process.env.REACT_APP_EMO_URI!;

export default async function createNotificationAPI(
  params: {
    notification_text: string;
  },
  user_uuid: string,
  app: string | undefined,
  cohort_id: number | string
) {
  try {
    let endpoint = 'notify';
    if (user_uuid !== 'None') {
      endpoint = `notify?user_uuid=${user_uuid}`;
    }
    if (cohort_id !== 'None') {
      endpoint = `notify?cohort_id=${cohort_id}`;
    }
    // Needed custom endpoint because the notification was a param(multipart form) while the user_uuid was a query (per docs) so i need to seperate them
    const response = await axios.post(
      app === 'sa'
        ? `${emoURI}/api/v1/reach_out/${endpoint}`
        : `/api/v1/reach_out/${endpoint}`,
      params
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
