import { Box } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { AppContext } from '../../AppContext';
import ContentContainer from '../../components/ContentContainer';
import RouteTitle from '../../components/RouteTitle';
import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import SelectionSearch from '../../containers/SelectionSearch';
import { selectAuthUser } from '../auth/authSlice';
import UpdateCopingCoachForm, {
  UpdateCopingCoachFormData,
} from './UpdateCopingCoachForm';
import User from './user';
import useUserSettingsSlice from './useUserSettingsSlice';
import useUsersSlice from './useUsersSlice';

export default function CopingCoach() {
  const authUser = useSelector(selectAuthUser);
  const hasAccess = authUser?.roles[0] !== 'leader';

  const [selectedCoach, setSelectedCoach] = React.useState<User | null>(null);
  const { app } = React.useContext(AppContext);
  const usersSlice = useUsersSlice({ role: 'coping_coach' }, app === 'sa');
  const userSettingsSlice = useUserSettingsSlice(selectedCoach, app === 'sa');
  const { data: coaches } = usersSlice.state;
  const usersActions = usersSlice.actions;
  const { data: settings } = userSettingsSlice.state;
  const settingsActions = userSettingsSlice.actions;

  const handleCoachSelection = (user: string | User | null) => {
    if (typeof user !== 'string') setSelectedCoach(user);
  };

  const handleFormSubmit = async (formData: UpdateCopingCoachFormData) => {
    if (selectedCoach && settings) {
      await usersActions.updateUser(selectedCoach.uuid, {
        // first_name: selectedCoach.first_name,
        // last_name: selectedCoach.last_name,
        email: formData.email,
        cell_phone: formData.phone,
        // study_id: selectedCoach.study_id,
        // study_initials: selectedCoach.study_initials,
        // // home_phone: string;
        // cohort_id: selectedCoach.cohort?.id,
        // roles: 'coping_coach',
        // settings: {
        //   about_me_short: formData.shortAbout,
        //   about_me_long: formData.longAbout,
        // },
      });
      const updatedCoach = await settingsActions.updateUserSettings({
        about_me_long: formData.longAbout,
        about_me_short: formData.shortAbout,
      });
      setSelectedCoach(updatedCoach ?? null);
      usersActions.refresh();
    }
  };

  if (!hasAccess) {
    return <Redirect to="/forbidden" />;
  }

  return (
    <ContentContainer maxWidth="lg">
      <RouteTitle title="Manage Coping Coach" />
      {coaches && (
        <Section variant="outlined">
          <SectionHeading heading="Select a Coping Coach" />
          <Box width="100%" my={2}>
            <SelectionSearch
              label="Search Coaches"
              options={coaches}
              getOptionLabel={option =>
                `<ID: [${option.study_id}]>  <Initials: [${
                  option.study_initials
                }]>  [${
                  !Boolean(option.settings.display_name)
                    ? 'Anonymous'
                    : option.settings.display_name
                }]`
              }
              onSelect={handleCoachSelection}
            />
          </Box>
        </Section>
      )}
      {selectedCoach && (
        <Section variant="outlined">
          <SectionHeading heading="Update Info" />
          <UpdateCopingCoachForm
            copingCoach={selectedCoach}
            onSubmit={handleFormSubmit}
          />
        </Section>
      )}
    </ContentContainer>
  );
}
