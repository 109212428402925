import Axios, { AxiosInstance } from 'axios';

const CancelToken = Axios.CancelToken;

let jwtTokenInterceptID = 0;

const axios = Axios.create({
  baseURL: process.env.REACT_APP_PSS_URI!
});

function injectJWTToken(instance: AxiosInstance, jwtToken: string) {
  jwtTokenInterceptID = instance.interceptors.request.use((config) => {
    config.headers = config.headers ?? {};
    config.headers['Authorization'] = jwtToken;
    return config;
  });
}

function ejectJWTToken(instance: AxiosInstance) {
  instance.interceptors.request.eject(jwtTokenInterceptID);
}

export { axios, injectJWTToken, ejectJWTToken, CancelToken };
