import { Box, List, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';

import Section from '../../../components/Section';
import SectionHeading from '../../../components/SectionHeading';
import SelectionSearch from '../../../containers/SelectionSearch';
import User from '../user';
import SafetyReportContainer from '../../check_in/daily_safety_report/SafetyReportContainer';
import GainsTable from '../../gains/gainsTable';

export default function SAUserSettings(props: { user: User }) {
  const { user } = props;

  const [selectedTracker, setSelectedTracker] = React.useState('');

  const trackers = user.settings.trackers;

  const handleSelection = (selection: string | null) => {
    setSelectedTracker(selection ?? '');
  };

  return (
    <div>
      <Section variant="outlined">
        <SectionHeading heading="Trackers" />
        <SelectionSearch
          label="Select Tracker"
          options={Object.keys(user.settings.trackers)}
          getOptionLabel={option => option}
          onSelect={handleSelection}
        />
        {trackers[selectedTracker]?.length !== 0 ? (
          <List>
            {trackers[selectedTracker]?.map((item, i) => (
              <ListItem
                key={i}
                divider={i !== trackers[selectedTracker].length - 1}
              >
                <ListItemText
                  primary={
                    typeof item === 'object' ? (
                      <span>
                        <b>{`${item[0]} `}</b>
                        {item[1]}
                      </span>
                    ) : (
                      item
                    )
                  }
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Box width="100%" p={5} textAlign="center">
            No record to display.
          </Box>
        )}
      </Section>
      <SafetyReportContainer user={user} />
      <GainsTable user={user} />
    </div>
  );
}
