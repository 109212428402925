import React from 'react';
import CheckIn from './checkIn';
import { getCheckInsListAPI } from './checkInAPI';
import { useSnackbar } from 'notistack';

interface CheckInsState {
  isLoading: boolean;
  error: Error | null;
  data: CheckIn[];
}

export default function useCheckInsSlice(
  userUUID?: string,
  filter?: { start?: string; end?: string; session_uuid?: string; type?: string },
) {
  const [state, setState] = React.useState<CheckInsState>({
    isLoading: false,
    error: null,
    data: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  const refresh = React.useCallback(async () => {
    if (userUUID === undefined) return;
    try {
      setState(prev => ({ ...prev, isLoading: true }));
      const checkIns = await getCheckInsListAPI(userUUID, filter);
      setState(prev => ({ ...prev, isLoading: false, data: checkIns }));
    } catch (reason) {
      const error = new Error(reason);
      setState(prev => ({ ...prev, isLoading: false, error }));
      enqueueSnackbar(reason, { variant: 'error' });
    }
  }, [userUUID, filter, enqueueSnackbar]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return { state, actions: { refresh } };
}
