import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import React from 'react';

import User from '../user/user';
import useHelpCopeSlice from './useHelpCopeSlice';

interface UserHelpCopeListProps {
  user: User;
}

export default function UserHelpCopeList(props: UserHelpCopeListProps) {
  const { user } = props;

  const helpCopeSlice = useHelpCopeSlice({ user_uuid: user.uuid });
  const { data: helpCopeList, /* error, */ isLoading } = helpCopeSlice.state;

  if (isLoading) {
    return (
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" thickness={2} size={48} />
      </Box>
    );
  }

  return (
    <List aria-label="help cope list">
      {helpCopeList.length > 0 ? (
        helpCopeList.map((helpCope) => (
          <ListItem key={helpCope.id}>
            <ListItemText primary={helpCope.text} />
          </ListItem>
        ))
      ) : (
        <Box textAlign="center" width="100%" p={2}>
          No records to display
        </Box>
      )}
    </List>
  );
}
