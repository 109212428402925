import { axios } from '../../config/axiosConfig';

const emoURI: string = process.env.REACT_APP_EMO_URI!;

/**
 *
 * @param uuid The avatar's uuid
 */
export async function getUserAvatarURLAPI(uuid: string, sa?: boolean) {
  try {
    const response = await axios.get<{ url: string }>(
      `${
        sa
          ? `${emoURI}/api/v1/avatar/`
          : '/api/v1/avatar/'
      }${uuid}/url`
    );
    return response.data.url;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
