import { axios } from '../../config/axiosConfig';
import Role from './role';
import User from '../user/user';

export async function getRolesAPI(show_permissions: boolean) {
  try {
    const response = await axios.get<{ roles: Array<Role> }>('/api/v1/roles', {
      params: { show_permissions },
    });
    return response.data.roles;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getUserRolesAPI(uuid: string, show_permissions: boolean) {
  try {
    const response = await axios.get<{ roles: Array<Role>; user: User }>(
      `/api/v1/user/${uuid}/roles`,
      { params: { show_permissions } }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
