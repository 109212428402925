import { axios } from '../../config/axiosConfig';
import Commitment from './commitment';

export async function getCommitmentsListAPI(filter: {
  topic_id?: number;
  cohort_id?: number;
  user_uuid?: string;
  is_deleted?: boolean;
}) {
  try {
    const response = await axios.get<{
      commitments: Commitment[];
    }>('/api/v1/commitments', {
      params: filter,
    });
    return response.data.commitments;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
