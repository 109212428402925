import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import MaterialTable from 'material-table';
import React from 'react';
import { useSelector } from 'react-redux';

import SectionHeading from '../../components/SectionHeading';
import tableIcons from '../../config/tableIcons';
import usePowerUpsSlice from '../power_up/usePowerUpsSlice';
import User from '../user/user';
import Topic from './topic';
import { selectTopicsMap } from './topicSlice';

export default function PowerUpTable(props: {
  type: string;
  topic?: Topic;
  user?: User;
  title?: string;
  showTopicName?: boolean;
}) {
  const { type, topic, user, title, showTopicName } = props;

  const [responseType, setResponseType] = React.useState('Has Tried It');

  const powerUpsSlice = usePowerUpsSlice({
    topic_id: topic?.id,
    user_uuid: user?.uuid,
    is_not_interested: responseType === 'Not Interested',
    has_tried_it: responseType === 'Has Tried It',
    type,
  });

  const { data: powerUps, /* error, */ isLoading } = powerUpsSlice.state;
  const actions = powerUpsSlice.actions;

  const topicsMap = useSelector(selectTopicsMap);

  const handleChange = () => {
    if (responseType === 'Has Tried It') {
      setResponseType('Not Interested');
    } else {
      setResponseType('Has Tried It');
    }
  };

  const columns = React.useMemo(() => {
    const headings = [
      { title: 'Topic', field: 'topicName', editable: 'never' },
      {
        title: 'Question',
        field: 'text_one',
      },
      { title: 'Info', field: 'text_two' },
    ];
    if (!showTopicName) {
      headings.shift();
    }
    return headings;
  }, [showTopicName]);

  const data = React.useMemo(
    () =>
      powerUps.map(powerUp => {
        const row = {
          text_one: powerUp.text_one,
          text_two: powerUp.text_two,
          id: powerUp.id,
          topicID: powerUp.topic_id,
          topicName:
            topicsMap && topicsMap[powerUp.topic_id]
              ? topicsMap[powerUp.topic_id].name
              : 'Unavailable',
        };
        if (!showTopicName) {
          // @ts-ignore
          delete row.topicName;
        }
        return row;
      }),
    [powerUps, showTopicName, topicsMap]
  );

  return (
    <React.Fragment>
      {user && (
        <FormControl fullWidth variant="filled">
          <InputLabel variant="filled" id="select-response-label">
            Select Response Type
          </InputLabel>
          <Select
            labelId="select-response-label"
            id="select-response"
            value={responseType}
            onChange={handleChange}
          >
            <MenuItem value="Has Tried It">Has Tried It</MenuItem>
            <MenuItem value="Not Interested">Not Interested</MenuItem>
          </Select>
        </FormControl>
      )}
      <MaterialTable
        isLoading={isLoading}
        title={<SectionHeading heading={title ?? 'Power Ups'} />}
        components={{ Container: Box }}
        icons={tableIcons}
        columns={columns as any}
        data={data}
        editable={
          topic
            ? {
                onRowAdd: async newData => {
                  try {
                    await actions.createPowerUp({
                      topic_id: topic.id,
                      text_one: newData.text_one,
                      text_two: newData.text_two,
                      type,
                    });
                  } catch (reason) {
                    console.error(reason);
                  }
                },

                onRowUpdate: async (newData, oldData) => {
                  try {
                    await actions.updatePowerUp(oldData!.id, {
                      topic_id: oldData!.topicID,
                      text_one: newData.text_one,
                      text_two: newData.text_two,
                      type,
                    });
                  } catch (reason) {
                    console.error(reason);
                  }
                },
                onRowDelete: async oldData => {
                  try {
                    await actions.deletePowerUp(oldData.id);
                  } catch (reason) {
                    console.error(reason);
                  }
                },
              }
            : undefined
        }
        actions={[
          {
            icon: RefreshIcon,
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: () => actions.refresh(),
          },
        ]}
        options={{
          search: false,
          // draggable: false,
          exportButton: true,
          exportAllData: true,
          exportFileName: `${user?.study_id}_powerups`,
        }}
      />
    </React.Fragment>
  );
}
