import { GroupChat } from './groupChat';
import React from 'react';
import { useSnackbar } from 'notistack';
import { QueryGroupChatsList, getGroupChatsListAPI } from './groupChatAPI';

interface GroupChatsState {
  isLoading: boolean;
  error: Error | null;
  data: GroupChat[];
}

export default function useGroupChatsSlice(query?: QueryGroupChatsList) {
  const [state, setState] = React.useState<GroupChatsState>({
    isLoading: false,
    error: null,
    data: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  const refresh = React.useCallback(async () => {
    try {
      setState(prev => ({ ...prev, isLoading: true }));
      const chatList = await getGroupChatsListAPI(query);
      setState(prev => ({ ...prev, data: chatList }));
    } catch (reason) {
      const error = new Error(reason);
      setState(prev => ({ ...prev, error }));
      enqueueSnackbar(reason, { variant: 'error' });
    } finally {
      setState(prev => ({ ...prev, isLoading: false }));
    }
  }, [query, setState, enqueueSnackbar]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return { state, actions: { refresh } };
}

export type UseGroupChatsSlice = ReturnType<typeof useGroupChatsSlice>