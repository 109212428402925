import 'react-quill/dist/quill.snow.css';

import { Box, Button, Grid, TextField } from '@material-ui/core';
import ImageResize from 'quill-image-resize-module-react';
import VideoResize from 'quill-video-resize-module2';
import React from 'react';
import { useForm } from 'react-hook-form';
import ReactQuill, { Quill } from 'react-quill';

import { LearnPost } from './learn';

function imageHandler(this: any) {
  var range = this.quill.getSelection(true);
  var value = prompt('What is the image URL');
  this.quill.insertEmbed(
    range.index,
    'image',
    value,
    (Quill as any).sources.USER
  );
}

Quill.register('modules/VideoResize', VideoResize);

Quill.register('modules/imageResize', ImageResize);
export type LearnPostFormData = {
  subcategory: string;
  text: string;
};

export default function LearnPostForm(props: {
  post?: LearnPost;
  onSubmit?: (formData: LearnPostFormData) => void;
  onDelete?: (post: LearnPost) => void;
}) {
  const { post, onSubmit, onDelete } = props;
  const [editorValue, setEditorValue] = React.useState('');

  const {
    register,
    handleSubmit,
    errors,
    // setValue,
    // getValues,
    reset,
  } = useForm<LearnPostFormData>({
    defaultValues: {
      subcategory: post?.subcategory ?? '',
      text: post?.text ?? '',
    },
  });

  const _onSubmit = handleSubmit(formData => {
    if (onSubmit) {
      onSubmit({
        ...formData,
        text: `<html><head><meta name="viewport" content="width=device-width, user-scalable=no, initial-scale=1, height=device-height"/>
        <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet"/><style>body {color: black; font-size: 14px; font-family: 'Montserrat', sans-serif;} html { -webkit-text-size-adjust:100%; text-size-adjust:auto}</style></head><body>${editorValue}</body></html>`,
      });
    }
  });

  const handleDelete = (post: LearnPost) => () => {
    if (onDelete) onDelete(post);
  };

  React.useEffect(() => {
    if (post) {
      reset({ text: post.text ?? '', subcategory: post.subcategory ?? '' });
    }
  }, [post, reset]);

  React.useEffect(() => {
    setEditorValue(post?.text ?? '');
  }, [post]);

  return (
    <form onSubmit={_onSubmit}>
      <Box width="100%" my={2}>
        <TextField
          fullWidth
          label="Post Name (subcategory)"
          variant="filled"
          name="subcategory"
          inputRef={register({ required: 'Required' })}
          helperText={errors.subcategory?.message}
          error={Boolean(errors.subcategory)}
          margin="normal"
        />
        <ReactQuill
          theme="snow"
          modules={{
            toolbar: {
              container: [
                [{ font: [] }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
                ['blockquote', 'code-block'],
                [
                  { list: 'ordered' },
                  { list: 'bullet' },
                  { indent: '-1' },
                  { indent: '+1' },
                ],
                [{ direction: 'rtl' }, { align: [] }],
                ['link', 'image', 'video', 'formula'],
                ['clean'], // remove formatting button
              ],
              handlers: {
                image: imageHandler,
              },
            },
            imageResize: {
              parchment: Quill.import('parchment'),
              modules: ['Resize', 'DisplaySize'],
              // See optional "config" below
            },
            VideoResize: {
              modules: ['Resize', 'DisplaySize'],
              tagName: 'iframe', // iframe | video
            },
          }}
          value={editorValue}
          onChange={setEditorValue}
        />
        {/* </Section> */}
      </Box>
      <Box display="flex" width="100%">
        <Grid container spacing={1} justify="flex-end">
          <Grid item>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              type="submit"
            >
              {post ? 'Update Post' : 'Create Post'}
            </Button>
          </Grid>
          {post && (
            <Grid item>
              <Button
                variant="contained"
                disableElevation
                color="secondary"
                onClick={handleDelete(post)}
              >
                Delete Post
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </form>
  );
}
