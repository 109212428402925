import { useSnackbar } from 'notistack';
import React from 'react';

import PowerUp from './powerUp';
import {
  createPowerUpAPI,
  deletePowerUpAPI,
  GetPowerUpsFilter,
  getPowerUpsListAPI,
  PowerUpUploadArgs,
  updatePowerUpAPI,
} from './powerUpAPI';

interface PowerUpsState {
  isLoading: boolean;
  error: Error | null;
  data: PowerUp[];
}

type PowerUpsAction =
  | {
      type: 'request/getPowerUps';
    }
  | { type: 'success/getPowerUps'; payload: PowerUp[] }
  | { type: 'reject/getPowerUps'; payload: Error }
  | { type: 'request/createPowerUp' }
  | { type: 'success/createPowerUp'; payload: PowerUp }
  | { type: 'reject/createPowerUp'; payload: Error }
  | { type: 'request/updatePowerUp' }
  | { type: 'success/updatePowerUp'; payload: PowerUp }
  | { type: 'reject/updatePowerUp'; payload: Error }
  | { type: 'request/deletePowerUp' }
  | { type: 'success/deletePowerUp'; payload: PowerUp }
  | { type: 'reject/deletePowerUp'; payload: Error };

function reducer(state: PowerUpsState, action: PowerUpsAction): PowerUpsState {
  switch (action.type) {
    case 'request/getPowerUps':
    case 'request/createPowerUp':
    case 'request/updatePowerUp':
    case 'request/deletePowerUp':
      return { ...state, isLoading: true };
    case 'success/getPowerUps':
      return { ...state, isLoading: false, data: action.payload };
    case 'success/createPowerUp':
      return {
        ...state,
        isLoading: false,
        data: [...state.data, action.payload],
      };
    case 'success/updatePowerUp':
    case 'success/deletePowerUp':
      const index = state.data.findIndex(
        powerUp => powerUp.id === action.payload.id
      );
      if (index > -1) {
        const data =
          action.type === 'success/updatePowerUp'
            ? [
                ...state.data.slice(0, index),
                action.payload,
                ...state.data.slice(index + 1),
              ]
            : [...state.data.slice(0, index), ...state.data.slice(index + 1)];
        return { ...state, isLoading: false, data: data };
      }
      return state;
    case 'reject/getPowerUps':
    case 'reject/createPowerUp':
    case 'reject/updatePowerUp':
    case 'reject/deletePowerUp':
      return { ...state, isLoading: false, error: action.payload };
    default:
      throw new Error('Action does not exists on PowerUpsSlice');
  }
}

export default function usePowerUpsSlice(filter: GetPowerUpsFilter) {
  const [state, dispatch] = React.useReducer(reducer, {
    isLoading: false,
    error: null,
    data: [],
  });

  const { type, topic_id, user_uuid, is_not_interested, has_tried_it } = filter;

  const { enqueueSnackbar } = useSnackbar();

  const createPowerUp = React.useCallback(
    async (args: PowerUpUploadArgs) => {
      try {
        dispatch({ type: 'request/createPowerUp' });
        const powerUps = await createPowerUpAPI(args);
        dispatch({ type: 'success/createPowerUp', payload: powerUps });
        enqueueSnackbar(`Power Up CREATED`, { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        dispatch({ type: 'reject/createPowerUp', payload: error });
        enqueueSnackbar(reason, { variant: 'error' });
        throw error;
      }
    },
    [dispatch, enqueueSnackbar]
  );

  const updatePowerUp = React.useCallback(
    async (id: number, args: PowerUpUploadArgs) => {
      try {
        dispatch({ type: 'request/updatePowerUp' });
        const updatedPowerUp = await updatePowerUpAPI(id, args);
        dispatch({ type: 'success/updatePowerUp', payload: updatedPowerUp });
        enqueueSnackbar(`Power Up UPDATED`, { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        dispatch({ type: 'reject/updatePowerUp', payload: error });
        enqueueSnackbar(reason, { variant: 'error' });
        throw error;
      }
    },
    [dispatch, enqueueSnackbar]
  );

  const deletePowerUp = React.useCallback(
    async (id: number) => {
      try {
        dispatch({ type: 'request/deletePowerUp' });
        const deletedPowerUp = await deletePowerUpAPI(id);
        dispatch({ type: 'success/deletePowerUp', payload: deletedPowerUp });
        enqueueSnackbar(`Power Up DELETED`, { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        dispatch({ type: 'reject/deletePowerUp', payload: error });
        enqueueSnackbar(reason, { variant: 'error' });
        throw error;
      }
    },
    [dispatch, enqueueSnackbar]
  );

  const refresh = React.useCallback(async () => {
    try {
      dispatch({ type: 'request/getPowerUps' });
      const powerUps = await getPowerUpsListAPI({
        topic_id,
        user_uuid,
        is_not_interested,
        has_tried_it,
        type,
      });
      dispatch({ type: 'success/getPowerUps', payload: powerUps });
    } catch (reason) {
      const error = new Error(reason);
      dispatch({ type: 'reject/getPowerUps', payload: error });
      enqueueSnackbar(reason, { variant: 'error' });
      throw error;
    }
  }, [
    dispatch,
    type,
    topic_id,
    user_uuid,
    is_not_interested,
    has_tried_it,
    enqueueSnackbar,
  ]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    state,
    actions: { createPowerUp, updatePowerUp, deletePowerUp, refresh },
  };
}
