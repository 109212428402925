import { useSnackbar } from 'notistack';
import React from 'react';

import { Attendees } from './attendance';
import { getAttendeesListAPI, updateAttendeesListsAPI } from './attendanceAPI';

interface AttendeesState {
  isLoading: boolean;
  error: Error | null;
  data: Attendees[];
}

export default function useAttendeesSlice(meeting_id?: number) {
  const [state, setState] = React.useState<AttendeesState>({
    isLoading: false,
    error: null,
    data: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  const updateAttendees = React.useCallback(
    async (
      meeting_id: number,
      data: { user_uuid: string; attendance: string | null }[]
    ) => {
      try {
        setState(prev => ({ ...prev, isLoading: true }));
        const updatedAttendees = await updateAttendeesListsAPI(
          meeting_id,
          data
        );
        setState(prev => ({
          ...prev,
          isLoading: false,
          data: updatedAttendees,
        }));
      } catch (reason) {
        const error = new Error(reason);
        setState(prev => ({ ...prev, isLoading: false, error }));
        enqueueSnackbar(reason, { variant: 'error' });
      }
    },
    [enqueueSnackbar, setState]
  );

  const refresh = React.useCallback(
    async meeting_id => {
      if (meeting_id) {
        try {
          setState(prev => ({ ...prev, isLoading: true }));
          const attendees = await getAttendeesListAPI(meeting_id);
          setState(prev => ({ ...prev, isLoading: false, data: attendees }));
        } catch (reason) {
          const error = new Error(reason);
          setState(prev => ({ ...prev, isLoading: false, error }));
          enqueueSnackbar(reason, { variant: 'error' });
        }
      }
    },
    [enqueueSnackbar, setState]
  );

  React.useEffect(() => {
    refresh(meeting_id);
  }, [refresh, meeting_id]);

  return { state, actions: { refresh, updateAttendees } };
}
