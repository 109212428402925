import { axios } from '../../config/axiosConfig';
import { TopicReflection,SafetySurpriseResponses } from './ratings';

export async function TopicReflectionAPI(params: { cohort_id: number }) {
  try {
    const response = await axios.get<{
      topic_reflections: TopicReflection[];
    }>('/api/v1/topic/reflections', {
      params,
    });
    return response.data.topic_reflections;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
export async function SafetyReflectionAPI(params: { cohort_id: number }) {
  try {
    const response = await axios.get<{
      safety_surprise_responses: SafetySurpriseResponses[];
    }>('/api/v1/safety_surprise_responses', {
      params,
    });
    return response.data.safety_surprise_responses;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}