import { useSnackbar } from 'notistack';
import React from 'react';

import { getUserAvatarURLAPI } from './avatarAPI';

interface AvatarState {
  isLoading: boolean;
  error: Error | null;
  data: string | null;
}

export default function useAvatarSlice(uuid: string | null, sa?:boolean) {
  const [state, setState] = React.useState<AvatarState>({
    isLoading: false,
    error: null,
    data: null,
  });

  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (uuid) {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      getUserAvatarURLAPI(uuid, sa)
        .then((url) => {
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            data: url,
          }));
        })
        .catch((reason) => {
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            error: new Error(reason),
          }));
          enqueueSnackbar(reason, { variant: 'error' });
        });
    } else {
      setState((prevState) => ({ ...prevState, isLoading: false, data: null }));
    }
  }, [uuid, setState, enqueueSnackbar, sa]);

  return { state };
}
