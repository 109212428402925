import { AxiosResponse } from 'axios';

import { axios } from '../../config/axiosConfig';
import User from '../user/user';

export type LoginArgs = {
  study_id: string;
  study_initials: string;
};

export async function loginAPI(args?: LoginArgs) {
  try {
    let data: FormData | undefined = undefined;
    if (args) {
      data = new FormData();
      data.append('study_id', args.study_id);
      data.append('study_initials', args.study_initials);
    }

    const response: AxiosResponse<{
      access_token: string;
      user: User;
    }> = await axios({
      url: '/api/v1/login',
      method: args ? 'POST' : 'GET',
      data: args ? data: undefined,
      headers:  { 'Content-Type': args ?'multipart/form-data':'application/json' },
    });
    axios.defaults.headers.common['Authorization'] =
      'Bearer ' + response.data.access_token;
    document.cookie = `session=.${response.data.access_token.split('.').slice(1).join('.')}; Path=/`
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function logoutAPI() {
  try {
    await axios.get('/api/v1/logout');
    axios.defaults.headers.common['Authorization'] = undefined;
    return;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
