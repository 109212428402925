// import { Canceler } from 'axios';
import { axios } from '../../config/axiosConfig';
import { GroupChat, GroupChatMessage } from './groupChat';

export interface QueryGroupChatsList {
  cohort_id?: number;
  interest_group_id?: number;
}

export async function getGroupChatsListAPI(query?: QueryGroupChatsList) {
  try {
    const response = await axios.get<{
      group_chats: GroupChat[];
      page: number;
    }>(`/api/v1/group_chats`, { params: query });
    return response.data.group_chats;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export interface GroupChatMessagesByAdminQuery {
  cohort_id?: number;
  group_chat_uuid?: string;
  interest_group_id?: number;
  is_flagged?: boolean;
  is_hidden?: boolean;
  order_by?: string;
  page?: number;
  per_page?: number;
  reverse?: boolean;
  user_uuid?: string;
}

export async function getGroupChatMessagesByAdminAPI(
  query?: GroupChatMessagesByAdminQuery
) {
  try {
    const response = await axios.get<{
      group_chat_messages: GroupChatMessage[];
      page: number;
    }>('/api/v1/group_chat/messages', { params: query });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function unflagGroupChatMessageFlagAPI(
  uuid: string,
) {
  try {
    const response = await axios.put<{
      group_chat_message: GroupChatMessage;
    }>(`/api/v1/group_chat/message/${uuid}/unflag`);
    return response.data.group_chat_message;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function hideGroupChatMessageAPI(uuid: string) {
  try {
    const response = await axios.delete(
      `/api/v1/group_chat/message/${uuid}/hide`
    );

    return response.data.group_chat_message;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
