import { Box, Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { Redirect } from 'react-router-dom';

import { AppContext } from '../../../AppContext';
import ContentContainer from '../../../components/ContentContainer';
import RouteTitle from '../../../components/RouteTitle';
import Section from '../../../components/Section';
import SectionHeading from '../../../components/SectionHeading';
import SelectionSearch from '../../../containers/SelectionSearch';
import User from '../../user/user';
import useUsersSlice from '../../user/useUsersSlice';
import UserEmotionAnalysisTable from './UserEmotionAnalysisTables';

export default function EmotionalAnalysis() {
  const [selectedUser, setSelectedUser] = React.useState<User | null>(null);
  const [start, setStart] = React.useState('');
  const [end, setEnd] = React.useState('');

  const { app } = React.useContext(AppContext);

  const usersSlice = useUsersSlice({}, true);
  const { data: users, isLoading } = usersSlice.state;

  const handleSelection = (user: string | User | null) => {
    if (typeof user !== 'string') {
      setSelectedUser(user);
    }
  };

  const handleChange = (time: 'start' | 'end') => (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    switch (time) {
      case 'start':
        setStart(e.target.value);
        return;
      case 'end':
        setEnd(e.target.value);
        return;
    }
  };

  if (app !== 'sa') {
    return <Redirect to="/" />;
  }

  return (
    <ContentContainer maxWidth="xl">
      <RouteTitle title="Emotional Analysis" />
      <Section variant="outlined">
        <SectionHeading heading="Select a User" />
        <Box width="100%" my={2}>
          <SelectionSearch
            label="Select a User"
            loading={isLoading}
            options={users}
            getOptionLabel={option =>
              `<ID: [${option.study_id}]>  <Initials: [${
                option.study_initials
              }]>  [${
                !Boolean(option.settings?.display_name)
                  ? 'Anonymous'
                  : option.settings.display_name
              }]`
            }
            onSelect={handleSelection}
          />
        </Box>
        {selectedUser && (
          <React.Fragment>
            <Box mb={2}>
              <Typography variant="h6" gutterBottom>
                Select Time Frame
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    type="date"
                    fullWidth
                    variant="filled"
                    label="Start"
                    value={start}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange('start')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="date"
                    fullWidth
                    variant="filled"
                    label="Start"
                    value={end}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange('end')}
                  />
                </Grid>
              </Grid>
            </Box>
            {selectedUser && (
              <UserEmotionAnalysisTable
                user={selectedUser}
                start={
                  Boolean(start) ? new Date(start).toISOString() : undefined
                }
                end={Boolean(end) ? new Date(end).toISOString() : undefined}
              />
            )}
          </React.Fragment>
        )}
      </Section>
    </ContentContainer>
  );
}
