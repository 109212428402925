import { axios } from '../../config/axiosConfig';
import Quiz, { QuizUploadArgs } from './quiz';

export async function createTopicQuizAPI(reqArgs: QuizUploadArgs) {
  try {
    const response = await axios.post<{
      quiz: Quiz;
    }>('/api/v1/quiz/create', JSON.stringify(reqArgs), {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data.quiz;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function updateTopicQuizAPI(id: number, reqArgs: QuizUploadArgs) {
  try {
    const response = await axios.put<{
      quiz: Quiz;
    }>(`/api/v1/quiz/${id}`, JSON.stringify(reqArgs), {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data.quiz;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getTopicQuizAPI(id: number) {
  try {
    const response = await axios.get<{
      quiz: Quiz;
    }>(`/api/v1/quiz/${id}`);
    return response.data.quiz;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getTopicQuizzesAPI(query?: { topic_id?: number }) {
  try {
    const response = await axios.get<{
      quizzes: Array<Quiz>;
    }>('/api/v1/quizzes', { params: query });
    return response.data.quizzes;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
