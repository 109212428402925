import { axios } from '../../config/axiosConfig';
import ScheduleEvent from './event';
import Invitee from './invitee';

export type ScheduledEventFilterParam = Partial<
  Omit<
    ScheduleEvent,
    'id' | 'is_deleted' | 'event_name' | 'event_type' | 'invitees'
  >
> &
  Partial<{ invitees: Array<Pick<Invitee, 'invitee_id' | 'invitee_type'>> }>;

// export type EditScheduledEventReqArgs = Omit<
//   ScheduleEvent,
//   'id' | 'is_deleted' | 'event_name' | 'event_type' | 'invitees'
// > &
//   Partial<Pick<ScheduleEvent, 'event_name' | 'event_type'>> &
//   Partial<{ invitees: Array<Pick<Invitee, 'invitee_id' | 'invitee_type'>> }>;

export type EditScheduledEventReqArgs = Partial<
  Omit<ScheduleEvent, 'id' | 'is_deleted' | 'invitees'>
> & { invitees?: Partial<Invitee>[] };

export async function createScheduledEventAPI(
  reqArgs: EditScheduledEventReqArgs
) {
  try {
    const response = await axios.post<{ scheduled_event: ScheduleEvent }>(
      '/api/v1/scheduled_event/create',
      reqArgs,
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
    return response.data.scheduled_event;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function updateScheduledEventAPI(
  id: string,
  reqArgs: EditScheduledEventReqArgs
) {
  try {
    const response = await axios.put<{ scheduled_event: ScheduleEvent }>(
      `/api/v1/scheduled_event/${id}`,
      reqArgs,
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
    return response.data.scheduled_event;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getFilteredScheduledEventsAPI(
  reqArgs: ScheduledEventFilterParam
) {
  try {
    const response = await axios.post<{
      scheduled_events: Array<ScheduleEvent>;
    }>('/api/v1/scheduled_events', reqArgs, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data.scheduled_events;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function deleteScheduleEventAPI(id: string) {
  try {
    const response = await axios.delete<{
      scheduled_event: ScheduleEvent;
    }>(`/api/v1/scheduled_event/${id}`);
    return response.data.scheduled_event;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
