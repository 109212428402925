import { axios } from '../../config/axiosConfig';
import CheckIn from './checkIn';
import CheckInPrompt from './checkInPrompt';
import { Threshold } from './checkIn';

const emoURI: string = process.env.REACT_APP_EMO_URI!;

export async function getCheckInsListAPI(
  userUUID: string,
  query?: {
    start?: string;
    end?: string;
    session_uuid?: string;
    type?: string;
  }
) {
  try {
    const response = await axios.get<{ check_ins: CheckIn[] }>(
      `${emoURI}/api/v1/user/${userUUID}/check_ins`,
      { params: query }
    );
    return response.data.check_ins;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getCheckInPromptsAPI(type: string) {
  try {
    const response = await axios.get<{ check_in_prompts: CheckInPrompt[] }>(
      `${emoURI}/api/v1/check_in_prompts/${type}`
    );
    return response.data.check_in_prompts;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function updateCheckInPromptsAPI(
  type: string,
  args: { check_in_prompts: Array<Partial<CheckInPrompt>> }
) {
  try {
    const response = await axios.put<{ check_in_prompts: CheckInPrompt[] }>(
      `${emoURI}/api/v1/check_in_prompts/${type}/update`,
      args,
      { headers: { 'Content-Type': 'application/json' } }
    );
    return response.data.check_in_prompts;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function updateThresholdsAPI(params: { threshold: Threshold }) {
  try {
    const response = await axios.post<{ threshold: Threshold }>(
      `${emoURI}/api/v1/threshold/create`,
      params,
      { headers: { 'Content-Type': 'application/json' } }
    );
    return response.data.threshold;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getThresholdAPI() {
  try {
    const response = await axios.get<{ threshold: Threshold }>(
      `${emoURI}/api/v1/threshold`
    );
    return response.data.threshold;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
