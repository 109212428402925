import React from 'react';
import { useSelector } from 'react-redux';
import { selectAuthUser } from '../features/auth/authSlice';
import { Redirect } from 'react-router-dom';

export default function Root() {
  const authUser = useSelector(selectAuthUser);

  if (authUser && authUser.roles[0] === 'leader') {
    return <Redirect to="/profile" />;
  }

  return <Redirect to="/user-admin" />;
}
