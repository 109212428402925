import { axios } from '../../../config/axiosConfig';
import { Note } from './noteToSelf';

const emoURI: string = process.env.REACT_APP_EMO_URI!;

export default async function getNoteToSelfListAPI(userUUID?: string) {
  try {
    const params = {
      user_uuid: userUUID,
    };
    const response = await axios.get<{ notes: Note[] }>(
      `${emoURI}/api/v1/notes`,

      { params }
    );
    return response.data.notes;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
