import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import ContentContainer from '../../components/ContentContainer';
import RouteTitle from '../../components/RouteTitle';
import { selectAuthUser } from '../auth/authSlice';
import ChannelMessageModerationTable from '../channel/ChannelMessageModerationTable';
import GroupChatModeration from '../group_chat/GroupChatModeration';
import SocialFeedCommentsModerationTable from '../my_safe_place/SocialFeedCommentsModerationTable';
import SocialFeedModerationTable from '../my_safe_place/SocialFeedModerationTable';
import { AppContext } from '../../AppContext';

export default function Moderation() {
  const authUser = useSelector(selectAuthUser);
  const hasAccess = authUser?.roles[0] !== 'leader';
  const {app} = React.useContext(AppContext);

  if (app !== 'ss') {
    return <Redirect to="/" />;
  }

  if (!hasAccess) {
    return <Redirect to="/forbidden" />;
  }

  return (
    <ContentContainer maxWidth="lg">
      <RouteTitle title="Moderation" />
      <SocialFeedModerationTable />
      <SocialFeedCommentsModerationTable />
      <ChannelMessageModerationTable />
      <GroupChatModeration />
    </ContentContainer>
  );
}
