import { axios } from '../../config/axiosConfig';
import ChannelMessage from './channelMessage';

export interface ChannelMessagesByAdminQuery {
  is_flagged?: boolean;
  is_hidden?: boolean;
  order_by?: string;
  page?: number;
  per_page?: number;
  reverse?: boolean;
  user_uuid?: string;
}

export async function getChannelMessagesByAdminAPI(
  query?: ChannelMessagesByAdminQuery
) {
  try {
    const response = await axios.get<{ messages: ChannelMessage[], page: number }>(
      `/api/v1/channel/messages`,
      { params: query }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function unflagChannelMessageAPI(uuid: string) {
  try {
    const response = await axios.put<{ message: ChannelMessage }>(
      `/api/v1/channel/message/${uuid}/unflag`
    );
    return response.data.message;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function hideChannelMessageAPI(uuid: string) {
  try {
    const response = await axios.delete<{ message: ChannelMessage }>(
      `/api/v1/channel/message/${uuid}/hide`
    );
    return response.data.message;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
