import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MaterialTable from 'material-table';
import React from 'react';

import tableIcons from '../../../config/tableIcons';
import CheckIn from '../checkIn';
import { Note } from '../note_to_self/noteToSelf';

type Columns = {
  category: string;
  response: string;
  score: number;
  type: string;
};

// users checkin analysis
export default function UserSafetyReportTable(props: {
  selfReport: CheckIn;
  isLoading?: boolean;
  notes: Note[];
}) {
  const { selfReport: safetyReport, isLoading } = props;

  // create data objects for rows on tables
  const createData = (
    category: string,
    response: string,
    score: number,
    type: string
  ): Columns => {
    return { category, response, score, type };
  };

  // Creating substance rows for substance report
  const usedSubstances = () => {
    let substanceArray: Columns[] = [];

    if (safetyReport.supplemental?.substances_used) {
      safetyReport.supplemental.substances_used.forEach(substance => {
        // if drug is prescirbed score is zero rather than 3
        if (substance.includes('prescribed')) {
          return (substanceArray = [
            ...substanceArray,
            createData('Drug Used Today', substance, 0, 'substance_addiction'),
          ]);
        } else {
          return (substanceArray = [
            ...substanceArray,
            createData('Drug Used Today', substance, 3, 'substance_addiction'),
          ]);
        }
      });
    }
    return substanceArray;
  };
  // create substance recovery effort rows
  const substanceRecoveryEfforts = () => {
    let substanceRecoveryEffortsArray: Columns[] = [];

    const trackerUpdatesScore = {
      effort: '',
      score: 0,
    };
    // takes tracker info and creates effort and score
    safetyReport.tracker_updates.forEach(substance => {
      if (substance.type === 'self_care_topic') {
        switch (substance.change) {
          case -1:
            trackerUpdatesScore.effort = 'No';
            trackerUpdatesScore.score = 0;
            break;
          case 0:
            trackerUpdatesScore.effort = 'Somewhat';
            trackerUpdatesScore.score = 1;
            break;
          case null:
            trackerUpdatesScore.effort = 'Skipped';
            trackerUpdatesScore.score = 0;
            break;
          case 1:
            trackerUpdatesScore.effort = 'Yes';
            trackerUpdatesScore.score = 2;
            break;
          default:
            trackerUpdatesScore.effort = '';
            trackerUpdatesScore.score = 0;
        }
      } else {
        switch (substance.change) {
          case -1:
            trackerUpdatesScore.effort = 'None';
            trackerUpdatesScore.score = 3;
            break;
          case 0:
            trackerUpdatesScore.effort = 'A Little';
            trackerUpdatesScore.score = 0;
            break;
          case null:
            trackerUpdatesScore.effort = 'Skipped';
            trackerUpdatesScore.score = 0;
            break;
          case 1:
            trackerUpdatesScore.effort = 'A Lot';
            trackerUpdatesScore.score = -3;
            break;
          default:
            trackerUpdatesScore.effort = '';
            trackerUpdatesScore.score = 0;
        }
      }

      let category: string = '';
      // selfcare questions full topic name based on endpoint
      if (substance.type === 'self_care_topic') {
        switch (substance.item) {
          case 'Follow Through Responsibilities':
            category = 'Did you follow through on responsibilities?';
            break;
          case 'Sleep':
            category = 'Did you sleep well?';
            break;
          case 'Safe People Associations':
            category = 'Did you associate with safe people?';
            break;
          case 'Exercise':
            category = 'Did you exercise?';
            break;
          case 'Medication':
            category = 'Did you medicate as prescribed?';
            break;
          case 'Eating Healthy':
            category = 'Did you eat healthy?';
            break;
          case 'Keeping Appointments':
            category = 'Did you keep appointments?';
            break;
          default:
            category = '';
        }
        substanceRecoveryEffortsArray.push(
          createData(
            category,
            trackerUpdatesScore.effort,
            trackerUpdatesScore.score,
            substance.type
          )
        );
      } else {
        substanceRecoveryEffortsArray.push(
          createData(
            `${substance.item} Recovery Effort`,
            trackerUpdatesScore.effort,
            trackerUpdatesScore.score,
            substance.type
          )
        );
      }
    });

    return substanceRecoveryEffortsArray;
  };
  // create craving level row
  const createRowsData = () => {
    const cravingLevels = () => {
      const craving: { cravingLevel: string; score: number } = {
        cravingLevel: '',
        score: 0,
      };
      switch (safetyReport.supplemental?.substance_craving) {
        case 0:
          craving.cravingLevel = 'None';
          craving.score = 0;
          break;
        case 0.33:
          craving.cravingLevel = 'Mild';
          craving.score = 1;
          break;
        case null:
          craving.cravingLevel = "Skip/Don't Know";
          craving.score = 0;
          break;
        case 0.67:
          craving.cravingLevel = 'Medium';
          craving.score = 2;
          break;
        case 1.0:
          craving.cravingLevel = 'Intense';
          craving.score = 3;
          break;
        default:
          craving.cravingLevel = '';
          craving.score = 0;
      }
      return craving;
    };

    const rows = [
      createData(
        'Craving',
        cravingLevels().cravingLevel,
        cravingLevels().score,
        'substance_addiction'
      ),
      ...usedSubstances(),
      ...substanceRecoveryEfforts(),
      createData('', '', 0, 'total'),
    ];
    return rows;
  };
  // divide each substance report into different data sets with each tables data
  const substanceReport = createRowsData().filter(
    row => row.type === 'substance_addiction' || row.type === 'total'
  );
  const mentalHealthReport = createRowsData().filter(
    row => row.type === 'mental_disorder' || row.type === 'total'
  );
  const behavioralAddictionReport = createRowsData().filter(
    row => row.type === 'behavioral_addiction' || row.type === 'total'
  );
  const emotionalGoalsReport = createRowsData().filter(
    row => row.type === 'emotional_goal' || row.type === 'total'
  );
  const selfCareQuestions = createRowsData().filter(
    row => row.type === 'self_care_topic' || row.type === 'total'
  );

  // calculates total score for reports
  const substanceReportTotal = () => {
    let total = 0;
    substanceReport.forEach(row => {
      total = total + row.score;
    });
    if (total < 0) {
      total = 0;
    }
    return total;
  };
  const mentalHealthReportTotal = () => {
    let total = 0;
    mentalHealthReport.forEach(row => {
      total = total + row.score;
    });
    if (total < 0) {
      total = 0;
    }
    return total;
  };
  const behavioralAddictionReportTotal = () => {
    let total = 0;
    behavioralAddictionReport.forEach(row => {
      total = total + row.score;
    });
    if (total < 0) {
      total = 0;
    }
    return total;
  };
  const emotionalGoalsReportTotal = () => {
    let total = 0;
    emotionalGoalsReport.forEach(row => {
      total = total + row.score;
    });
    if (total < 0) {
      total = 0;
    }
    return total;
  };
  const selfCareQuestionsTotal = () => {
    let total = 0;
    let count = 0;
    selfCareQuestions.forEach(row => {
      total = total + row.score;
      if (row.type !== 'total') {
        count += 1;
      }
    });
    return Math.round(total / count);
  };

  //  Create emotion self report table
  const createEmotionData = (
    emotion: string,
    response: string,
    health: string,
    score: number
  ) => {
    return {
      emotion,
      response,
      health,
      score,
    };
  };
  //create table for self report emotions
  const createTable = () => {
    let rows = [
      createEmotionData(
        'Angry',
        emotionalInfo('angry').response,
        emotionalInfo('angry').healthy,
        emotionalInfo('angry').score
      ),
      createEmotionData(
        'Anxious',
        emotionalInfo('anxious').response,
        emotionalInfo('anxious').healthy,
        emotionalInfo('anxious').score
      ),
      createEmotionData(
        'Calm',
        emotionalInfo('calm').response,
        emotionalInfo('calm').healthy,
        emotionalInfo('calm').score
      ),
      createEmotionData(
        'Disgusted',
        emotionalInfo('disgusted').response,
        emotionalInfo('disgusted').healthy,
        emotionalInfo('disgusted').score
      ),
      createEmotionData(
        'Happy',
        emotionalInfo('happy').response,
        emotionalInfo('happy').healthy,
        emotionalInfo('happy').score
      ),
      createEmotionData(
        'Sad',
        emotionalInfo('sad').response,
        emotionalInfo('sad').healthy,
        emotionalInfo('sad').score
      ),
      createEmotionData(
        'Scornful',
        emotionalInfo('scornful').response,
        emotionalInfo('scornful').healthy,
        emotionalInfo('scornful').score
      ),
      createEmotionData(
        'Surprised',
        emotionalInfo('surprised').response,
        emotionalInfo('surprised').healthy,
        emotionalInfo('surprised').score
      ),
    ];
    return rows;
  };

  // sets values for each key in object
  const emotionalInfo = (
    key: keyof Pick<
      CheckIn,
      | 'angry'
      | 'anxious'
      | 'calm'
      | 'disgusted'
      | 'happy'
      | 'sad'
      | 'scornful'
      | 'surprised'
    >
  ): {
    percentage: string;
    score: number;
    healthy: string;
    response: string;
  } => {
    let emoData = {
      percentage: '',
      score: 0,
      healthy: '',
      response: '',
    };
    // immotional score and percentage based off self report emotion response
    switch (safetyReport[key]) {
      case null:
        emoData.percentage = '0%';
        emoData.score = 0;
        emoData.response = "Skip/Don't Know";
        break;
      case 0.0:
        emoData.percentage = '0%';
        emoData.score = 0;
        emoData.response = 'None';
        break;
      case 0.33:
        emoData.percentage = '33%';
        emoData.score = 1;
        emoData.response = 'Mild';
        break;
      case 0.67:
        emoData.percentage = '67%';
        emoData.score = 2;
        emoData.response = 'Medium';
        break;
      case 1.0:
        emoData.percentage = '100%';
        emoData.score = 3;
        emoData.response = 'Intense';
        break;
      default:
        emoData.percentage = '0%';
        emoData.score = 0;
        emoData.response = '';
    }
    // logs if is healthy
    switch ((safetyReport.supplemental as any)[`is_healthy_${key}`]) {
      case true:
        emoData.healthy = 'Healthy';
        break;
      case false:
        emoData.healthy = 'Unhealthy';
        break;
      case null:
        emoData.healthy = 'Not Answered';
        break;
      default:
        emoData.healthy = 'Unhealthy';
    }

    return emoData;
  };

  // emeotional context section
  // takes notes and filters it to relate to a checkin session uuid for checkin context
  const checkinNote = () => {
    let noteBody: string = '';
    let note = props.notes.filter(
      note => note.session_uuid === safetyReport.session_uuid
    )[0];
    if (note) {
      noteBody = note.body;
    } else {
      noteBody = 'Skipped';
    }
    return noteBody;
  };

  const createEmotionalContextData = (
    category: string, // convert to string when case is built
    response: string | string[]
  ) => {
    return { category, response };
  };
  const createEmotionalContextTable = () => {
    let row: any = [];
    let alerts: any = [];
    let alerted = 'No';
    // need to check if there was an alert even if user did not fill out form
    if (safetyReport.alert_details) {
      safetyReport.alert_details.forEach(alert => {
        if (alert.type === 'emotion_alert') {
          alerts.push(alert);
        }
      });
    }
    // need a case statement for different levels of honesty
    if (safetyReport.supplemental) {
      if (safetyReport.supplemental) {
        if (alerts.length > 0) {
          alerted = 'Yes';
        }
        let honesty: string = '';
        switch (safetyReport.supplemental?.honesty) {
          case null:
            honesty = "Skip/Don't Know";
            break;
          case 0.0:
            honesty = 'Not At All';
            break;
          case 0.33:
            honesty = 'Mild';
            break;
          case 0.67:
            honesty = 'A lot';
            break;
          case 1.0:
            honesty = 'Intense';
            break;
          default:
            honesty = '';
        }
        if (safetyReport.supplemental?.tags) {
          row = [
            createEmotionalContextData('Honesty Level', honesty),
            createEmotionalContextData('Tags', safetyReport.supplemental?.tags),
            createEmotionalContextData('Journal', checkinNote()),
            createEmotionalContextData('Checkin Alert', alerted),
          ];
        } else {
          row = [
            createEmotionalContextData('Honesty Level', honesty),
            createEmotionalContextData('Tags', ['Skipped']),
            createEmotionalContextData('Journal', checkinNote()),
            createEmotionalContextData('Checkin Alert', alerted),
          ];
        }
      }
    }
    return row;
  };

  // end EC

  // alert/trigger details
  const createAlertTriggerdata = (
    category: string | null,
    response: string | null
  ) => {
    return { category, response };
  };

  const createAlertTriggerRows = () => {
    const rows: any = {
      alertRows: [],
      triggerRows: [],
    };

    if (safetyReport.alert_details.length > 0) {
      safetyReport.alert_details.forEach(alert => {
        if (alert.type === 'emotion_alert' && alert.what !== '') {
          rows.alertRows = [
            createAlertTriggerdata('Note', alert.note),
            createAlertTriggerdata('What', alert.what),
            createAlertTriggerdata('Where', alert.where),
            createAlertTriggerdata('Who', alert.who),
          ];
        } else if (alert.type === 'trigger_alert' && alert.what !== '') {
          rows.triggerRows = [
            createAlertTriggerdata('Situation', alert.note),
            createAlertTriggerdata('Journal', alert.what),
            createAlertTriggerdata('Where', alert.where),
            createAlertTriggerdata('Who', alert.who),
          ];
        }
      });
    }
    return rows;
  };

  return (
    <Accordion
      variant="outlined"
      TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
      >
        <Typography>{`Check-In - ${
          safetyReport
            ? new Date(safetyReport.created).toLocaleDateString()
            : 'Missing Timestamp'
        }`}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'block' }}>
        <Accordion
          variant="outlined"
          TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography>{'Substance Report'}</Typography>
          </AccordionSummary>

          <AccordionDetails style={{ display: 'block' }}>
            {safetyReport ? (
              <MaterialTable
                isLoading={isLoading}
                components={{ Container: Box }}
                icons={tableIcons}
                columns={[
                  {
                    title: 'Category',
                    field: 'category',
                    render: rowData =>
                      rowData.category.charAt(0).toUpperCase() +
                      rowData.category.slice(1),
                  },

                  {
                    title: 'Response',
                    field: 'response',
                    render: rowData => {
                      if (rowData.response === null) {
                        return 'Skipped';
                      } else {
                        return (
                          rowData.response.charAt(0).toUpperCase() +
                          rowData.response.slice(1)
                        );
                      }
                    },
                  },

                  {
                    title: 'Score',
                    field: 'score',
                    type: 'numeric',
                    render: rowData => {
                      if (rowData.type === 'total') {
                        return (
                          <span>
                            <b>Total Score: </b>
                            {substanceReportTotal()}
                          </span>
                        );
                      } else {
                        return rowData.score;
                      }
                    },
                  },
                ]}
                data={substanceReport}
                options={{
                  showTitle: false,
                  exportButton: true,
                  exportAllData: true,
                  exportFileName: 'Substance Report',
                  search: false,
                  emptyRowsWhenPaging: false,
                  pageSize: 20,
                }}
              />
            ) : (
              <div>"not Working"</div>
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion
          variant="outlined"
          TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography>{'Mental Health Report'}</Typography>
          </AccordionSummary>

          <AccordionDetails style={{ display: 'block' }}>
            {safetyReport ? (
              <MaterialTable
                isLoading={isLoading}
                components={{ Container: Box }}
                icons={tableIcons}
                columns={[
                  {
                    title: 'Category',
                    field: 'category',
                    render: rowData =>
                      rowData.category.charAt(0).toUpperCase() +
                      rowData.category.slice(1),
                  },

                  {
                    title: 'Response',
                    field: 'response',
                    render: rowData => {
                      if (rowData.response === null) {
                        return 'Skipped';
                      } else {
                        return (
                          rowData.response.charAt(0).toUpperCase() +
                          rowData.response.slice(1)
                        );
                      }
                    },
                  },

                  {
                    title: 'Score',
                    field: 'score',
                    type: 'numeric',
                    render: rowData => {
                      if (rowData.type === 'total') {
                        return (
                          <span>
                            <b>Total Score: </b>
                            {mentalHealthReportTotal()}
                          </span>
                        );
                      } else {
                        return rowData.score;
                      }
                    },
                  },
                ]}
                data={mentalHealthReport}
                options={{
                  showTitle: false,
                  exportButton: true,
                  exportAllData: true,
                  exportFileName: 'Mental Health Report',
                  search: false,
                  emptyRowsWhenPaging: false,
                  pageSize: 20,
                }}
              />
            ) : (
              <div>"not Working"</div>
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion
          variant="outlined"
          TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography>{'Behavioral Addiction Report'}</Typography>
          </AccordionSummary>

          <AccordionDetails style={{ display: 'block' }}>
            {safetyReport ? (
              <MaterialTable
                isLoading={isLoading}
                components={{ Container: Box }}
                icons={tableIcons}
                columns={[
                  {
                    title: 'Category',
                    field: 'category',
                    render: rowData =>
                      rowData.category.charAt(0).toUpperCase() +
                      rowData.category.slice(1),
                  },

                  {
                    title: 'Response',
                    field: 'response',
                    render: rowData => {
                      if (rowData.response === null) {
                        return 'Skipped';
                      } else {
                        return (
                          rowData.response.charAt(0).toUpperCase() +
                          rowData.response.slice(1)
                        );
                      }
                    },
                  },

                  {
                    title: 'Score',
                    field: 'score',
                    type: 'numeric',
                    render: rowData => {
                      if (rowData.type === 'total') {
                        return (
                          <span>
                            <b>Total Score: </b>
                            {behavioralAddictionReportTotal()}
                          </span>
                        );
                      } else {
                        return rowData.score;
                      }
                    },
                  },
                ]}
                data={behavioralAddictionReport}
                options={{
                  showTitle: false,
                  exportButton: true,
                  exportAllData: true,
                  exportFileName: 'Behavioral Addiction Report',
                  search: false,
                  emptyRowsWhenPaging: false,
                  pageSize: 20,
                }}
              />
            ) : (
              <div>"not Working"</div>
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion
          variant="outlined"
          TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography>{'Emotional Goals Report'}</Typography>
          </AccordionSummary>

          <AccordionDetails style={{ display: 'block' }}>
            {safetyReport ? (
              <MaterialTable
                isLoading={isLoading}
                components={{ Container: Box }}
                icons={tableIcons}
                columns={[
                  {
                    title: 'Category',
                    field: 'category',
                    render: rowData =>
                      rowData.category.charAt(0).toUpperCase() +
                      rowData.category.slice(1),
                  },

                  {
                    title: 'Response',
                    field: 'response',
                    render: rowData => {
                      if (rowData.response === null) {
                        return 'Skipped';
                      } else {
                        return (
                          rowData.response.charAt(0).toUpperCase() +
                          rowData.response.slice(1)
                        );
                      }
                    },
                  },

                  {
                    title: 'Score',
                    field: 'score',
                    type: 'numeric',
                    render: rowData => {
                      if (rowData.type === 'total') {
                        return (
                          <span>
                            <b>Total Score: </b>
                            {emotionalGoalsReportTotal()}
                          </span>
                        );
                      } else {
                        return rowData.score;
                      }
                    },
                  },
                ]}
                data={emotionalGoalsReport}
                options={{
                  showTitle: false,
                  exportButton: true,
                  exportAllData: true,
                  exportFileName: 'Emotional Goals Report',
                  search: false,
                  emptyRowsWhenPaging: false,
                  pageSize: 20,
                }}
              />
            ) : (
              <div>"not Working"</div>
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion
          variant="outlined"
          TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography>{'Emotional Analysis Report'}</Typography>
          </AccordionSummary>

          <AccordionDetails style={{ display: 'block' }}>
            {safetyReport ? (
              <MaterialTable
                isLoading={isLoading}
                components={{ Container: Box }}
                icons={tableIcons}
                columns={[
                  {
                    title: 'Emotion',
                    field: 'emotion',
                  },
                  {
                    title: 'Response',
                    field: 'response',
                  },
                  {
                    title: 'Healthy?',
                    field: 'health',
                  },
                ]}
                data={createTable()}
                options={{
                  search: false,
                  showTitle: false,
                  exportButton: true,
                  exportAllData: true,
                  exportFileName: 'Emotional Analysis Report',

                  emptyRowsWhenPaging: false,
                  pageSize: 20,
                }}
              />
            ) : (
              <div>"not Working"</div>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          variant="outlined"
          TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography>{'Self Care Questions'}</Typography>
          </AccordionSummary>

          <AccordionDetails style={{ display: 'block' }}>
            {safetyReport ? (
              <MaterialTable
                isLoading={isLoading}
                components={{ Container: Box }}
                icons={tableIcons}
                columns={[
                  {
                    title: 'Category',
                    field: 'category',
                    render: rowData =>
                      rowData.category.charAt(0).toUpperCase() +
                      rowData.category.slice(1),
                  },

                  {
                    title: 'Response',
                    field: 'response',
                    render: rowData => {
                      if (rowData.response === null) {
                        return 'Skipped';
                      } else {
                        return (
                          rowData.response.charAt(0).toUpperCase() +
                          rowData.response.slice(1)
                        );
                      }
                    },
                  },

                  {
                    title: 'Score',
                    field: 'score',
                    type: 'numeric',
                    render: rowData => {
                      if (rowData.type === 'total') {
                        return (
                          <span>
                            <b>Avergare Score: </b>
                            {selfCareQuestionsTotal()}
                          </span>
                        );
                      } else {
                        return rowData.score;
                      }
                    },
                  },
                ]}
                data={selfCareQuestions}
                options={{
                  showTitle: false,
                  exportButton: true,
                  exportAllData: true,
                  exportFileName: 'Self Care Questions',
                  search: false,
                  emptyRowsWhenPaging: false,
                  pageSize: 20,
                }}
              />
            ) : (
              <div>"not Working"</div>
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion
          variant="outlined"
          TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography>{'Emotional Context'}</Typography>
          </AccordionSummary>

          <AccordionDetails style={{ display: 'block' }}>
            {safetyReport ? (
              <MaterialTable
                isLoading={isLoading}
                components={{ Container: Box }}
                icons={tableIcons}
                columns={[
                  {
                    title: 'Category',
                    field: 'category',
                  },

                  {
                    title: 'Response',
                    field: 'response',
                    render: rowData => {
                      if (rowData.category === 'Tags') {
                        return rowData.response.map(
                          (tag: string, i: number) => <p key={i}>{tag}</p>
                        );
                      } else {
                        return rowData.response;
                      }
                    },
                  },
                ]}
                data={createEmotionalContextTable()}
                options={{
                  showTitle: false,
                  exportButton: true,
                  exportAllData: true,
                  exportFileName: 'Emotional Context',
                  search: false,
                  emptyRowsWhenPaging: false,
                  pageSize: 20,
                }}
              />
            ) : (
              <div>"not Working"</div>
            )}
          </AccordionDetails>
        </Accordion>
        {createAlertTriggerRows().triggerRows.length > 0 ? (
          <Accordion
            variant="outlined"
            TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
            >
              <Typography>{'Triggers'}</Typography>
            </AccordionSummary>

            <AccordionDetails style={{ display: 'block' }}>
              <MaterialTable
                isLoading={isLoading}
                components={{ Container: Box }}
                icons={tableIcons}
                columns={[
                  {
                    title: 'Category',
                    field: 'category',
                  },

                  {
                    title: 'Response',
                    field: 'response',
                    render: rowData => {
                      if (
                        rowData.response === null ||
                        rowData.response === ''
                      ) {
                        return 'Skipped';
                      } else {
                        return rowData.response;
                      }
                    },
                  },
                ]}
                data={createAlertTriggerRows().triggerRows}
                options={{
                  showTitle: false,
                  exportButton: true,
                  exportAllData: true,
                  exportFileName: 'Triggered',
                  search: false,

                  emptyRowsWhenPaging: false,
                  pageSize: 20,
                }}
              />
            </AccordionDetails>
          </Accordion>
        ) : null}
        {createAlertTriggerRows().alertRows.length > 0 ? (
          <Accordion
            variant="outlined"
            TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
            >
              <Typography>{'Emotional Alert'}</Typography>
            </AccordionSummary>

            <AccordionDetails style={{ display: 'block' }}>
              <MaterialTable
                isLoading={isLoading}
                components={{ Container: Box }}
                icons={tableIcons}
                columns={[
                  {
                    title: 'Category',
                    field: 'category',
                  },

                  {
                    title: 'Response',
                    field: 'response',
                    render: rowData => {
                      if (
                        rowData.response === null ||
                        rowData.response === ''
                      ) {
                        return 'Skipped';
                      } else {
                        return rowData.response;
                      }
                    },
                  },
                ]}
                data={createAlertTriggerRows().alertRows}
                options={{
                  showTitle: false,
                  exportButton: true,
                  exportAllData: true,
                  exportFileName: 'Emotional Alert',
                  search: false,

                  emptyRowsWhenPaging: false,
                  pageSize: 20,
                }}
              />
            </AccordionDetails>
          </Accordion>
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
}
