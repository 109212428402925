import { Box, Container, Grid } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Section from '../../components/Section';
import { selectAuthUser } from './authSlice';
import LoginForm from './LoginForm';

export default function Login() {
  const user = useSelector(selectAuthUser);

  if (user) {
    if (user.roles[0] === 'leader') {
      return <Redirect to="/profile" />;
    }
    return <Redirect to="/" />;
  }

  return (
    <Container maxWidth="md" style={{ height: '100vh', margin: '0 auto' }}>
      <Grid container style={{ height: '100%', width: '100%' }}>
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <img src="/logo.png" alt="logo" width={320} height={320} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Section variant="outlined" square>
              <LoginForm />
            </Section>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
