import { axios } from '../../config/axiosConfig';
import DiagnosticFormData, {
  DiagnosticAssessmentUpdateForm,
} from './DiagnosticFormData';

export async function getDiagnosticListsURLsAPI(params: { cohort_id: number }) {
  try {
    const response = await axios.get(`api/v1/diagnostic_assessments`, {
      params,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function updateDiagnosticURLsAPI(
  data: DiagnosticAssessmentUpdateForm,
  diagnosticID: number
) {
  try {
    const endpoint = diagnosticID;
    const response = await axios.put<{ diagnostic_url: DiagnosticFormData }>(
      `/api/v1/diagnostic_assessment/${endpoint}`,
      data,
      { headers: { 'Content-Type': 'application/json' } }
    );
    return response.data.diagnostic_url;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function createDiagnosticURLsAPI(
  data: DiagnosticAssessmentUpdateForm
) {
  try {
    const response = await axios.post<{ diagnostic_url: DiagnosticFormData }>(
      `/api/v1/diagnostic_assessment/create`,
      data,
      { headers: { 'Content-Type': 'application/json' } }
    );
    return response.data.diagnostic_url;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function deleteDiagnosticListsURLsAPI() {
  try {
    const response = await axios.delete(
      `/api/v1/diagnostic_assessment/7/delete
      `
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
