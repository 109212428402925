import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import authReducer from '../features/auth/authSlice';
import topicReducer from '../features/topic/topicSlice';
import roleReducer from '../features/role/roleSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  topics: topicReducer,
  roles: roleReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
// Export a hook that can be resused to resolve types
export const useAppDispatch = () => useDispatch<AppDispatch>();
