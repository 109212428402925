import { Canceler } from 'axios';

import { axios, CancelToken } from '../../config/axiosConfig';
import Cohort from './cohort';

export async function createCohortAPI(name: string) {
  try {
    const data = new FormData();
    data.append('name', name);
    const response = await axios.post<{ cohort: Cohort }>(
      '/api/v1/cohort/create',
      data,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    return response.data.cohort;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
export async function updateCohortAPI(
  id: number,
  args: Partial<Pick<Cohort, 'name' | 'enabled_features'>>
) {
  try {
    // const data = new FormData();
    // data.append('name', name);
    const response = await axios.put<{ cohort: Cohort }>(
      `/api/v1/cohort/${id}`,
      args,
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
    return response.data.cohort;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function deleteCohortAPI(id: number) {
  try {
    const response = await axios.delete<{ cohort: Cohort }>(
      `/api/v1/cohort/${id}/delete`
    );
    return response.data.cohort;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getCohortAPI(id: number) {
  try {
    const response = await axios.get<{ cohort: Cohort }>(
      `/api/v1/cohort/${id}`
    );
    return response.data.cohort;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getCohortsListAPI(
  executor?: (canceler: Canceler) => void
) {
  try {
    const response = await axios.get<{ cohorts: Array<Cohort> }>(
      '/api/v1/cohorts',
      { cancelToken: executor ? new CancelToken(executor) : undefined }
    );
    return response.data.cohorts;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
