import { Box,Button, Slider, Typography } from '@material-ui/core';
import React from 'react';
import useThresholdSlice from '../useThresholdSlice'
type Thresholds = {
  text: number;
  video: number;
  audio: number;
};

const sliderMarks = [
  { value: 0, label: '0%' },
  { value: 25, label: '25%' },
  { value: 50, label: '50%' },
  { value: 75, label: '75%' },
  { value: 100, label: '100%' },
];

export default function AnalysisThresholdSlides(props: {
  onTextChangeCommited: (
    event: React.ChangeEvent<{}>,
    value: number | number[]
  ) => void;
  onVideoChangeCommited: (
    event: React.ChangeEvent<{}>,
    value: number | number[]
  ) => void;
  onAudioChangeCommited: (
    event: React.ChangeEvent<{}>,
    value: number | number[]
  ) => void;
  handleUpdateThreshold: (
  ) => void;
}) {
  const { onTextChangeCommited, onVideoChangeCommited, onAudioChangeCommited, handleUpdateThreshold } = props;
  const threshold = useThresholdSlice().state.data
  React.useEffect(()=>{
    setThresholds({
      text: threshold.text,
      video: threshold.video,
      audio: threshold.audio,
    })
  },[threshold])
  const [thresholds, setThresholds] = React.useState<Thresholds>({
    text: 65,
    video: 65,
    audio: 65,
  });

  const handleChange = (type: keyof Thresholds) => (
    event: React.ChangeEvent<{}>,
    value: number | number[]
  ) => {
    setThresholds(prev => ({ ...prev, [type]: value as number }));
  };

  return (
    <Box p={2}>
      <Typography id="text-analytics-threshold" gutterBottom>
        Text Thresholds
      </Typography>
      <Slider
        value={thresholds.text}
        onChange={handleChange('text')}
        onChangeCommitted={onTextChangeCommited}
        aria-labelledby="text-analytics-threshold"
        aria-valuetext="text-threshold-percent"
        valueLabelDisplay="on"
        marks={sliderMarks}
      />
      <Typography id="video-analytics-threshold" gutterBottom>
        Video Threshold
      </Typography>
      <Slider
        value={thresholds.video}
        onChange={handleChange('video')}
        onChangeCommitted={onVideoChangeCommited}
        aria-labelledby="video-analytics-threshold"
        aria-valuetext="video-threshold-percent"
        valueLabelDisplay="on"
        marks={sliderMarks}
      />
      <Typography id="audio-analytics-threshold" gutterBottom>
        Audio Threshold
      </Typography>
      <Slider
        value={thresholds.audio}
        onChange={handleChange('audio')}
        onChangeCommitted={onAudioChangeCommited}
        aria-labelledby="audio-analytics-threshold"
        aria-valuetext="audio-threshold-percent"
        valueLabelDisplay="on"
        marks={sliderMarks}
      />
      <Box width="100%" my={1} display="flex" justifyContent="flex-end">
              <Button onClick={handleUpdateThreshold}
                type="submit"
                color="primary"
                variant="contained"
                disableElevation
              >
                {'Update Threshold'}
              </Button>
            </Box>
    </Box>
  );
}
