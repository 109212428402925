import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import ContentContainer from '../../components/ContentContainer';
import RouteTitle from '../../components/RouteTitle';
import { selectAuthUser } from '../auth/authSlice';
import CreateInterestGroup from './CreateInterestGroup';
import EditInterestGroup from './EditInterestGroup';
import useInterestGroupsSlice from './useInterestGroupsSlice';
import { AppContext } from '../../AppContext';

export default function InterestGroups() {
  const authUser = useSelector(selectAuthUser);
  const hasAccess = authUser?.roles[0] !== 'leader';
  const { app } = React.useContext(AppContext);

  const interestGroupsSlice = useInterestGroupsSlice();

  if (!hasAccess) {
    return <Redirect to="/forbidden" />;
  }

  if (app !== 'ss') {
    return <Redirect to="/" />;
  }

  return (
    <ContentContainer maxWidth="lg">
      <RouteTitle title="Interest Groups" />
      <CreateInterestGroup actions={interestGroupsSlice.actions} />
      <EditInterestGroup slice={interestGroupsSlice} />
    </ContentContainer>
  );
}
