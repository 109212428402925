import { Box } from '@material-ui/core';
import React from 'react';

import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import SelectionSearch from '../../containers/SelectionSearch';
import { GroupChat } from './groupChat';
import GroupChatMessageModerationTable from './GroupChatMessageModerationTable';
import useGroupChatsSlice from './useGroupChatsSlice';

export default function GroupChatModeration() {
  const [selectedChat, setSelectedChat] = React.useState<GroupChat | null>(
    null
  );
  const groupChatsSlice = useGroupChatsSlice();
  const { data } = groupChatsSlice.state;

  const handleSelection = (chat: string | GroupChat | null) => {
    if (typeof chat !== 'string') {
      setSelectedChat(chat);
    }
  };
  return (
    <Section variant="outlined">
      <SectionHeading heading="Group Chat" />
      <Box width="100%" my={2}>
        <SelectionSearch
          label="Search Group Chat"
          options={data}
          getOptionLabel={option => option.name ?? 'UNTITLED'}
          onSelect={handleSelection}
        />
      </Box>
      {selectedChat && (
        <GroupChatMessageModerationTable group_chat_uuid={selectedChat.uuid} />
      )}
    </Section>
  );
}
