import { Box } from '@material-ui/core';
import MaterialTable from 'material-table';
import React from 'react';

import tableIcons from '../../../config/tableIcons';
import CheckIn from '../checkIn';

type Columns = {
  checkin_date: string;
  audio: string;
  video: string;
  text: string;
};

type Session = {
  checkin_date: string;
  audio: string;
  text: string;
  video: string;
};

// Shows users response to if they thought checkin analysis was correct
export default function YouVsAppTable(props: {
  checkins: CheckIn[];
  isLoading?: boolean;
}) {
  const { checkins, isLoading } = props;

  const sessions = Object.values(
    checkins.reduce<{ [session: string]: Session }>(
      (accum, curr, index, array) => {
        const accuracy = curr.supplemental?.is_accurate;
        const nullLabel = 'No Input';
        let label = nullLabel;
        if (accuracy !== null) {
          label = accuracy ? 'Accurate' : 'Not Accurate';
        }
        if (accum[curr.session_uuid] === undefined) {
          accum[curr.session_uuid] = {audio: nullLabel, video: nullLabel, text: nullLabel, checkin_date: nullLabel}
        }
        accum[curr.session_uuid] = {
          ...accum[curr.session_uuid],
          checkin_date: new Date(curr.created).toLocaleDateString(),
          [curr.type as keyof Session]: label,
        };
        return accum;
      },
      {}
    )
  );

  // // grouping each checkin (video, audio, text) based off their session uuid
  const groupCheckins = () => {
    const checkinsObj: any = {};
    checkins.forEach(checkin => {
      if (!checkinsObj[checkin.session_uuid]) {
        checkinsObj[checkin.session_uuid] = [];
        checkinsObj[checkin.session_uuid].push(checkin);
      } else if (checkinsObj[checkin.session_uuid]) {
        checkinsObj[checkin.session_uuid].push(checkin);
      }
    });
    return checkinsObj;
  };

  // create data objects for rows on tables
  const createData = (
    checkin_date: string,
    audio: string,
    video: string,
    text: string
  ): Columns => {
    return { checkin_date, audio, video, text };
  };

  const createTable = () => {
    const rows: Columns[] = [];
    // used the grouped checkins to check if each checkin has a value for is_accurate and then group all 3 results for is_accurate and create a row per session
    Object.values(groupCheckins()).forEach((checkinSession: any) => {
      let video: string = 'No Input';
      let audio: string = 'No Input';
      let text: string = 'No Input';
      checkinSession.forEach((checkin: CheckIn) => {
        switch (checkin.type) {
          case 'video':
            if (checkin.supplemental === null) {
              video = 'No Input';
            } else {
              if (checkin.supplemental.is_accurate === true) {
                video = 'Accurate';
              }
              if (checkin.supplemental.is_accurate === false) {
                video = 'Not Accurate';
              }
            }
            break;
          case 'audio':
            if (checkin.supplemental === null) {
              audio = 'No Input';
            }
            if (checkin.supplemental !== null) {
              if (checkin.supplemental.is_accurate === true) {
                audio = 'Accurate';
              }
              if (checkin.supplemental.is_accurate === false) {
                audio = 'Not Accurate';
              }
            }
            break;
          case 'text':
            if (checkin.supplemental === null) {
              text = 'No Input';
            }
            if (checkin.supplemental !== null) {
              if (checkin.supplemental.is_accurate === true) {
                text = 'Accurate';
              }
              if (checkin.supplemental.is_accurate === false) {
                text = 'Not Accurate';
              }
            }
            break;
          default:
            video = 'No Input';
            audio = 'No Input';
            text = 'No Input';
            break;
        }
      });
      rows.push(
        createData(
          new Date(checkinSession[0].created).toLocaleString(),
          audio,
          video,
          text
        )
      );
    });
    return rows;
  };
  return (
    <MaterialTable
      isLoading={isLoading}
      components={{ Container: Box }}
      icons={tableIcons}
      columns={[
        {
          title: 'Checkin Date',
          field: 'checkin_date',
        },
        {
          title: 'Audio',
          field: 'audio',
        },
        {
          title: 'Video',
          field: 'video',
        },
        {
          title: 'Text',
          field: 'text',
        },
      ]}
      data={sessions}
      options={{
        showTitle: false,
        exportButton: true,
        exportAllData: true,
        exportFileName: 'You V App',
        search: false,
        emptyRowsWhenPaging: false,
      }}
    />
  );
}
