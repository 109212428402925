import { Box, Typography } from '@material-ui/core';
import React from 'react';

import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import SelectionSearch from '../../containers/SelectionSearch';
import Meeting from './meeting';
import { getMeetingRecordingAPI } from './meetingAPI';

export default function ZoomRecording(props: { meetings: Meeting[] }) {
  const [selected, setSelected] = React.useState<Meeting | null>(null);
  const [meetingRecording, setMeetingRecording] = React.useState<string | null>(
    null
  );

  //return all the meetings
  const meetings = React.useMemo(() => {
    return [...props.meetings];
  }, [props.meetings]);

  //sets meeting selected as selected
  const handleChange = React.useCallback(
    (meeting: Meeting | null | string) => {
      if (typeof meeting !== 'string') {
        if (meeting === null) {
          setSelected(null);
        } else {
          setSelected(meeting);
        }
      }
    },
    [setSelected]
  );
  //end Select a meeting

  React.useEffect(() => {
    if (selected) {
      getMeetingRecordingAPI(selected.id).then((recording: string) =>
        setMeetingRecording(recording)
      );
    }
  }, [selected]);
  // if there is no recording then it should display no recording
  const output = () => {
    if (meetingRecording) {
      if (meetingRecording === 'No Recording') {
        return 'No Recording';
      } else {
        return (
          <audio src={meetingRecording} controls style={{ width: '100%' }} />
        );
      }
    }
  };

  return (
    <React.Fragment>
      <Section variant="outlined">
        <Typography variant="body2" paragraph></Typography>
        <SectionHeading heading="Meeting Recordings" />
        {meetings.length > 0 ? (
          <Box width="100%" my={2} key={meetings[0].id}>
            <SelectionSearch
              label="Search Meeting"
              options={meetings}
              getOptionLabel={option =>
                `${option.details.topic}, Date: ${new Date(
                  option.details.start_time
                ).toLocaleString()}`
              }
              onSelect={handleChange}
            />
          </Box>
        ) : null}

        {selected ? (
          <Section variant="outlined">
            {meetingRecording ? output : null}
          </Section>
        ) : null}
      </Section>
    </React.Fragment>
  );
}
