import { Canceler } from 'axios';

import { axios, CancelToken } from '../../config/axiosConfig';
import { TrustedPerson } from './trustedPerson';
import User from '../user/user';

export async function getTrustedPeopleListAPI(
  params: Partial<TrustedPerson>,
  executor?: (canceler: Canceler) => void
) {
  try {
    const response = await axios.get<{ trusted_people: Array<TrustedPerson> }>(
      '/api/v1/trusted_people',
      { params, cancelToken: executor ? new CancelToken(executor) : undefined }
    );
    return response.data.trusted_people;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

/**
 * 
 * @param uuid User uuid
 */
export async function getUserTrustedPeopleListAPI(uuid: string) {
  try {
    const response = await axios.get<{ trusted_people: Array<TrustedPerson>, user: User }>(
      `/api/v1/user/${uuid}/trusted_people`,
      // { params, cancelToken: executor ? new CancelToken(executor) : undefined }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}