import { Typography } from '@material-ui/core';
import React from 'react';

import useInterestGroupsSlice from '../interest_group/useInterestGroupsSlice';
import User from './user';

export default function UserInterestGroupsList(props: { user: User }) {
  const { user } = props;

  const interestGroupsSlice = useInterestGroupsSlice(user);
  const { data: groups } = interestGroupsSlice.state;

  return (
    <React.Fragment>
      {groups.map((group) => (
        <Typography key={group.id} variant="body2" paragraph>
          {group.name}
        </Typography>
      ))}
    </React.Fragment>
  );
}
