import { Box, Button, TextField } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';

import { UseCohortsSlice } from './useCohortsSlice';

type CohortFormData = {
  name: string;
};

export default function CohortForm(props: { cohortsSlice: UseCohortsSlice }) {
  const { cohortsSlice } = props;
  const actions = cohortsSlice.actions;

  const { register, handleSubmit, errors, setValue } = useForm<CohortFormData>();

  const onSubmit = handleSubmit(async (formData) => {
    try {
      await actions.createCohort(formData.name);
      setValue('name', '')
    } catch (reason) {
      console.error(reason);
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <Box width="100%" my={2}>
        <TextField
          fullWidth
          label="Cohort Name"
          variant="filled"
          name="name"
          inputRef={register({ required: 'Required' })}
          helperText={errors.name?.message}
          error={Boolean(errors.name)}
        />
      </Box>
      <Box display="flex" width="100%">
        <Box flexGrow={1} />
        <Button
          variant="contained"
          disableElevation
          color="primary"
          type="submit"
        >
          Create
        </Button>
      </Box>
    </form>
  );
}
