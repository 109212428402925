import { Box, Divider } from '@material-ui/core';
import MaterialTable from 'material-table';
import React from 'react';

import SectionHeading from '../../components/SectionHeading';
import tableIcons from '../../config/tableIcons';
import useSafetyChecksSlice from './useSafetyChecksSlice';
import RefreshIcon from '@material-ui/icons/Refresh';

export default function DailySafetyCheckTable(props: {
  user_uuid?: string;
  cohort_id?: number;
}) {
  const safetyCheckInsSlice = useSafetyChecksSlice(props);
  const { data: safetyChecks, isLoading } = safetyCheckInsSlice.state;
  const actions = safetyCheckInsSlice.actions;

  return (
    <MaterialTable
      isLoading={isLoading}
      title={<SectionHeading heading="Daily Safety Check" />}
      components={{ Container: Box }}
      icons={tableIcons}
      columns={[
        {
          title: 'Date',
          field: 'modified',
          type: 'date',
          render: rowData => new Date(rowData.modified).toLocaleDateString(),
          defaultSort: 'asc',
        },
        {
          title: 'Self Harm',
          field: 'self_harm',
          type: 'numeric'
          // sorting: false,
          // render: safetyCheck => {
          //   if (safetyCheck.self_harm_text === '') {
          //     return 'No Input';
          //   }
          //   return (
          //     <Box display="flex" flexDirection="column" height="100%">
          //       <Box mb={1}>
          //         <Box>
          //           <b>Text:</b>
          //         </Box>
          //         <Box>{safetyCheck.self_harm_text}</Box>
          //       </Box>
          //       <Divider />
          //       <Box mt={1}>
          //         <b>Count: </b>
          //         {safetyCheck.self_harm}
          //       </Box>
          //     </Box>
          //   );
          // },
        },
        {
          title: 'Other Harm',
          field: 'other_harm',
          type: 'numeric'
          // sorting: false,
          // render: safetyCheck => {
          //   if (safetyCheck.other_harm_text === '') {
          //     return 'No Input';
          //   }
          //   return (
          //     <Box display="flex" flexDirection="column" height="100%">
          //       <Box mb={1}>
          //         <Box>
          //           <b>Text:</b>
          //         </Box>
          //         <Box>{safetyCheck.other_harm_text}</Box>
          //       </Box>
          //       <Divider />
          //       <Box mt={1}>
          //         <b>Count: </b>
          //         {safetyCheck.other_harm}
          //       </Box>
          //     </Box>
          //   );
          // },
        },
        {
          title: 'Substance Use',
          field: 'substance_use',
          type: 'numeric'
          // sorting: false,
          // render: safetyCheck => {
          //   if (safetyCheck.substance_use_text === '') {
          //     return 'No Input';
          //   }
          //   return (
          //     <Box display="flex" flexDirection="column" height="100%">
          //       <Box mb={1}>
          //         <Box>
          //           <b>Text:</b>
          //         </Box>
          //         <Box>{safetyCheck.substance_use_text}</Box>
          //       </Box>
          //       <Divider />
          //       <Box mt={1}>
          //         <b>Count: </b>
          //         {safetyCheck.substance_use}
          //       </Box>
          //     </Box>
          //   );
          // },
        },
        {
          title: 'Unsafe Bahavior',
          field: 'unsafe_behavior',
          type: 'numeric'
          // sorting: false,
          // render: safetyCheck => {
          //   if (safetyCheck.unsafe_behavior_text === '') {
          //     return 'No Input';
          //   }
          //   return (
          //     <Box display="flex" flexDirection="column" height="100%">
          //       <Box mb={1}>
          //         <Box>
          //           <b>Text:</b>
          //         </Box>
          //         <Box>{safetyCheck.unsafe_behavior_text}</Box>
          //       </Box>
          //       <Divider />
          //       <Box mt={1}>
          //         <b>Count: </b>
          //         {safetyCheck.unsafe_behavior}
          //       </Box>
          //     </Box>
          //   );
          // },
        },
      ]}
      data={safetyChecks.map(sc => ({
        ...sc,
        created: new Date(sc.created),
        modified: new Date(sc.modified),
      }))}
      actions={[
        {
          icon: RefreshIcon,
          tooltip: 'Refresh Data',
          isFreeAction: true,
          onClick: () => actions.refresh(),
        },
      ]}
      options={{
        search: false,
        exportButton: true,
      }}
    />
  );
}
