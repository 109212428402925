import { Box, Button, TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useAppDispatch } from '../../config/storeConfig';
import Topic from './topic';
import { topicActions } from './topicSlice';

interface TopicFormData {
  name: string;
  handout_url: string;
  quiz_url: string;
}

export default function TopicForm(props: {
  topic?: Topic;
  onSuccess?: (topic: Topic) => void;
  onReject?: () => void;
}) {
  const { topic, onSuccess, /* onReject */ } = props;
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, errors, reset, control } = useForm<TopicFormData>();

  const onSubmit = handleSubmit((formData) => {
    if (topic) {
      dispatch(
        topicActions.updateTopic({
          id: topic.id,
          name: formData.name,
          handout_url: formData.handout_url,
          quiz_url: formData.quiz_url,
        })
      ).then((result) => {
        if (typeof result.payload === 'string') {
          enqueueSnackbar(result.payload, { variant: 'error' });
        } else if (typeof result.payload !== 'undefined') {
          enqueueSnackbar(`Topic ${formData.name} UPDATED`, {
            variant: 'success',
          });
          if (onSuccess) onSuccess(result.payload);
        }
      });
    } else {
      dispatch(
        topicActions.createTopic({
          name: formData.name,
          handout_url: formData.handout_url,
          quiz_url: formData.quiz_url,
        })
      ).then((result) => {
        if (typeof result.payload === 'string') {
          enqueueSnackbar(result.payload, { variant: 'error' });
        } else {
          enqueueSnackbar(`Topic ${formData.name} CREATED`, {
            variant: 'success',
          });
        }
      });
    }
  });

  React.useEffect(() => {
    reset({
      name: topic?.name,
      handout_url: topic?.handout_url ?? '',
      quiz_url: topic?.quiz_url ?? '',
    });
  }, [topic, reset]);

  return (
    <form onSubmit={onSubmit}>
      <Controller
        as={
          <TextField
            fullWidth
            label="Topic Name"
            variant="filled"
            helperText={errors.name?.message}
            error={Boolean(errors.name)}
            margin="normal"
          />
        }
        name="name"
        control={control}
        defaultValue=""
      />
      {topic && (
        <React.Fragment>
          <Controller
            as={
              <TextField
                fullWidth
                label="Handout URL"
                variant="filled"
                helperText={errors.handout_url?.message}
                error={Boolean(errors.handout_url)}
                margin="normal"
              />
            }
            name="handout_url"
            control={control}
            defaultValue=""
          />
          {/* <Controller
            as={
              <TextField
                fullWidth
                label="FeedBack URL"
                variant="filled"
                helperText={errors.quiz_url?.message}
                error={Boolean(errors.quiz_url)}
                margin="normal"
              />
            }
            name="quiz_url"
            control={control}
            defaultValue=""
          /> */}
        </React.Fragment>
      )}

      <Box width="100%" my={1} display="flex" justifyContent="flex-end">
        <Button type="submit" color="primary" variant="contained" disableElevation>
          {topic ? 'Update' : 'Create'}
        </Button>
      </Box>
    </form>
  );
}
