import { Box, Button, TextField } from '@material-ui/core';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import User from './user';

export type UpdateCopingCoachFormData = {
  shortAbout: string;
  longAbout: string;
  email: string;
  phone: string;
  preferEmail: boolean;
  preferPhone: boolean;
};

interface UpdateCopingCoachFromProps {
  copingCoach: User;
  onSubmit?: (formData: UpdateCopingCoachFormData) => void;
}

export default function UpdateCopingCoachForm(
  props: UpdateCopingCoachFromProps
) {
  const { copingCoach, onSubmit } = props;
  const { register, handleSubmit, errors, control, reset } = useForm<
    UpdateCopingCoachFormData
  >();

  const _onSubmit = handleSubmit(formData => {
    if (onSubmit) onSubmit(formData);
  });

  React.useEffect(() => {
    reset({
      shortAbout: copingCoach.settings.about_me_short ?? '',
      longAbout: copingCoach.settings.about_me_long ?? '',
      email: copingCoach.email,
      phone: copingCoach.cell_phone ?? '',
    });
  }, [copingCoach, reset]);

  return (
    <form onSubmit={_onSubmit}>
      <Controller
        as={
          <TextField
            fullWidth
            label="Short Bio"
            variant="filled"
            margin="normal"
            // defaultValue={user?.study_initials}
            helperText={errors.shortAbout?.message}
            error={Boolean(errors.shortAbout)}
          />
        }
        name="shortAbout"
        control={control}
        defaultValue=""
      />
      <Controller
        as={
          <TextField
            fullWidth
            label="Long Bio"
            variant="filled"
            margin="normal"
            helperText={errors.longAbout?.message}
            error={Boolean(errors.longAbout)}
          />
        }
        name="longAbout"
        control={control}
        defaultValue=""
      />
      <Controller
        as={
          <TextField
            fullWidth
            label="Email"
            variant="filled"
            margin="normal"
            // defaultValue={user?.email}
            inputRef={register({})}
            helperText={errors.email?.message}
            error={Boolean(errors.email)}
          />
        }
        name="email"
        rules={{
          pattern: {
            value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
            message: 'Invalid email format.',
          },
        }}
        control={control}
        defaultValue=""
      />

      <Controller
        as={
          <TextField
            fullWidth
            label="Mobile Number"
            variant="filled"
            margin="normal"
            helperText={errors.phone?.message}
            error={Boolean(errors.phone)}
          />
        }
        // rules={{
        //   pattern: {
        //     value: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
        //     message: 'Invalid format.',
        //   },
        // }}
        name="phone"
        control={control}
        defaultValue=""
      />

      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disableElevation
        >
          Update
        </Button>
      </Box>
    </form>
  );
}
