import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import ContentContainer from '../../components/ContentContainer';
import RouteTitle from '../../components/RouteTitle';
import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import { selectAuthUser } from '../auth/authSlice';
import useCohortsSlice from '../cohort/useCohortsSlice';
import CreateUserForm from './CreateUserForm';
import EditUser from './EditUser';
import useUsersSlice from './useUsersSlice';
import { AppContext } from '../../AppContext';
import DataDumpForm from '../data_dump/DataDumpForm';
import NotifcationTable from '../notifications/notification';
import { Grid } from '@material-ui/core';

export default function UserAdmin() {
  // const [selectedApp, setSelectedApp] = React.useState('');
  const { app } = React.useContext(AppContext);

  const authUser = useSelector(selectAuthUser);

  const usersSlice = useUsersSlice({}, app === 'sa');
  const cohortsSlice = useCohortsSlice();
  const {
    data: cohorts /* error: cohortError, isLoading: isLoadingCohorts */,
  } = cohortsSlice.state;

  const hasAccess = authUser?.roles[0] !== 'leader';

  if (!hasAccess) {
    return <Redirect to="/forbidden" />;
  }

  return (
    <ContentContainer maxWidth="xl">
      <RouteTitle title="User Administration" />
      <Section variant="outlined">
        <SectionHeading heading="Create User" />
        <CreateUserForm cohorts={cohorts} usersSlice={usersSlice} app={app!} />
      </Section>
      <EditUser cohorts={cohorts} usersSlice={usersSlice} app={app!} />
      <Grid container spacing={2} direction="row-reverse">
        <Grid item xs={12} lg={6}>
          <DataDumpForm users={usersSlice.state.data} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <NotifcationTable
            users={usersSlice.state.data}
            cohorts={cohorts}
            // app={app}
          />
        </Grid>
      </Grid>
    </ContentContainer>
  );
}

UserAdmin.displayName = 'UserAdmin';
