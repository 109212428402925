import { Box } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { mdiFlagRemoveOutline } from '@mdi/js';
import Icon from '@mdi/react';
import MaterialTable, { MaterialTableProps } from 'material-table';
import { useSnackbar } from 'notistack';
import React from 'react';

import tableIcons from '../config/tableIcons';
import CollapsableSection from './CollapsableSection';


export default function ModerationTable(
  props: Pick<
    MaterialTableProps<{
      created: string;
      message: string;
      count_flags: number;
      identifier: number | string;
    }>,
    'title' | 'data' | 'tableRef'
  > & {
    deleteAction: (identifier: number | string) => void;
    initCollapse?: boolean;
  }
) {
  const {
    title,
    deleteAction,
    initCollapse,
    // updateAction,
    // refreshAction,
    data,
    // isLoading,
  } = props;

  const {enqueueSnackbar} = useSnackbar();

  const tableRef = React.useRef<
    MaterialTable<{
      created: string;
      message: string;
      count_flags: number;
      identifier: number | string;
    }>
  >();

  const refresh = () => {
    tableRef.current && (tableRef.current as any).onQueryChange();
  };

  return (
    <CollapsableSection
      initiallyCollapsed={initCollapse}
      heading={title}
      customActions={[
        {
          icon: <RefreshIcon />,
          execute: refresh,
          title: 'Refresh',
        },
      ]}
    >
      <MaterialTable
        tableRef={tableRef}
        components={{
          Container: Box,
        }}
        icons={{
          ...tableIcons,
          Edit: React.forwardRef((props, ref) => (
            <Icon
              {...props}
              ref={ref as React.MutableRefObject<SVGSVGElement>}
              path={mdiFlagRemoveOutline}
              title="Unflag"
              size={1}
            />
          )),
        }}
        title={title}
        columns={[
          {
            title: 'Created On',
            field: 'created',
            type: 'datetime',
            render: rowData => new Date(rowData.created).toLocaleString(),
            editable: 'never',
          },
          { title: 'Flagged Message', field: 'message', editable: 'never' },
          {
            title: '# of Times Flagged',
            field: 'count_flags',
            type: 'numeric',
            editable: 'never',
          },
        ]}
        data={data}
        editable={{
          // onRowUpdate: async (newData, oldData) => {
          //   try {
          //     if (updateAction) updateAction(newData.identifier);
          //   } catch (error) {
          //     console.error(error);
          //   }
          // },
          onRowDelete: async oldData => {
            try {
              await deleteAction(oldData.identifier);
              enqueueSnackbar('Item DELETED', {variant: 'success'})
              refresh();
            } catch (error) {
              console.error(error);
              enqueueSnackbar(error, {variant: 'error'})
            }
          },
        }}
        options={{ search: false, pageSize: 10, toolbar: false }}
      />
    </CollapsableSection>
  );
}
