import { Box, Typography } from '@material-ui/core';
import React from 'react';

export default function PageNotFound() {
  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
    >
      <img src="/logo.png" alt="logo" width={160} height={160} />
      <Box m={1}>
        <Typography align="center" variant="h2" paragraph>
          Error 404
        </Typography>
        <Typography align="center" variant="h5" paragraph>
          Page Not Found
        </Typography>
      </Box>
    </Box>
  );
}
