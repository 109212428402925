import { axios, CancelToken } from '../../config/axiosConfig';
import InterestGroup from './interestGroup';
import User from '../user/user';
import { AxiosResponse, Canceler } from 'axios';

export async function createInterestGroupAPI(args: {
  name: string;
  description: string;
}) {
  try {
    const data = new FormData();
    data.append('name', args.name);
    data.append('description', args.description);
    const response = await axios.post<{ interest_group: InterestGroup }>(
      '/api/v1/interest_group/create',
      data,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    return response.data.interest_group;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function updateInterestGroupAPI(args: {
  id: number;
  name: string;
  description: string;
}) {
  try {
    const { id, description, name } = args;
    const data = new FormData();
    data.append('name', name);
    data.append('description', description);
    const response = await axios.put<{ interest_group: InterestGroup }>(
      `/api/v1/interest_group/${id}`,
      data,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    return response.data.interest_group;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function deleteInterestGroupAPI(id: number) {
  try {
    const response = await axios.delete<{ interest_group: InterestGroup }>(
      `/api/v1/interest_group/${id}/delete`
    );
    return response.data.interest_group;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function restoreInterestGroupAPI(id: number) {
  try {
    const response = await axios.put<{ interest_group: InterestGroup }>(
      `/api/v1/interest_group/${id}/restore`
    );
    return response.data.interest_group;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getInterestGroupAPI(id: number) {
  try {
    const response = await axios.get<{ interest_group: InterestGroup }>(
      `/api/v1/interest_group/${id}`
    );
    return response.data.interest_group;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getInterestGroupsListAPI() {
  try {
    const response = await axios.get<{ interest_groups: Array<InterestGroup> }>(
      '/api/v1/interest_groups'
    );
    return response.data.interest_groups;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

/**
 * Without a request body, returns a user and a list of their interest_groups. See PUT user_interest_groups docs.
 * Development note: If a user's setting.hide_interest_groups:true, only they can make this request...for now until roles.
 * @param groups The interest groups to add user to.
 */
export async function userInterestGroupsListAPI(
  uuid: string,
  groups?: Array<InterestGroup>,
  executor?: (canceler: Canceler) => void
) {
  try {
    const response: AxiosResponse<{
      interest_groups: Array<InterestGroup>;
      user: User;
    }> = await axios({
      url: `/api/v1/user/${uuid}/interest_groups`,
      method: groups ? 'PUT' : 'GET',
      data: groups ? { interest_groups: groups } : undefined,
      headers: groups ? { 'Content-Type': 'application/json' } : undefined,
      cancelToken: executor ? new CancelToken(executor) : undefined,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
