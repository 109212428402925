import React from 'react';

import ModerationTable from '../../components/ModerationTable';
import SectionHeading from '../../components/SectionHeading';
import {
  getSocialFeedAPI,
  hideMySafePlacePostAPI,
  SocialFeedQuery,
} from './mySafePlaceAPI';

export default function SocialFeedModerationTable() {
  return (
    <ModerationTable
      title={<SectionHeading heading="Social Feed" />}
      data={async tableQuery => {
        const query: SocialFeedQuery = {
          is_flagged: true,
          order_by: 'count_flags',
          is_hidden: false,
          page: tableQuery.page + 1,
          per_page: tableQuery.pageSize,
        };
        const response = await getSocialFeedAPI(query);
        const data = response.msp_posts.map(post => ({
          created: post.created,
          message:
            typeof post.resource_data === 'string' && post.resource_data
              ? post.resource_data
              : '',
          count_flags: post.count_flags,
          identifier: post.id,
        }));
        return {
          data,
          page: response.page - 1,
          totalCount: response.msp_posts.length,
        };
      }}
      deleteAction={identifier => {
        if (typeof identifier === 'number') hideMySafePlacePostAPI(identifier);
      }}
    />
  );
}
