import React from 'react';

import ModerationTable from '../../components/ModerationTable';
import SectionHeading from '../../components/SectionHeading';
import {
  getMySafePlacePostCommentsListByAdminAPI,
  hideMySafePlacePostCommentAPI,
  MySafePlacePostCommentsListByAdminQuery,
} from './mySafePlaceAPI';

export default function SocialFeedCommentsModerationTable() {
  return (
    <ModerationTable
      title={<SectionHeading heading="Social Feed Comments" />}
      data={async tableQuery => {
        const query: MySafePlacePostCommentsListByAdminQuery = {
          is_flagged: true,
          order_by: 'count_flags',
          is_hidden: false,
          page: tableQuery.page + 1,
          per_page: tableQuery.pageSize,
        };
        const response = await getMySafePlacePostCommentsListByAdminAPI(query);
        const data = response.comments.map(comment => ({
          created: comment.created,
          message: comment.comment,
          count_flags: comment.count_flags,
          identifier: comment.id,
        }));
        return {
          data,
          page: response.page - 1,
          totalCount: response.comments.length,
        };
      }}
      deleteAction={identifier => {
        if (typeof identifier === 'number') hideMySafePlacePostCommentAPI(identifier);
      }}
    />
  );
}
