import { useSnackbar } from 'notistack';
import React from 'react';

import User from '../user/user';
import {
  getUserTrustedPeopleListAPI,
  getTrustedPeopleListAPI,
} from './trustedPeopleAPI';
import { TrustedPerson } from './trustedPerson';
import { useSelector } from 'react-redux';
import { selectAuthUser } from '../auth/authSlice';

interface TrustedPeopleState {
  isLoading: boolean;
  error: Error | null;
  data: TrustedPerson[];
}

type TrustedPeopleAction =
  | {
      type: 'request/getTrustedPeople';
    }
  | { type: 'success/getTrustedPeople'; payload: TrustedPerson[] }
  | { type: 'reject/getTrustedPeople'; payload: Error };

function reducer(
  state: TrustedPeopleState,
  action: TrustedPeopleAction
): TrustedPeopleState {
  switch (action.type) {
    case 'request/getTrustedPeople':
      return { ...state, isLoading: true };
    case 'success/getTrustedPeople':
      return { ...state, isLoading: false, data: action.payload };
    case 'reject/getTrustedPeople':
      return { ...state, isLoading: false, error: action.payload };
    default:
      throw new Error();
  }
}

//TODO: create and export some actions
export default function useTrustedPeopleSlice(user: User) {
  const authUser = useSelector(selectAuthUser);
  const [state, dispatch] = React.useReducer(reducer, {
    isLoading: false,
    error: null,
    data: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  const refresh = React.useCallback(async () => {
    try {
      dispatch({ type: 'request/getTrustedPeople' });
      let trustedPeople: TrustedPerson[] = [];
      if (authUser?.roles[0] === 'leader') {
        trustedPeople = await getTrustedPeopleListAPI({ user_uuid: user.uuid });
      } else {
        trustedPeople = (await getUserTrustedPeopleListAPI(user.uuid))
          .trusted_people;
      }
      dispatch({ type: 'success/getTrustedPeople', payload: trustedPeople });
    } catch (reason) {
      const error = new Error(reason);
      dispatch({ type: 'reject/getTrustedPeople', payload: error });
      enqueueSnackbar(reason, { variant: 'error' });
      throw error;
    }
  }, [user, dispatch, enqueueSnackbar, authUser]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return { state, actions: { refresh } };
}
