import { HelpCope } from './helpCope';
import { axios } from '../../config/axiosConfig';

export async function getHelpCopeListAPI(filter: {
  cohort_id?: number;
  user_uuid?: string;
  is_deleted?: boolean;
}) {
  try {
    const response = await axios.get<{
      help_copes: HelpCope[];
    }>('/api/v1/help_copes', {
      params: filter,
    });
    return response.data.help_copes;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
