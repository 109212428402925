import { Box } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import { AppContext } from '../../AppContext';
import ContentContainer from '../../components/ContentContainer';
import RouteTitle from '../../components/RouteTitle';
import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import SelectionSearch from '../../containers/SelectionSearch';
import { selectAuthUser } from '../auth/authSlice';
import SSUserSettings from './seeking_safety/SSUserSettings';
import SAUserSettings from './self_aware/SAUserSettings';
import User from './user';
import UserDetails from './UserDetails';
import useUsersSlice from './useUsersSlice';

// const csvFields = [
//   // {
//   //   label: 'Long About Me',
//   //   value: 'about_me_long',
//   // },
//   // {
//   //   label: 'Short About Me',
//   //   value: 'about_me_short',
//   // },
//   {
//     label: 'First Name',
//     value: 'first_name',
//   },
//   {
//     label: 'Last Name',
//     value: 'last_name',
//   },
//   {
//     label: 'Public Name',
//     value: 'settings.display_name',
//   },
//   {
//     label: 'Onboarded',
//     value: 'settings.is_onboarded',
//   },
//   {
//     label: 'Tracker/Behavioral Addiction',
//     value: 'settings.trackers.behavioral_addictions',
//   },
//   {
//     label: 'Tracker/Emotional Goals',
//     value: 'settings.trackers.emotional_goals',
//   },
//   {
//     label: 'Tracker/Mental Disorders',
//     value: 'settings.trackers.mental_disorders',
//   },
//   {
//     label: 'Tracker/Self Care Topics',
//     value: 'settings.trackers.self_care_topics',
//   },
//   {
//     label: 'Tracker/Substance Addictions',
//     value: 'settings.trackers.substance_addictions',
//   },
// ];

export default function Profiles() {
  const [selectedUser, setSelectedUser] = React.useState<User | null>(null);
  const [selectedApp, setSelectedApp] = React.useState('');

  const authUser = useSelector(selectAuthUser);

  const { app } = React.useContext(AppContext);

  const isLeader = authUser?.roles[0] === 'leader';

  const usersSlice = useUsersSlice(
    isLeader ? { cohort_id: authUser?.cohort?.id } : {},
    app === 'sa'
  );
  const { data: users } = usersSlice.state;
  // const action = usersSlice.actions;

  const handleSelection = (user: string | User | null) => {
    if (typeof user !== 'string') setSelectedUser(user);
  };

  // const handleCSVExport = async () => {
  //   if (selectedUser) {
  //     console.log(selectedUser);
  //     const opts = { fields: csvFields };
  //     try {
  //       let csv = await parseAsync(selectedUser, opts);
  //       csv = 'data:text/csv;charset=utf-8,' + csv;
  //       const encodedURI = encodeURI(csv);
  //       const link = document.createElement('a');
  //       link.setAttribute('href', encodedURI);
  //       link.setAttribute(
  //         'download',
  //         `${selectedUser.settings.display_name}.csv`
  //       );
  //       document.body.appendChild(link); // Required for FF

  //       link.click(); // This will download the data file named "my_data.csv".
  //       // console.log(csv);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  // };

  React.useEffect(() => {
    //whenever user switches app, clear out the selected user.
    setSelectedUser(null);
    setSelectedApp(app!);
  }, [app, setSelectedUser, setSelectedApp]);

  return (
    <ContentContainer maxWidth="xl">
      <RouteTitle title="User Profile" />
      <Section variant="outlined">
        {users && <SectionHeading heading="Select a User Profile" />}
        <Box width="100%" my={2}>
          {users && (
            <SelectionSearch
              label="Select a User"
              options={users}
              getOptionLabel={option =>
                `<ID: [${option.study_id}]>  <Initials: [${
                  option.study_initials
                }]>  [${
                  !Boolean(option.settings.display_name)
                    ? 'Anonymous'
                    : option.settings.display_name
                }]`
              }
              onSelect={handleSelection}
            />
          )}
          {selectedUser && (
            <UserDetails user={selectedUser} app={selectedApp} />
          )}
        </Box>
        {selectedUser &&
          (selectedApp === 'ss' ? (
            <SSUserSettings user={selectedUser} />
          ) : (
            <SAUserSettings user={selectedUser} />
          ))}
      </Section>
    </ContentContainer>
  );
}
