import { axios } from '../../config/axiosConfig';
import { SafetyPlan } from './safetyPlan';
import { SafetyPlanResponse } from './safetyPlanResponse';

export async function getSafetyPlansListAPI(params?: { user_uuid?: string }) {
  try {
    const response = await axios.get<{ safety_plans: SafetyPlan[] }>(
      '/api/v1/safety_plans',
      { params }
    );
    return response.data.safety_plans;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getSafetyPlanResponsesListAPI(params?: {
  user_uuid?: string;
  start?: string;
  end?: string;
}) {
  try {
    const response = await axios.get<{
      safety_plan_responses: SafetyPlanResponse[];
    }>(`/api/v1/safety_plan_responses`, { params });
    return response.data.safety_plan_responses;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
