import { useSnackbar } from 'notistack';
import React from 'react';

import User from '../user/user';
import InterestGroup from './interestGroup';
import {
  createInterestGroupAPI,
  deleteInterestGroupAPI,
  getInterestGroupsListAPI,
  updateInterestGroupAPI,
  userInterestGroupsListAPI,
} from './interestGroupAPI';

interface InterestGroupsState {
  isLoading: boolean;
  error: Error | null;
  data: InterestGroup[];
}

export default function useInterestGroupsSlice(user?: User) {
  const [state, setState] = React.useState<InterestGroupsState>({
    isLoading: false,
    error: null,
    data: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  const createInterestGroup = React.useCallback(
    async (args: { name: string; description: string }) => {
      try {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const newGroup = await createInterestGroupAPI(args);
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          data: [...prevState.data, newGroup],
        }));
        enqueueSnackbar(`Interest Group CREATED`, { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          error: error,
        }));
        enqueueSnackbar(reason, { variant: 'error' });
        throw error;
      }
    },
    [setState, enqueueSnackbar]
  );

  const updateInterestGroup = React.useCallback(
    async (args: { id: number; name: string; description: string }) => {
      try {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const updatedGroup = await updateInterestGroupAPI(args);
        setState((prevState) => {
          const index = prevState.data.findIndex(
            (group) => group.id === updatedGroup.id
          );
          if (index > -1) {
            return {
              ...prevState,
              isLoading: false,
              data: [
                ...prevState.data.slice(0, index),
                updatedGroup,
                ...prevState.data.slice(index + 1),
              ],
            };
          }
          return { ...prevState, isLoading: false };
        });
        enqueueSnackbar(`Interest Group UPDATED`, { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          error: error,
        }));
        enqueueSnackbar(reason, { variant: 'error' });
        throw error;
      }
    },
    [setState, enqueueSnackbar]
  );

  const deleteInterestGroup = React.useCallback(
    async (id: number) => {
      try {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const deletedGroup = await deleteInterestGroupAPI(id);
        setState((prevState) => {
          const index = prevState.data.findIndex(
            (group) => group.id === deletedGroup.id
          );
          if (index) {
            return {
              ...prevState,
              isLoading: false,
              data: [
                ...prevState.data.slice(0, index),
                ...prevState.data.slice(index + 1),
              ],
            };
          }
          return { ...prevState, isLoading: false };
        });
        enqueueSnackbar(`Interest Group DELETED`, { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          error: error,
        }));
        enqueueSnackbar(reason, { variant: 'error' });
        throw error;
      }
    },
    [setState, enqueueSnackbar]
  );

  const addUserToInterestGroup = React.useCallback(
    async (user: User, groups: InterestGroup[]) => {
      try {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        await userInterestGroupsListAPI(user.uuid, groups);
        setState((prevState) => ({ ...prevState, isLoading: false }));
        enqueueSnackbar(`User ${user.study_id} ADDED to ${groups[0].name}`);
      } catch (reason) {
        const error = new Error(reason);
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          error: error,
        }));
        enqueueSnackbar(reason, { variant: 'error' });
        throw error;
      }
    },
    [setState, enqueueSnackbar]
  );

  const refresh = React.useCallback(async () => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    if (user) {
      userInterestGroupsListAPI(user.uuid)
        .then(({ interest_groups }) =>
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            data: interest_groups,
          }))
        )
        .catch((reason) => {
          const error = new Error(reason);
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            error: error,
          }));
          enqueueSnackbar(reason, { variant: 'error' });
          throw error;
        });
    } else {
      getInterestGroupsListAPI()
        .then((groups) =>
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            data: groups,
          }))
        )
        .catch((reason) => {
          const error = new Error(reason);
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            error: error,
          }));
          enqueueSnackbar(reason, { variant: 'error' });
          throw error;
        });
    }
  }, [setState, enqueueSnackbar, user]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    state,
    actions: {
      createInterestGroup,
      updateInterestGroup,
      deleteInterestGroup,
      addUserToInterestGroup,
    },
  };
}

export type UseInterestGroupsSlice = ReturnType<typeof useInterestGroupsSlice>;
