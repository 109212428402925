import { useSnackbar } from 'notistack';
import React from 'react';

import { LearnCategory, LearnPost } from './learn';
import {
  createLearnCategoryAPI,
  createLearnPostAPI,
  CreateLearnPostArgs,
  deleteLearnCategoryAPI,
  deleteLearnPostAPI,
  getLearnCategoriesListAPI,
  getLearnPostsListAPI,
  reIndexLearnCategoryAPI,
  updateLearnCategoryAPI,
  updateLearnPostAPI,
} from './learnAPI';

interface LeanCategoriesState {
  isLoading: boolean;
  error: Error | null;
  data: LearnCategory[];
}
interface LeanPostsState {
  isLoading: boolean;
  error: Error | null;
  data: LearnPost[];
}

export default function useLearnSlice(
  postQuery?: {
    category_id?: number;
    subcategory?: string;
  },
  sa?: boolean,
  categoryQuery?: {
    order_by?: string;
    reverse?: boolean;
  }
) {
  const [categories, setCategories] = React.useState<LeanCategoriesState>({
    isLoading: false,
    error: null,
    data: [],
  });
  const [posts, setPosts] = React.useState<LeanPostsState>({
    isLoading: false,
    error: null,
    data: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  //#region Categories
  const refreshCategories = React.useCallback(async () => {
    if (categoryQuery === undefined) return;
    try {
      setCategories(prev => ({ ...prev, isLoading: true }));
      const cats = await getLearnCategoriesListAPI(sa, categoryQuery);
      setCategories(prev => ({ ...prev, isLoading: false, data: cats }));
    } catch (reason) {
      const error = new Error(reason);
      setCategories(prev => ({ ...prev, isLoading: false, error }));
      enqueueSnackbar(reason, { variant: 'error' });
    }
  }, [setCategories, sa, enqueueSnackbar, categoryQuery]);

  const createCategory = React.useCallback(
    async (name: string) => {
      try {
        setCategories(prev => ({ ...prev, isLoading: true }));
        const newCat = await createLearnCategoryAPI(name, sa);
        setCategories(prev => ({ ...prev, data: [...prev.data, newCat] }));
        enqueueSnackbar('Category CREATED', { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        setCategories(prev => ({ ...prev, error }));
        enqueueSnackbar(reason, { variant: 'error' });
      } finally {
        setCategories(prev => ({ ...prev, isLoading: false }));
      }
    },
    [setCategories, sa, enqueueSnackbar]
  );

  const updateCategory = React.useCallback(
    async (id: number, name: string) => {
      try {
        setCategories(prev => ({ ...prev, isLoading: true }));
        const updatedCat = await updateLearnCategoryAPI(id, name, sa);
        setCategories(prev => {
          const index = prev.data.findIndex(cat => cat.id === updatedCat.id);
          return index > -1
            ? {
                ...prev,
                data: [
                  ...prev.data.slice(0, index),
                  updatedCat,
                  ...prev.data.slice(index + 1),
                ],
              }
            : prev;
        });
        enqueueSnackbar('Category UPDATED', { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        setCategories(prev => ({ ...prev, error }));
        enqueueSnackbar(reason, { variant: 'error' });
      } finally {
        setCategories(prev => ({ ...prev, isLoading: false }));
      }
    },
    [setCategories, sa, enqueueSnackbar]
  );

  const reIndexCategory = React.useCallback(
    async (reIndex: { learn_category_ids: number[] }) => {
      try {
        setCategories(prev => ({ ...prev, isLoading: true }));
        const reIndexedCat = await reIndexLearnCategoryAPI(reIndex, sa);
        setCategories(prev => ({ ...prev, data: reIndexedCat }));
        // enqueueSnackbar('Categories Re-Prioritized', { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        setCategories(prev => ({ ...prev, error }));
        enqueueSnackbar(reason, { variant: 'error' });
      } finally {
        setCategories(prev => ({ ...prev, isLoading: false }));
      }
    },
    [setCategories, enqueueSnackbar, sa]
  );

  const deleteCategory = React.useCallback(
    async (id: number) => {
      try {
        setCategories(prev => ({ ...prev, isLoading: true }));
        const deletedCat = await deleteLearnCategoryAPI(id, sa);
        setCategories(prev => {
          const index = prev.data.findIndex(cat => cat.id === deletedCat.id);
          return index > -1
            ? {
                ...prev,
                data: [
                  ...prev.data.slice(0, index),
                  ...prev.data.slice(index + 1),
                ],
              }
            : prev;
        });
        enqueueSnackbar('Category DELETED', { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        setCategories(prev => ({ ...prev, error }));
        enqueueSnackbar(reason, { variant: 'error' });
      } finally {
        setCategories(prev => ({ ...prev, isLoading: false }));
      }
    },
    [setCategories, sa, enqueueSnackbar]
  );

  //#endregion

  //#region Posts
  const refreshPosts = React.useCallback(async () => {
    if (postQuery === undefined) return;
    try {
      setPosts(prev => ({ ...prev, isLoading: true }));
      const posts = await getLearnPostsListAPI(postQuery, sa);
      setPosts(prev => ({ ...prev, isLoading: false, data: posts }));
    } catch (reason) {
      const error = new Error(reason);
      setPosts(prev => ({ ...prev, isLoading: false, error }));
      enqueueSnackbar(reason, { variant: 'error' });
    }
  }, [setPosts, postQuery, sa, enqueueSnackbar]);

  const createPost = React.useCallback(
    async (args: CreateLearnPostArgs) => {
      try {
        setPosts(prev => ({ ...prev, isLoading: true }));
        const newPost = await createLearnPostAPI(args, sa);
        setPosts(prev => ({ ...prev, data: [...prev.data, newPost] }));
        enqueueSnackbar('Post CREATED', { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        setPosts(prev => ({ ...prev, error }));
        enqueueSnackbar(reason, { variant: 'error' });
      } finally {
        setPosts(prev => ({ ...prev, isLoading: false }));
      }
    },
    [setPosts, sa, enqueueSnackbar]
  );

  const updatePost = React.useCallback(
    async (id: number, args: Partial<CreateLearnPostArgs>) => {
      try {
        setPosts(prev => ({ ...prev, isLoading: true }));
        const updatedPost = await updateLearnPostAPI(id, args, sa);
        setPosts(prev => {
          const index = prev.data.findIndex(cat => cat.id === updatedPost.id);
          return index > -1
            ? {
                ...prev,
                data: [
                  ...prev.data.slice(0, index),
                  updatedPost,
                  ...prev.data.slice(index + 1),
                ],
              }
            : prev;
        });
        enqueueSnackbar('Post UPDATED', { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        setPosts(prev => ({ ...prev, error }));
        enqueueSnackbar(reason, { variant: 'error' });
      } finally {
        setPosts(prev => ({ ...prev, isLoading: false }));
      }
    },
    [setPosts, sa, enqueueSnackbar]
  );

  const deletePost = React.useCallback(
    async (id: number) => {
      try {
        setPosts(prev => ({ ...prev, isLoading: true }));
        const deletedPost = await deleteLearnPostAPI(id, sa);
        setPosts(prev => {
          const index = prev.data.findIndex(cat => cat.id === deletedPost.id);
          return index > -1
            ? {
                ...prev,
                data: [
                  ...prev.data.slice(0, index),
                  ...prev.data.slice(index + 1),
                ],
              }
            : prev;
        });
        enqueueSnackbar('Post DELETED', { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        setPosts(prev => ({ ...prev, error }));
        enqueueSnackbar(reason, { variant: 'error' });
      } finally {
        setPosts(prev => ({ ...prev, isLoading: false }));
      }
    },
    [setPosts, sa, enqueueSnackbar]
  );

  //#endregion

  React.useEffect(() => {
    refreshCategories();
  }, [refreshCategories]);

  return {
    categories,
    posts,
    actions: {
      refreshCategories,
      createCategory,
      updateCategory,
      deleteCategory,
      reIndexCategory,
      refreshPosts,
      createPost,
      updatePost,
      deletePost,
    },
  };
}

export type UseLearnSlice = ReturnType<typeof useLearnSlice>;
