import { axios } from '../../config/axiosConfig';
import Meeting from './meeting';

export interface CreateMeetingArgs {
  topic_id: number;
  cohort_id: number;
  start_time?: string;
  password?: string;
  duration?: number;
  feedback_url?: string;
  approval_type: number;
}

export async function createMeetingAPI(reqArgs: CreateMeetingArgs) {
  try {
    const response = await axios.post<{
      meeting: Meeting;
    }>('/api/v1/meeting/create', reqArgs, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data.meeting;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function updateMeetingAPI(id: number, reqArgs: CreateMeetingArgs) {
  try {
    const response = await axios.put<{
      meeting: Meeting;
    }>(`/api/v1/meeting/${id}`, reqArgs, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data.meeting;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function deleteMeetingAPI(id: string) {
  try {
    const response = await axios.delete<{
      meeting: Meeting;
    }>(`/api/v1/meeting/${id}/delete`);
    return response.data.meeting;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getMeetingsListAPI(filter?: {
  topic_id?: number;
  cohort_id?: number;
}) {
  try {
    const response = await axios.get<{
      meetings: Meeting[];
    }>('/api/v1/meetings', { params: filter });
    return response.data.meetings;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getMeetingHostKeyAPI() {
  try {
    const response = await axios.get<{
      host_key: string;
    }>('/api/v1/meeting/host_key');
    return response.data.host_key;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getMeetingRecordingAPI(meeting_id: number) {
  try {
    const response = await axios.get<{
      url: string;
    }>(`/api/v1/meeting/${meeting_id}/recording_url`);
    return response.data.url;
  } catch (error) {
    if (error.response) {
      return 'No Recording';
    }
    throw error.config;
  }
}
