import { useSnackbar } from 'notistack';
import React from 'react';

import User from './user';
import { UserSettings } from './userSettings';
import {
  getSAUserSettingsAPI,
  getSSUserSettingsAPI,
  updateSAUserSettingsAPI,
  updateSSUserSettingsAPI,
} from './userSettingsAPI';

interface UserSettingsState {
  isLoading: boolean;
  error: Error | null;
  data: UserSettings | null;
}

type UserSettingsAction =
  | { type: 'request/getUserSettings' }
  | { type: 'success/getUserSettings'; payload: UserSettings }
  | { type: 'reject/getUserSettings'; payload: Error }
  | { type: 'request/updateUserSettings' }
  | { type: 'success/updateUserSettings'; payload: UserSettings }
  | { type: 'reject/updateUserSettings'; payload: Error };

function reducer(
  state: UserSettingsState,
  action: UserSettingsAction
): UserSettingsState {
  switch (action.type) {
    case 'request/getUserSettings':
    case 'request/updateUserSettings':
      return { ...state, isLoading: true };
    case 'success/getUserSettings':
    case 'success/updateUserSettings':
      return { ...state, isLoading: false, data: action.payload };
    case 'reject/getUserSettings':
    case 'reject/updateUserSettings':
      return { ...state, isLoading: false, error: action.payload };
  }
}

export default function useUserSettingsSlice(user: User | null, sa: boolean) {
  const [state, dispatch] = React.useReducer(reducer, {
    isLoading: false,
    error: null,
    data: null,
  });

  const { enqueueSnackbar } = useSnackbar();

  /**
   *
   * @param data Updates the currently loaded user settings
   */
  const updateUserSettings = React.useCallback(
    async (data: Partial<UserSettings>) => {
      try {
        if (user) {
          dispatch({ type: 'request/updateUserSettings' });
          let update: User | null = null;
          if (sa === true) {
            update = await updateSAUserSettingsAPI(user.uuid, data);
          } else if (sa === false) {
            update = await updateSSUserSettingsAPI(user.uuid, data);
          }
          if (update) {
            dispatch({
              type: 'success/updateUserSettings',
              payload: update.settings,
            });
          }
          enqueueSnackbar(`User Settings UPDATED`, { variant: 'success' });
          return update;
        }
      } catch (reason) {
        dispatch({
          type: 'reject/updateUserSettings',
          payload: new Error(reason),
        });
      }
    },
    [dispatch, user, enqueueSnackbar, sa]
  );

  const refresh = React.useCallback(async () => {
    try {
      if (user) {
        dispatch({ type: 'request/getUserSettings' });
        let _user: User | null = null;
        if (sa === true) {
          _user = await getSAUserSettingsAPI(user.uuid);
        } else if (sa === false) {
          _user = await getSSUserSettingsAPI(user.uuid);
        }
        if (_user) {
          dispatch({
            type: 'success/getUserSettings',
            payload: _user.settings,
          });
        }
      }
    } catch (reason) {
      dispatch({
        type: 'reject/getUserSettings',
        payload: new Error(reason),
      });
      enqueueSnackbar(reason, { variant: 'error' });
    }
  }, [dispatch, user, enqueueSnackbar, sa]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return { state, actions: { updateUserSettings, refresh } };
}
