import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Typography,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import {
  AttachFile,
  Audiotrack,
  Description,
  PictureAsPdf,
  Theaters,
} from '@material-ui/icons';
import { DropzoneArea } from 'material-ui-dropzone';
import { useSnackbar } from 'notistack';
import React from 'react';

import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import TopicAnimation from '../animation/topicAnimation';
import useTopicAnimationsSlice from '../animation/useTopicAnimationsSlice';
import Topic from './topic';

const handlePreviewIcon = (fileObject: any, classes: any) => {
  const { type } = fileObject.file;
  const iconProps = {
    className: classes.image,
  };

  if (type.startsWith('video/'))
    return (
      <React.Fragment>
        <Theaters {...iconProps} />
        <Typography>{fileObject.file.name}</Typography>
      </React.Fragment>
    );
  if (type.startsWith('audio/')) return <Audiotrack {...iconProps} />;

  switch (type) {
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return <Description {...iconProps} />;
    case 'application/pdf':
      return <PictureAsPdf {...iconProps} />;
    default:
      return <AttachFile {...iconProps} />;
  }
};

const FileDropSnackTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Fade ref={ref} {...props} />;
});

export default function TopicAnimationManager(props: { topic: Topic }) {
  const { topic } = props;

  const [videoFiles, setVideoFiles] = React.useState<Array<File>>([]);

  const topicAnimationsSlice = useTopicAnimationsSlice(topic);
  const {
    data: topicAnimations,
    isLoading: vidUploading,
  } = topicAnimationsSlice.state;
  const actions = topicAnimationsSlice.actions;

  const { enqueueSnackbar } = useSnackbar();

  const handleFileChange = (files: Array<File>) => {
    setVideoFiles(files);
  };

  const handleAnimationUpload = async () => {
    if (videoFiles.length < 0) {
      enqueueSnackbar('Please slecte files to upload.', { variant: 'warning' });
    } else {
      if (topicAnimations.length > 0) {
        await actions.updateTopicAnimation(topicAnimations[0].uuid, {
          file: videoFiles[0],
          topic_id: topic.id,
        });
      } else {
        await actions.createTopicAnimation({
          file: videoFiles[0],
          topic_id: topic.id,
        });
      }

      setVideoFiles([]);
    }
  };

  const handleAnimationDelete = (animation: TopicAnimation) => async () => {
    await actions.deleteTopicAnimation(animation.uuid);
    setVideoFiles([]);
  };

  return (
    <Section variant="outlined">
      <SectionHeading heading="Topic Animations" />
      <Typography variant="subtitle1">
        Please try to keep videos to less than 25mb and 720p for optimal app
        performance
      </Typography>
      {topicAnimations.map(animation => (
        <Section key={animation.uuid} variant="outlined">
          <Box width="100%" display="flex" justifyContent="space-between">
            <Typography variant="subtitle1">
              {animation.name === null ? 'N/A' : animation.name}
            </Typography>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={handleAnimationDelete(animation)}
              disableElevation
            >
              Delete
            </Button>
          </Box>
        </Section>
      ))}
      <Box width="100%" my={2}>
        <DropzoneArea
          filesLimit={1}
          maxFileSize={25000000}
          // fileObjects={[]}
          dropzoneText="Click or drag and drop video file"
          acceptedFiles={['video/mp4']}
          onChange={handleFileChange}
          onDropRejected={file => console.error('Rejected File', file)}
          getPreviewIcon={handlePreviewIcon}
          alertSnackbarProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
            TransitionComponent: FileDropSnackTransition,
          }}
        />
      </Box>

      <Box my={2} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          disabled={videoFiles.length === 0}
          onClick={handleAnimationUpload}
          style={{ minWidth: 171 }}
          disableElevation
        >
          {vidUploading ? (
            <CircularProgress color="inherit" />
          ) : (
            'Upload Animation'
          )}
        </Button>
      </Box>
    </Section>
  );
}
