import React from 'react';
import ScheduleEvent from './event';
import { useSnackbar } from 'notistack';
import {
  EditScheduledEventReqArgs,
  createScheduledEventAPI,
  updateScheduledEventAPI,
  deleteScheduleEventAPI,
} from './scheduleAPI';
import {
  getFilteredScheduledEventsAPI,
  ScheduledEventFilterParam,
} from './scheduleAPI';

interface ScheduleState {
  isLoading: boolean;
  error: Error | null;
  data: ScheduleEvent[];
}

type ScheduleAction =
  | { type: 'request/getScheduledEvents' }
  | { type: 'success/getScheduledEvents'; payload: ScheduleEvent[] }
  | { type: 'reject/getScheduledEvents'; payload: Error }
  | { type: 'request/createScheduledEvent' }
  | { type: 'success/createScheduledEvent'; payload: ScheduleEvent }
  | { type: 'reject/createScheduledEvent'; payload: Error }
  | { type: 'request/updateScheduledEvent' }
  | { type: 'success/updateScheduledEvent'; payload: ScheduleEvent }
  | { type: 'reject/updateScheduledEvent'; payload: Error }
  | { type: 'request/deleteScheduledEvent' }
  | { type: 'success/deleteScheduledEvent'; payload: ScheduleEvent }
  | { type: 'reject/deleteScheduledEvent'; payload: Error };

function reducer(state: ScheduleState, action: ScheduleAction): ScheduleState {
  switch (action.type) {
    case 'request/getScheduledEvents':
    case 'request/createScheduledEvent':
    case 'request/updateScheduledEvent':
    case 'request/deleteScheduledEvent':
      return { ...state, isLoading: true };

    case 'success/getScheduledEvents':
      return { ...state, isLoading: false, data: action.payload };

    case 'success/createScheduledEvent':
      return {
        ...state,
        isLoading: false,
        data: [...state.data, action.payload],
      };

    case 'success/updateScheduledEvent':
    case 'success/deleteScheduledEvent':
      const index = state.data.findIndex(
        (event) => event.id === action.payload.id
      );
      if (index > -1) {
        return {
          ...state,
          isLoading: false,
          data:
            action.type === 'success/updateScheduledEvent'
              ? [
                  ...state.data.slice(0, index),
                  action.payload,
                  ...state.data.slice(index + 1),
                ]
              : [...state.data.slice(0, index), ...state.data.slice(index + 1)],
        };
      }
      return state;

    case 'reject/getScheduledEvents':
    case 'reject/createScheduledEvent':
    case 'reject/updateScheduledEvent':
    case 'reject/deleteScheduledEvent':
      return { ...state, isLoading: false, error: action.payload };
    default:
      throw new Error('Action does not exists on Schedule Slice');
  }
}

export default function useScheduleSlice(filter: ScheduledEventFilterParam) {
  const [state, dispatch] = React.useReducer(reducer, {
    isLoading: false,
    error: null,
    data: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  const createScheduledEvent = React.useCallback(
    async (args: EditScheduledEventReqArgs) => {
      try {
        dispatch({ type: 'request/createScheduledEvent' });
        const newEvent = await createScheduledEventAPI(args);
        dispatch({ type: 'success/createScheduledEvent', payload: newEvent });
        enqueueSnackbar(`Event Schedule CREATED`, { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        dispatch({
          type: 'reject/createScheduledEvent',
          payload: error,
        });
        enqueueSnackbar(reason, { variant: 'error' });
        throw error;
      }
    },
    [dispatch, enqueueSnackbar]
  );

  const updateScheduledEvent = React.useCallback(
    async (id: string, args: EditScheduledEventReqArgs) => {
      try {
        dispatch({ type: 'request/updateScheduledEvent' });
        const updatedEvent = await updateScheduledEventAPI(id, args);
        dispatch({
          type: 'success/updateScheduledEvent',
          payload: updatedEvent,
        });
        enqueueSnackbar(`Event Schedule UPDATED`, { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        dispatch({
          type: 'reject/updateScheduledEvent',
          payload: error,
        });
        enqueueSnackbar(reason, { variant: 'error' });
        throw error;
      }
    },
    [dispatch, enqueueSnackbar]
  );

  const deleteScheduledEvent = React.useCallback(
    async (id: string) => {
      try {
        dispatch({ type: 'request/deleteScheduledEvent' });
        const deletedEvent = await deleteScheduleEventAPI(id);
        dispatch({
          type: 'success/deleteScheduledEvent',
          payload: deletedEvent,
        });
        enqueueSnackbar('Event Schedule DELETED', { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        dispatch({
          type: 'reject/deleteScheduledEvent',
          payload: error,
        });
        enqueueSnackbar(reason, { variant: 'error' });
        throw error;
      }
    },
    [dispatch, enqueueSnackbar]
  );

  const refresh = React.useCallback(async () => {
    try {
      dispatch({ type: 'request/getScheduledEvents' });
      const scheduledEvents = await getFilteredScheduledEventsAPI(filter);
      dispatch({
        type: 'success/getScheduledEvents',
        payload: scheduledEvents,
      });
    } catch (reason) {
      const error = new Error(reason);
      dispatch({
        type: 'reject/getScheduledEvents',
        payload: error,
      });
      enqueueSnackbar(reason, { variant: 'error' });
      throw error;
    }
  }, [dispatch, enqueueSnackbar, filter]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    state,
    actions: {
      createScheduledEvent,
      updateScheduledEvent,
      deleteScheduledEvent,
      refresh,
    },
  };
}
