import { Box, createStyles, FormControl, makeStyles, MenuItem, Select, Typography } from '@material-ui/core';
import { ChevronLeft, ChevronRight, MenuRounded } from '@material-ui/icons';
import { Content, Header, Nav, presets, Root } from 'mui-layout';
import React from 'react';

import { AppContext } from '../AppContext';
import NavList from './NavList';

const muiLayoutConfig = presets.createStandardLayout();

const useHeaderStyles = makeStyles((theme) =>
  createStyles({
    header: {
      backgroundColor: theme.palette.primary.main,
    },
    appSelect: {
      color: 'white',
      '& svg': { display: 'none' },
      '& input': { border: 'none' },
      '& .MuiSelect-outlined': {
        paddingRight: theme.spacing(1.5),
      },
      '& fieldset': {
        borderColor: 'white', 
        '&:hover': {
          borderColor: 'whtie'
        }
      }
    },
  })
);

export default function MainLayout(props: { content: React.ReactNode }) {
  const { content } = props;

  const { app, setApp } = React.useContext(AppContext);

  const { header: headerCSS, appSelect } = useHeaderStyles();
  const handleChange = () => {
    if (app && setApp) {
      if (app === 'ss') setApp('sa');
      else setApp('ss');
    }
  };
  return (
    <Root
      config={{
        ...muiLayoutConfig,
        collapsible: false,
        navWidth: 200,
        navVariant: {
          sm: 'temporary',
          md: 'permanent',
        },
      }}
    >
      <Header
        classes={{ root: headerCSS }}
        renderMenuIcon={(open: boolean) =>
          open ? (
            <ChevronLeft htmlColor="white" />
          ) : (
            <MenuRounded htmlColor="white" />
          )
        }
      >
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="h4"
            color="initial"
            style={{ color: 'white', fontFamily: 'Times New Roman, serif' }}
          >
            Treatment Innovations
          </Typography>
          <FormControl variant="outlined" size="small" hiddenLabel>
            {/* <InputLabel
              id="demo-simple-select-outlined-label"
              style={{ color: 'white' }}
            >
              Select App
            </InputLabel> */}
            <Select
              className={appSelect}
              // labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={app}
              onChange={handleChange}
              // label="Select App to Manage"
            >
              <MenuItem value="ss">Seeking Safety</MenuItem>
              <MenuItem value="sa">Self Aware</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Header>
      <Nav
        renderIcon={(collapsed: boolean) =>
          collapsed ? <ChevronRight /> : <ChevronLeft />
        }
      >
        <NavList />
      </Nav>
      <Content>{content}</Content>
      {/* <Footer>Footer</Footer> */}
    </Root>
  );
}
