import {
  Box,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { LearnCategory } from './learn';

// import { UseCohortsSlice } fom './useCohortsSlice';

type LearnCategoryFormData = {
  name: string;
};

export default function LearnCategoryForm(props: {
  category?: LearnCategory;
  onSubmit?: (name: string, reIndex?: { index: number; id: number }) => void;
  onDeleteSubmit?: (category: LearnCategory) => void;
  priorityList?: number[];
}) {
  const { category, onSubmit, onDeleteSubmit, priorityList } = props;

  const [priorityIndex, setPriorityIndex] = React.useState(category?.index);

  const {
    register,
    handleSubmit,
    errors,
    setValue,
  } = useForm<LearnCategoryFormData>();

  const _onSubmit = handleSubmit(formData => {
    if (onSubmit)
      onSubmit(
        formData.name,
        priorityIndex !== undefined && priorityIndex !== category!.index
          ? { index: priorityIndex, id: category!.id }
          : undefined
      );
  });

  const handleDelete = (cat: LearnCategory) => () => {
    if (onDeleteSubmit) onDeleteSubmit(cat);
  };

  const handlePriorityChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    // if (onPriorityReindex)
    //   onPriorityReindex(category!.id, event.target.value as number);
    setPriorityIndex(event.target.value as number);
  };

  React.useEffect(() => {
    if (category !== undefined) {
      setPriorityIndex(category.index);
      setValue('name', category.name);
    }
  }, [category, setValue]);

  return (
    <form onSubmit={_onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={category ? 6 : 12}>
          <TextField
            fullWidth
            label="Change Category Name"
            variant="filled"
            name="name"
            defaultValue={category?.name}
            inputRef={register({ required: 'Required' })}
            helperText={errors.name?.message}
            error={Boolean(errors.name)}
          />
        </Grid>
        {category && (
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="priority-select-label" variant="filled">
                Priority
              </InputLabel>
              <Select
                labelId="priority-select-label"
                id="priority-select"
                value={priorityIndex}
                variant="filled"
                onChange={handlePriorityChange}
              >
                {priorityList?.map((id, i) => (
                  <MenuItem key={id} value={i}>
                    {/* {i + 1} */}
                    {i + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Box width="100%">
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              type="submit"
            >
              {category ? 'Update Category' : 'Create Category'}
            </Button>
          </Grid>
          {category && (
            <Grid item>
              <Button
                variant="contained"
                disableElevation
                color="secondary"
                onClick={handleDelete(category)}
              >
                Delete Category
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </form>
  );
}
