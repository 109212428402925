import { Box, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import React from 'react';

import SectionHeading from '../../components/SectionHeading';
import tableIcons from '../../config/tableIcons';
import User from '../user/user';
// import useSafetyPlanResponsesSlice from './useSafetyPlanResponseSlice';
import useSafetyPlansSlice from './useSafetyPlansSlice';
import RefreshIcon from '@material-ui/icons/Refresh';

export default function DailySafetyPlanTables(props: { user: User }) {
  const { user } = props;
  const safetyPlansSlice = useSafetyPlansSlice(user.uuid);

  // let filter = React.useMemo(() => {
  //   return {
  //     start: '2019-08-16T21:14:45.421Z',
  //     end: '3000-08-23T21:14:45.421Z',
  //   };
  // }, []);
  // // update to use filter above
  // const safetyPlanResponses = useSafetyPlanResponsesSlice(user.uuid);

  const { plans, isLoading } = safetyPlansSlice.state;
  const actions = safetyPlansSlice.actions;
  const safetyPlan = React.useMemo(
    () =>
      plans.length > 0
        ? plans[0].data.safety_plan.reduce<{
            [intensity: string]: {
              emotions: string[];
              intensity: string;
              responses: string[];
            };
          }>((accum, curr, index) => {
            switch (index) {
              case 0:
              case 1:
                accum['mild'] = {
                  ...accum['mild'],
                  intensity: 'Mild',
                  emotions:
                    index === 0
                      ? [
                          curr.method_0?.text ?? '',
                          curr.method_1?.text ?? '',
                          curr.method_2?.text ?? '',
                        ]
                      : accum['mild']?.emotions ?? [],
                  responses:
                    index === 1
                      ? [
                          curr.method_0?.text ?? '',
                          curr.method_1?.text ?? '',
                          curr.method_2?.text ?? '',
                        ]
                      : accum['mild']?.responses ?? [],
                };
                break;
              case 2:
              case 3:
                accum['moderate'] = {
                  ...accum['moderate'],
                  intensity: 'Moderate',
                  emotions:
                    index === 2
                      ? [
                          curr.method_0?.text ?? '',
                          curr.method_1?.text ?? '',
                          curr.method_2?.text ?? '',
                        ]
                      : accum['moderate']?.emotions ?? [],
                  responses:
                    index === 3
                      ? [
                          curr.method_0?.text ?? '',
                          curr.method_1?.text ?? '',
                          curr.method_2?.text ?? '',
                        ]
                      : accum['moderate']?.responses ?? [],
                };
                break;
              case 4:
              case 5:
                accum['serious'] = {
                  ...accum['serious'],
                  intensity: 'Serious',
                  emotions:
                    index === 4
                      ? [
                          curr.method_0?.text ?? '',
                          curr.method_1?.text ?? '',
                          curr.method_2?.text ?? '',
                        ]
                      : accum['serious']?.emotions ?? [],
                  responses:
                    index === 5
                      ? [
                          curr.method_0?.text ?? '',
                          curr.method_1?.text ?? '',
                          curr.method_2?.text ?? '',
                        ]
                      : accum['serious']?.responses ?? [],
                };
                break;
              default:
                break;
            }
            return accum;
          }, {})
        : {},
    [plans]
  );

  return (
    <MaterialTable
      isLoading={isLoading}
      title={<SectionHeading heading="Safety Plan" />}
      components={{ Container: Box }}
      icons={tableIcons}
      columns={[
        { title: 'Danger', field: 'intensity' },
        {
          title: 'Emotions',
          field: 'emotions',
          render: rowData =>
            rowData.emotions.map((emo, i) => (
              <Typography key={i} gutterBottom>
                {/* {`${i+1}.\t${emo}`} */}- {emo}
              </Typography>
            )),
        },
        {
          title: 'Responses',
          field: 'responses',
          render: rowData =>
            rowData.responses.map((resp, i) => (
              <Typography key={i} gutterBottom>
                {/* {`${i+1}.\t${resp}`} */}- {resp}
              </Typography>
            )),
        },
      ]}
      data={Object.values(safetyPlan)}
      actions={[
        {
          icon: RefreshIcon,
          tooltip: 'Refresh Data',
          isFreeAction: true,
          onClick: () => actions.refresh(),
        },
      ]}
      options={{
        pageSize: 6,
        paging: false,
        search: false,
        exportButton: true,
      }}
    />
  );
}
