import { Box } from '@material-ui/core';
import MaterialTable from 'material-table';
import React from 'react';

import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import tableIcons from '../../config/tableIcons';
import { useTriggerEventsSlice } from './useTriggerSlice';
import RefreshIcon from '@material-ui/icons/Refresh';

export default function PrepareForTriggerTableTable(props: any) {
  const userUUID = props.userUUID;
  const triggersEventsSlice = useTriggerEventsSlice(userUUID);

  // Create Prep For Trigger Data for Table
  const createData = (dateTime: string, appAction: string) => {
    return { dateTime, appAction };
  };

  // Convert Prep For Trigger Data to Rows
  const createTable = () => {
    let rows: any = [];
    if (triggersEventsSlice.state.data) {
      triggersEventsSlice.state.data.forEach(event => {
        rows.push(
          createData(
            new Date(event.scheduled_event.start_at).toLocaleString(),
            event.app_action
          )
        );
      });
    }
    return rows;
  };

  return (
    <Section variant="outlined">
      {triggersEventsSlice.state.data ? (
        <MaterialTable
          isLoading={triggersEventsSlice.state.isLoading}
          title={<SectionHeading heading="Scheduled Trigger" />}
          components={{ Container: Box }}
          icons={tableIcons}
          columns={[
            { title: 'Date Time', field: 'datetime', defaultSort: 'asc' },
            {
              title: 'App Action',
              field: 'appAction',
            },
          ]}
          data={createTable()}
          actions={[
            {
              icon: RefreshIcon,
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: () => triggersEventsSlice.actions.refresh(),
            },
          ]}
          options={{ pageSize: 6, paging: false, search: false, exportButton: true }}
        />
      ) : null}
    </Section>
  );
}
