import { Box, Button, CircularProgress } from '@material-ui/core';
import React from 'react';

import Section from '../../../components/Section';
import SectionHeading from '../../../components/SectionHeading';
import User from '../../user/user';
import useNoteToSelfsSlice from '../note_to_self/useNoteToSelfSlice';
import useCheckInsSlice from '../useCheckInsSlice';
import SafetyReportTables from './SafetyReportTables';
import YouVsAppTable from './youVsAppTable';

// container for checkin analysis
export default function SafetyReportContainer(props: { user: User }) {
  const userUUID = props.user.uuid;
  const checkInsSlice = useCheckInsSlice(userUUID);
  const notesSlice = useNoteToSelfsSlice(userUUID);
  const { data: notes } = notesSlice.state;
  const { data: checkinData, isLoading } = checkInsSlice.state;
  const actions = checkInsSlice.actions;
  const handleDataRefresh = () => {
    actions.refresh();
  };
  // filter checkion for only self_report checkins
  // const selfReportData = () => {
  //   const rows: CheckIn[] = [];
  //   checkinData.forEach(checkin => {
  //     if (checkin.type === 'self_report') {
  //       rows.push(checkin);
  //     }
  //   });
  //   return rows;
  // };

  const selfReportData = React.useMemo(() => checkinData.filter(checkin => checkin.type === 'self_report'), [checkinData]);
  const youVSAppData = React.useMemo(() => checkinData.filter(checkin => checkin.type !== 'self_report'), [checkinData])

  return (
    <React.Fragment>
      <Section variant="outlined">
        <SectionHeading heading="Daily Report" />
        <Box display="flex" width="100%" justifyContent="flex-end" my={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDataRefresh}
          >
            {isLoading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              'Refresh'
            )}
          </Button>
        </Box>
        {selfReportData.map((selfReport, i) => (
          <SafetyReportTables
            selfReport={selfReport}
            notes={notes}
            key={i}
            isLoading={isLoading}
          />
        ))}
      </Section>
      <Section variant="outlined">
        <SectionHeading heading="You Vs The App" />
        <YouVsAppTable checkins={youVSAppData} isLoading={isLoading} />
      </Section>
    </React.Fragment>
  );
}
