import React from 'react';
import {TopicReflection} from './ratings';
import { TopicReflectionAPI } from './ratingsAPI';
import { useSnackbar } from 'notistack';

interface TopicReflectionState {
  isLoading: boolean;
  error: Error | null;
  data: TopicReflection[] | null;
}

export default function useTopicRefelectionsSlice(cohort_id: number) {
  const [state, setState] = React.useState<TopicReflectionState>({
    isLoading: false,
    error: null,
    data: null,
  });

  const { enqueueSnackbar } = useSnackbar();

  const refresh = React.useCallback(async () => {
    try {
      setState(prev => ({ ...prev, isLoading: true }));
      const topicReflection = await TopicReflectionAPI({cohort_id});
      setState(prev => ({ ...prev, isLoading: false, data: topicReflection }));
    } catch (reason) {
      const error = new Error(reason);
      setState(prev => ({ ...prev, isLoading: false, error }));
      enqueueSnackbar(reason, { variant: 'error' });
    }
  }, [ cohort_id,enqueueSnackbar]);

  React.useEffect(() => {
    refresh();
  }, [cohort_id,refresh]);

  return { state, actions: { refresh } };
}
