import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MaterialTable from 'material-table';
import React from 'react';

import Section from '../../../components/Section';
import tableIcons from '../../../config/tableIcons';
import User from '../../user/user';
import CheckIn, { Supplemental } from '../checkIn';
import useCheckInsSlice from '../useCheckInsSlice';
import useThresholdSlice from '../useThresholdSlice';
import AnalysisThresholdSlides from './AnalysisThresholdSlides';

export default function UserEmotionAnalysisTable(props: {
  user: User;
  start?: string;
  end?: string;
}) {
  const { user, start, end } = props;

  const [textThreshold, setTextThreshold] = React.useState(65);
  const [videoThreshold, setVideoThreshold] = React.useState(65);
  const [audioThreshold, setAudioThreshold] = React.useState(65);
  const thresholdSlice = useThresholdSlice();
  const threshold = thresholdSlice.state.data;
  const thresholdActions = thresholdSlice.actions;

  const query = React.useMemo(
    () => (start && end ? { start, end } : undefined),
    [start, end]
  );

  const checkInsSlice = useCheckInsSlice(user?.uuid, query);
  const { data, isLoading } = checkInsSlice.state;
  const actions = checkInsSlice.actions;

  const theme = useTheme();

  const handleDataRefresh = () => {
    actions.refresh();
  };

  const handleUpdateThreshold = () => {
    thresholdActions.updateThresholds({
      text: textThreshold,
      video: videoThreshold,
      audio: audioThreshold,
    });
  };

  const handleTextThreshold = React.useCallback(
    (event: React.ChangeEvent<{}>, value: number | number[]) => {
      setTextThreshold(value as number);
    },
    [setTextThreshold]
  );
  const handleVideoThreshold = React.useCallback(
    (event: React.ChangeEvent<{}>, value: number | number[]) => {
      setVideoThreshold(value as number);
    },
    [setVideoThreshold]
  );
  const handleAudioThreshold = React.useCallback(
    (event: React.ChangeEvent<{}>, value: number | number[]) => {
      setAudioThreshold(value as number);
    },
    [setAudioThreshold]
  );

  const isAboveThreshold = (checkIn: CheckIn) => {
    // just return false if checkIn is undefined
    if (!checkIn) return false;
    // we don't want id as part of the iteration
    // delete checkIn.id;
    const chkIn: Partial<CheckIn> = { ...checkIn };
    delete chkIn.id;
    for (const key in chkIn) {
      if (Object.prototype.hasOwnProperty.call(checkIn, key)) {
        const element = checkIn[key as keyof CheckIn];
        if (typeof element === 'number') {
          const threshold =
            checkIn.type === 'text'
              ? checkIn.threshold.text
              : checkIn.type === 'video'
              ? checkIn.threshold.video
              : checkIn.threshold.audio;
          if (element * 100 <= threshold) continue;
          return true;
        }
      }
    }
    return false;
  };

  const sessionCheckIns = React.useMemo(() => {
    const formatReport = (checkIn: CheckIn): CheckIn => {
      return {
        ...checkIn,
        angry: checkIn.angry ?? 0,
        anxious: checkIn.anxious ?? 0,
        calm: checkIn.calm ?? 0,
        disgusted: checkIn.disgusted ?? 0,
        happy: checkIn.happy ?? 0,
        sad: checkIn.sad ?? 0,
        scornful: checkIn.scornful ?? 0,
        surprised: checkIn.surprised ?? 0,
      };
    };
    let chkIns = Object.values(
      data.reduce<{
        [uuid: string]: {
          text: CheckIn;
          video: CheckIn;
          audio: CheckIn;
          self_report: CheckIn;
        };
      }>((accum, curr) => {
        if (curr.session_uuid === null) return accum;
        switch (curr.type) {
          case 'text':
            accum[curr.session_uuid] = {
              ...accum[curr.session_uuid],
              text: formatReport(curr),
            };
            break;
          case 'video':
            accum[curr.session_uuid] = {
              ...accum[curr.session_uuid],
              video: formatReport(curr),
            };
            break;
          case 'audio':
            accum[curr.session_uuid] = {
              ...accum[curr.session_uuid],
              audio: formatReport(curr),
            };
            break;
          case 'self_report':
            accum[curr.session_uuid] = {
              ...accum[curr.session_uuid],
              self_report: formatReport(curr),
            };
            break;
          default:
            throw new Error('Check In does not have supported `type`');
        }
        return accum;
      }, {})
    );
    const emotions = [
      'angry',
      'anxious',
      'calm',
      'disgusted',
      'happy',
      'sad',
      'scornful',
      'surprised',
    ];
    return chkIns.map(checkIns => {
      // intensity will be a CheckIn type but with some missing props
      let intensity: any = {};
      let selfReport: any = {};
      selfReport.type = 'self_report';
      // getting values array assures we only get existing checkIns (no undefines)
      let available = Object.values(checkIns);
      available.forEach(chkInType => {
        for (const key in chkInType) {
          if (Object.prototype.hasOwnProperty.call(chkInType, key)) {
            if (!emotions.includes(key)) continue;
            // we can assume prop to only be number|null from the above logic.
            let prop = chkInType[key as keyof CheckIn] as number | null;

            let isHealthy = chkInType.supplemental
              ? chkInType.supplemental![
                  `is_healthy_${key}` as keyof Supplemental
                ]
              : false;

            const healthyLabel =
              typeof isHealthy === 'boolean' || isHealthy === null
                ? isHealthy
                  ? 'HEALTHY'
                  : isHealthy !== null
                  ? 'UNHEALTHY'
                  : 'N/A'
                : 'NONE';

            if (chkInType.type === 'self_report') {
              let selfReportLabel = '';
              switch (prop) {
                case 0:
                  selfReportLabel = `NONE ${healthyLabel}`;
                  break;
                case 0.33:
                  selfReportLabel = `MILD ${healthyLabel}`;
                  break;
                case 0.67:
                  selfReportLabel = `MEDIUM ${healthyLabel}`;
                  break;
                case 1:
                  selfReportLabel = `INTENSE ${healthyLabel}`;
                  break;
                default:
                  break;
              }
              selfReport[key] = selfReportLabel;
            } else {
              if (prop === null) prop = 0;
              // get the corresponding threshold to use
              const threshold =
                chkInType.type === 'text'
                  ? checkIns.text.threshold.text
                  : chkInType.type === 'video'
                  ? checkIns.video.threshold.video
                  : checkIns.audio.threshold.audio;
              // make sure prop exists first
              if (intensity[key] === undefined) intensity[key] = 0;
              intensity[key] =
                prop * 100 >= threshold ? intensity[key] + 1 : intensity[key];
            }
          }
        }
      });
      // translate intensity count to string
      for (const key in intensity) {
        if (Object.prototype.hasOwnProperty.call(intensity, key)) {
          if (intensity[key] === 3) intensity[key] = 'HIGH';
          else if (intensity[key] === 2) intensity[key] = 'MID';
          else if (intensity[key] === 1) intensity[key] = 'LOW';
          else if (intensity[key] === 0) intensity[key] = 'NONE';
        }
      }
      // make sure to distinguish this CheckIn by setting type to 'intensity'
      intensity.type = 'intensity';
      // this is temporary since we have missing data on some users.
      const { self_report, ...rest } = checkIns;
      if (self_report) return { ...rest, intensity, self_report: selfReport };
      return { ...checkIns, intensity };
    });
  }, [data]);

  React.useEffect(() => {
    setTextThreshold(threshold.text);
    setVideoThreshold(threshold.video);
    setAudioThreshold(threshold.audio);
  }, [threshold]);

  return (
    <React.Fragment>
      <AnalysisThresholdSlides
        onTextChangeCommited={handleTextThreshold}
        onVideoChangeCommited={handleVideoThreshold}
        onAudioChangeCommited={handleAudioThreshold}
        handleUpdateThreshold={handleUpdateThreshold}
      />
      <Box display="flex" width="100%" justifyContent="flex-end" my={2}>
        <Button variant="contained" color="primary" onClick={handleDataRefresh}>
          {isLoading ? (
            <CircularProgress color="inherit" size={24} />
          ) : (
            'Refresh'
          )}
        </Button>
      </Box>
      {sessionCheckIns.map((checkIn, index) => {
        return (
          <Accordion
            key={index}
            variant="outlined"
            TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`check-in-${index}-header`}
            >
              <Typography>{`Check-In - ${
                checkIn.text
                  ? new Date(checkIn.text.created).toLocaleDateString()
                  : 'Missing Timestamp'
              }`}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block' }}>
              <Section variant="outlined">
                <MaterialTable
                  isLoading={isLoading}
                  title="Analysis Breakdown"
                  icons={tableIcons}
                  components={{ Container: Box }}
                  columns={[
                    {
                      title: 'Type  ',
                      field: 'type',
                      render: rowData => {
                        let label =
                          rowData.type === 'self_report' ? (
                            <b>Self Report Intensity</b>
                          ) : (
                            rowData.type.charAt(0).toUpperCase() +
                            rowData.type.slice(1)
                          );
                        if (label === 'Intensity') {
                          label = <b>Intensity</b>;
                        }
                        return label;
                      },
                    },
                    {
                      title: 'Anger',
                      field: 'angry',
                      render: rowData => {
                        // if (
                        //   rowData.type === 'intensity' ||
                        //   rowData.type === 'self_report'
                        // )
                        //   return rowData.angry;

                        if (rowData.type === 'intensity') {
                          return <b>{rowData.angry}</b>;
                        }
                        if (rowData.type === 'self_report') {
                          return (rowData.angry as string)
                            .split(' ')
                            .map(s => <Typography key={s}>{s}</Typography>);
                        }
                        let label: any = '';
                        let threshold = 0;
                        switch (rowData.type) {
                          case 'video':
                            threshold = checkIn.video.threshold.video;
                            break;
                          case 'audio':
                            threshold = checkIn.audio.threshold.audio;
                            break;
                          case 'text':
                            threshold = checkIn.text.threshold.text;
                            break;

                          default:
                            threshold = 65;
                            break;
                        }
                        if (rowData.angry) {
                          if (rowData.angry * 100 > threshold) {
                            // label = 'angry passed';

                            label = (
                              <p>
                                <strong>{`${(rowData.angry * 100).toFixed(
                                  2
                                )}%`}</strong>
                              </p>
                            );
                          } else {
                            label = `${(rowData.angry * 100).toFixed(2)}%`;
                          }
                        } else {
                          label = '0%';
                        }
                        return label;
                      },
                    },
                    {
                      title: 'Anxious',
                      field: 'anxious',
                      render: rowData => {
                        if (rowData.type === 'intensity') {
                          return <b>{rowData.anxious}</b>;
                        }
                        if (rowData.type === 'self_report') {
                          return (rowData.anxious as string)
                            .split(' ')
                            .map(s => <Typography key={s}>{s}</Typography>);
                        }
                        let label: any = '';
                        let threshold = 0;
                        switch (rowData.type) {
                          case 'video':
                            threshold = checkIn.video.threshold.video;
                            break;
                          case 'audio':
                            threshold = checkIn.audio.threshold.audio;
                            break;
                          case 'text':
                            threshold = checkIn.text.threshold.text;
                            break;

                          default:
                            threshold = 65;
                            break;
                        }
                        if (rowData.anxious) {
                          if (rowData.anxious * 100 > threshold) {
                            // label = 'anxious passed';

                            label = (
                              <p>
                                <strong>{`${(rowData.anxious * 100).toFixed(
                                  2
                                )}%`}</strong>
                              </p>
                            );
                          } else {
                            label = `${(rowData.anxious * 100).toFixed(2)}%`;
                          }
                        } else {
                          label = '0%';
                        }
                        return label;
                      },
                    },
                    {
                      title: 'Calm',
                      field: 'calm',
                      render: rowData => {
                        if (rowData.type === 'intensity') {
                          return <b>{rowData.calm}</b>;
                        }
                        if (rowData.type === 'self_report') {
                          return (rowData.calm as string)
                            .split(' ')
                            .map(s => <Typography key={s}>{s}</Typography>);
                        }
                        let label: any = '';
                        let threshold = 0;
                        switch (rowData.type) {
                          case 'video':
                            threshold = checkIn.video.threshold.video;
                            break;
                          case 'audio':
                            threshold = checkIn.audio.threshold.audio;
                            break;
                          case 'text':
                            threshold = checkIn.text.threshold.text;
                            break;

                          default:
                            threshold = 65;
                            break;
                        }
                        if (rowData.calm) {
                          if (rowData.calm * 100 > threshold) {
                            // label = 'calm passed';

                            label = (
                              <p>
                                <strong>{`${(rowData.calm * 100).toFixed(
                                  2
                                )}%`}</strong>
                              </p>
                            );
                          } else {
                            label = `${(rowData.calm * 100).toFixed(2)}%`;
                          }
                        } else {
                          label = '0%';
                        }
                        return label;
                      },
                    },
                    {
                      title: 'Disgusted',
                      field: 'disgusted',
                      render: rowData => {
                        if (rowData.type === 'intensity') {
                          return <b>{rowData.disgusted}</b>;
                        }
                        if (rowData.type === 'self_report') {
                          return (rowData.disgusted as string)
                            .split(' ')
                            .map(s => <Typography key={s}>{s}</Typography>);
                        }
                        let label: any = '';
                        let threshold = 0;
                        switch (rowData.type) {
                          case 'video':
                            threshold = checkIn.video.threshold.video;
                            break;
                          case 'audio':
                            threshold = checkIn.audio.threshold.audio;
                            break;
                          case 'text':
                            threshold = checkIn.text.threshold.text;
                            break;

                          default:
                            threshold = 65;
                            break;
                        }
                        if (rowData.disgusted) {
                          if (rowData.disgusted * 100 > threshold) {
                            // label = 'disgusted passed';

                            label = (
                              <p>
                                <strong>{`${(rowData.disgusted * 100).toFixed(
                                  2
                                )}%`}</strong>
                              </p>
                            );
                          } else {
                            label = `${(rowData.disgusted * 100).toFixed(2)}%`;
                          }
                        } else {
                          label = '0%';
                        }
                        return label;
                      },
                    },
                    {
                      title: 'Happy',
                      field: 'happy',
                      render: rowData => {
                        if (rowData.type === 'intensity') {
                          return <b>{rowData.happy}</b>;
                        }
                        if (rowData.type === 'self_report') {
                          return (rowData.happy as string)
                            .split(' ')
                            .map(s => <Typography key={s}>{s}</Typography>);
                        }
                        let label: any = '';
                        let threshold = 0;
                        switch (rowData.type) {
                          case 'video':
                            threshold = checkIn.video.threshold.video;
                            break;
                          case 'audio':
                            threshold = checkIn.audio.threshold.audio;
                            break;
                          case 'text':
                            threshold = checkIn.text.threshold.text;
                            break;

                          default:
                            threshold = 65;
                            break;
                        }
                        if (rowData.happy) {
                          if (rowData.happy * 100 > threshold) {
                            // label = 'happy passed';

                            label = (
                              <p>
                                <strong>{`${(rowData.happy * 100).toFixed(
                                  2
                                )}%`}</strong>
                              </p>
                            );
                          } else {
                            label = `${(rowData.happy * 100).toFixed(2)}%`;
                          }
                        } else {
                          label = '0%';
                        }
                        return label;
                      },
                    },

                    {
                      title: 'Sadness',
                      field: 'sad',
                      render: rowData => {
                        if (rowData.type === 'intensity') {
                          return <b>{rowData.sad}</b>;
                        }
                        if (rowData.type === 'self_report') {
                          return (rowData.sad as string)
                            .split(' ')
                            .map(s => <Typography key={s}>{s}</Typography>);
                        }
                        let label: any = '';
                        let threshold = 0;
                        switch (rowData.type) {
                          case 'video':
                            threshold = checkIn.video.threshold.video;
                            break;
                          case 'audio':
                            threshold = checkIn.audio.threshold.audio;
                            break;
                          case 'text':
                            threshold = checkIn.text.threshold.text;
                            break;

                          default:
                            threshold = 65;
                            break;
                        }
                        if (rowData.sad) {
                          if (rowData.sad * 100 > threshold) {
                            // label = 'sad passed';

                            label = (
                              <p>
                                <strong>{`${(rowData.sad * 100).toFixed(
                                  2
                                )}%`}</strong>
                              </p>
                            );
                          } else {
                            label = `${(rowData.sad * 100).toFixed(2)}%`;
                          }
                        } else {
                          label = '0%';
                        }
                        return label;
                      },
                    },
                    {
                      title: 'Scornful',
                      field: 'scornful',
                      render: rowData => {
                        if (rowData.type === 'intensity') {
                          return <b>{rowData.scornful}</b>;
                        }
                        if (rowData.type === 'self_report') {
                          return (rowData.scornful as string)
                            .split(' ')
                            .map(s => <Typography key={s}>{s}</Typography>);
                        }
                        let label: any = '';
                        let threshold = 0;
                        switch (rowData.type) {
                          case 'video':
                            threshold = checkIn.video.threshold.video;
                            break;
                          case 'audio':
                            threshold = checkIn.audio.threshold.audio;
                            break;
                          case 'text':
                            threshold = checkIn.text.threshold.text;
                            break;

                          default:
                            threshold = 65;
                            break;
                        }
                        if (rowData.scornful) {
                          if (rowData.scornful * 100 > threshold) {
                            // label = 'scornful passed';

                            label = (
                              <p>
                                <strong>{`${(rowData.scornful * 100).toFixed(
                                  2
                                )}%`}</strong>
                              </p>
                            );
                          } else {
                            label = `${(rowData.scornful * 100).toFixed(2)}%`;
                          }
                        } else {
                          label = '0%';
                        }
                        return label;
                      },
                    },
                    {
                      title: 'Surprised',
                      field: 'surprised',
                      render: rowData => {
                        if (rowData.type === 'intensity') {
                          return <b>{rowData.surprised}</b>;
                        }
                        if (rowData.type === 'self_report') {
                          return (rowData.surprised as string)
                            .split(' ')
                            .map(s => <Typography key={s}>{s}</Typography>);
                        }
                        let label: any = '';
                        let threshold = 0;
                        switch (rowData.type) {
                          case 'video':
                            threshold = checkIn.video.threshold.video;
                            break;
                          case 'audio':
                            threshold = checkIn.audio.threshold.audio;
                            break;
                          case 'text':
                            threshold = checkIn.text.threshold.text;
                            break;

                          default:
                            threshold = 65;
                            break;
                        }
                        if (rowData.surprised) {
                          if (rowData.surprised * 100 > threshold) {
                            // label = 'surprised passed';

                            label = (
                              <p>
                                <strong>{`${(rowData.surprised * 100).toFixed(
                                  2
                                )}%`}</strong>
                              </p>
                            );
                          } else {
                            label = `${(rowData.surprised * 100).toFixed(2)}%`;
                          }
                        } else {
                          label = '0%';
                        }
                        return label;
                      },
                    },
                  ]}
                  data={Object.values(checkIn)}
                  detailPanel={[
                    rowData => ({
                      // tooltip: 'More',
                      disabled: rowData.type === 'intensity',
                      render: rowData => (
                        <Box p={2} /* width="100%" */>
                          <Grid
                            container
                            spacing={1} /* style={{width: '100%'}} */
                          >
                            {rowData.type !== 'self_report' && (
                              <Grid item xs={4}>
                                {rowData.type === 'video' && (
                                  <img
                                    src={rowData.media_url ?? undefined}
                                    width="100%"
                                    alt="video analysis"
                                    // width={360}
                                    // controls
                                  />
                                )}
                                {rowData.type === 'audio' && (
                                  <audio
                                    src={rowData.media_url ?? undefined}
                                    controls
                                    style={{ width: '100%' }}
                                  />
                                )}
                                {rowData.type === 'text' && (
                                  <Typography align="center">
                                    {Boolean(rowData.text) ? (
                                      <>
                                        <b>Content: </b>
                                        {rowData.text}
                                      </>
                                    ) : (
                                      'No records to display'
                                    )}
                                  </Typography>
                                )}
                              </Grid>
                            )}
                            <Grid
                              item
                              xs={rowData.type === 'self_report' ? 12 : 8}
                            >
                              <Typography>
                                {Boolean(rowData.prompt) ? (
                                  <>
                                    <b>Prompt: </b>
                                    {rowData.prompt}
                                  </>
                                ) : (
                                  'No records to display'
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          {rowData.type === 'self_report' &&
                            rowData.supplemental && (
                              <>
                                <Typography>
                                  {`Substance was used: ${
                                    rowData.supplemental.did_use_substance
                                      ? 'Yes'
                                      : 'No'
                                  }`}
                                </Typography>
                                <Typography>
                                  {`Substance craving: ${
                                    rowData.supplemental.substance_craving * 100
                                  }%`}
                                </Typography>
                                <Typography>
                                  {`Substance used: ${
                                    rowData.supplemental.substance_used ?? ''
                                  }`}
                                </Typography>
                                <Typography>
                                  {`Honesty: ${
                                    rowData.supplemental.honesty ?? ''
                                  }`}
                                </Typography>
                                <Typography>
                                  {`Triggered: ${
                                    rowData.supplemental.triggered ?? ''
                                  }`}
                                </Typography>
                              </>
                            )}
                        </Box>
                      ),
                    }),
                  ]}
                  options={{
                    paging: false,
                    pageSize: 10,
                    rowStyle: rowData => {
                      const isIntensity = rowData.type === 'intensity';
                      const isWithin =
                        isIntensity || rowData.type === 'self_report'
                          ? true
                          : isAboveThreshold(rowData);
                      return {
                        backgroundColor: isWithin
                          ? isIntensity
                            ? theme.palette.primary.light
                            : '#FFF'
                          : theme.palette.grey[200],
                        opacity: isWithin ? 1.0 : 0.4,
                      };
                    },
                    search: false,
                    exportButton: true,
                    exportAllData: true,
                    exportFileName: `emotional_analysis_breakdown_${
                      user?.study_id ?? ''
                    }_${user?.study_initials ?? ''}`,
                  }}
                />
              </Section>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </React.Fragment>
  );
}
