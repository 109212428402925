import React from 'react';
import { SafetyPlan } from './safetyPlan';
import { useSnackbar } from 'notistack';
import { getSafetyPlansListAPI } from './safetyPlanAPI';

interface SafetyPlansState {
  isLoading: boolean;
  error: Error | null;
  plans: SafetyPlan[];
}

export default function useSafetyPlansSlice(user_uuid?: string) {
  const [state, setState] = React.useState<SafetyPlansState>({
    isLoading: false,
    error: null,
    plans: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  const refresh = React.useCallback(async () => {
    try {
      setState(prev => ({ ...prev, isLoading: true }));
      const safetyPlans = await getSafetyPlansListAPI({user_uuid});
      setState(prev => ({ ...prev, isLoading: false, plans: safetyPlans }));
    } catch (reason) {
      const error = new Error(reason);
      setState(prev => ({ ...prev, isLoading: false, error }));
      enqueueSnackbar(reason, { variant: 'error' });
    }
  }, [user_uuid, setState, enqueueSnackbar]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return { state, actions: { refresh } };
}
