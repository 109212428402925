import { useSnackbar } from 'notistack';
import React from 'react';

import Quiz, { QuizUploadArgs } from './quiz';
import { createTopicQuizAPI, getTopicQuizzesAPI, updateTopicQuizAPI } from './quizAPI';

interface QuizzesState {
  isLoading: boolean;
  error: Error | null;
  data: Quiz[];
}

export default function useQuizzesSlice(filter?: { topic_id?: number }) {
  const [state, setSate] = React.useState<QuizzesState>({
    isLoading: false,
    error: null,
    data: [],
  });

  const topicID = filter?.topic_id;

  const { enqueueSnackbar } = useSnackbar();

  const createQuiz = React.useCallback(
    async (args: QuizUploadArgs) => {
      try {
        setSate((prev) => ({ ...prev, isLoading: true }));
        const newQuiz = await createTopicQuizAPI(args);
        setSate((prev) => ({
          ...prev,
          isLoading: false,
          data: [...prev.data, newQuiz],
        }));
        enqueueSnackbar(`Quiz CREATED`, { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        setSate((prev) => ({ ...prev, isLoading: false, error }));
        enqueueSnackbar(reason, { variant: 'error' });
      }
    },
    [setSate, enqueueSnackbar]
  );

  const updateQuiz = React.useCallback(
    async (id: number, args: QuizUploadArgs) => {
      try {
        setSate((prev) => ({ ...prev, isLoading: true }));
        const updatedQuiz = await updateTopicQuizAPI(id, args);
        setSate((prev) => {
          const index = prev.data.findIndex(
            (quiz) => quiz.id === updatedQuiz.id
          );
          if (index > -1) {
            return {
              ...prev,
              isLoading: false,
              data: [
                ...prev.data.slice(0, index),
                updatedQuiz,
                ...prev.data.slice(index + 1),
              ],
            };
          }
          return { ...prev, isLoading: false };
        });
        enqueueSnackbar(`Quiz UPDATED`, { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        setSate((prev) => ({ ...prev, isLoading: false, error }));
        enqueueSnackbar(reason, { variant: 'error' });
      }
    },
    [setSate, enqueueSnackbar]
  );

  const refresh = React.useCallback(async () => {
    try {
      setSate((prev) => ({ ...prev, isLoading: true }));
      const quizzes = await getTopicQuizzesAPI({ topic_id: topicID });
      setSate((prev) => ({ ...prev, isLoading: false, data: quizzes }));
    } catch (reason) {
      const error = new Error(reason);
      setSate((prev) => ({ ...prev, isLoading: false, error }));
      enqueueSnackbar(reason, { variant: 'error' });
    }
  }, [setSate, topicID, enqueueSnackbar]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return { state, actions: { refresh, createQuiz, updateQuiz } };
}
