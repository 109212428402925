import { Canceler } from 'axios';

import { axios, CancelToken } from '../../config/axiosConfig';
import User from './user';
import { UserSettings } from './userSettings';

export async function updateSSUserSettingsAPI(
  uuid: string,
  data: Partial<UserSettings>,
  executor?: (canceler: Canceler) => void
) {
  try {
    const response = await axios.put<{ user: User }>(
      `/api/v1/user/${uuid}/settings`,
      data,
      {
        cancelToken: executor ? new CancelToken(executor) : undefined,
        headers: { 'Content-Type': 'application/json' },
      }
    );
    return response.data.user;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getSSUserSettingsAPI(
  uuid: string,
  executor?: (canceler: Canceler) => void
) {
  try {
    const response = await axios.get<{ user: User }>(
      `/api/v1/user/${uuid}/settings`,
      {
        cancelToken: executor ? new CancelToken(executor) : undefined,
      }
    );
    return response.data.user;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

const emoURI: string = process.env.REACT_APP_EMO_URI!;

export async function updateSAUserSettingsAPI(
  uuid: string,
  data: Partial<UserSettings>,
  executor?: (canceler: Canceler) => void
) {
  try {
    const response = await axios.put<{ user: User }>(
      `${emoURI}/api/v1/user/${uuid}/settings`,
      data,
      {
        cancelToken: executor ? new CancelToken(executor) : undefined,
        headers: { 'Content-Type': 'application/json' },
      }
    );
    return response.data.user;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getSAUserSettingsAPI(
  uuid: string,
  executor?: (canceler: Canceler) => void
) {
  try {
    const response = await axios.get<{ user: User }>(
      `${emoURI}/api/v1/user/${uuid}/settings`,
      {
        cancelToken: executor ? new CancelToken(executor) : undefined,
      }
    );
    return response.data.user;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
