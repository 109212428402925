import React from 'react';
import TopicAnimation from './topicAnimation';
import { useSnackbar } from 'notistack';
import Topic from '../topic/topic';

import {
  getTopicAnimationsListAPI,
  createTopicAnimationAPI,
  deleteTopicAnimationAPI,
} from './animationAPI';

interface TopicAnimationsState {
  isLoading: boolean;
  error: Error | null;
  data: TopicAnimation[];
}

type TopicAnimationsAction =
  | { type: 'request/getTopicAnimations' }
  | { type: 'success/getTopicAnimations'; payload: TopicAnimation[] }
  | { type: 'reject/getTopicAnimations'; payload: Error }
  | { type: 'request/createTopicAnimation' }
  | { type: 'success/createTopicAnimation'; payload: TopicAnimation }
  | { type: 'reject/createTopicAnimation'; payload: Error }
  | { type: 'request/deleteTopicAnimation' }
  | { type: 'success/deleteTopicAnimation'; payload: TopicAnimation }
  | { type: 'reject/deleteTopicAnimation'; payload: Error }
  | { type: 'request/updateTopicAnimation' }
  | { type: 'success/updateTopicAnimation'; payload: TopicAnimation }
  | { type: 'reject/updateTopicAnimation'; payload: Error };

function reducer(
  state: TopicAnimationsState,
  action: TopicAnimationsAction
): TopicAnimationsState {
  switch (action.type) {
    case 'request/getTopicAnimations':
    case 'request/createTopicAnimation':
    case 'request/deleteTopicAnimation':
    case 'request/updateTopicAnimation':
      return { ...state, isLoading: true };

    case 'success/getTopicAnimations':
      return { ...state, isLoading: false, data: action.payload };

    case 'success/createTopicAnimation':
    case 'success/updateTopicAnimation':
      return {
        ...state,
        isLoading: false,
        data: [...state.data, action.payload],
      };

    case 'success/deleteTopicAnimation':
      const index = state.data.findIndex(
        (animation) => animation.uuid === action.payload.uuid
      );
      if (index > -1) {
        return {
          ...state,
          isLoading: false,
          data: [],
        };
      }
      return { ...state, isLoading: false };

    case 'reject/getTopicAnimations':
    case 'reject/createTopicAnimation':
    case 'reject/deleteTopicAnimation':
    case 'reject/updateTopicAnimation':
      return { ...state, isLoading: false, error: action.payload };

    default:
      throw new Error('Action does not exists in TopicAnimationsSlice');
  }
}

export default function useTopicAnimationsSlice(topic?: Topic) {
  const [state, dispatch] = React.useReducer(reducer, {
    isLoading: false,
    error: null,
    data: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  const createTopicAnimation = React.useCallback(
    async (args: { file: File; topic_id: number }) => {
      try {
        dispatch({ type: 'request/createTopicAnimation' });
        const newAnimation = await createTopicAnimationAPI(args);
        dispatch({
          type: 'success/createTopicAnimation',
          payload: newAnimation,
        });
        enqueueSnackbar(`Animation UPLOADED`, { variant: 'success' });
      } catch (reason) {
        dispatch({
          type: 'reject/createTopicAnimation',
          payload: new Error(reason),
        });
        enqueueSnackbar(reason, { variant: 'error' });
      }
    },
    [dispatch, enqueueSnackbar]
  );

  const updateTopicAnimation = React.useCallback(
    async (uuid: string, args: { file: File; topic_id: number }) => {
      try {
        dispatch({ type: 'request/updateTopicAnimation' });
        await deleteTopicAnimationAPI(uuid);
        const newAnimation = await createTopicAnimationAPI(args);
        dispatch({
          type: 'success/updateTopicAnimation',
          payload: newAnimation,
        });
        enqueueSnackbar(`Animation UPLOADED`, { variant: 'success' });
      } catch (reason) {
        dispatch({
          type: 'reject/updateTopicAnimation',
          payload: new Error(reason),
        });
        enqueueSnackbar(reason, { variant: 'error' });
      }
    },
    [dispatch, enqueueSnackbar]
  );

  const deleteTopicAnimation = React.useCallback(
    async (uuid: string) => {
      try {
        dispatch({ type: 'request/deleteTopicAnimation' });
        const deletedAnimation = await deleteTopicAnimationAPI(uuid);
        dispatch({
          type: 'success/deleteTopicAnimation',
          payload: deletedAnimation,
        });
        enqueueSnackbar(`Animation DELETED`, { variant: 'success' });
      } catch (reason) {
        dispatch({
          type: 'reject/deleteTopicAnimation',
          payload: new Error(reason),
        });
        enqueueSnackbar(reason, { variant: 'error' });
      }
    },
    [dispatch, enqueueSnackbar]
  );

  const refresh = React.useCallback(async () => {
    try {
      dispatch({ type: 'request/getTopicAnimations' });
      const animations = await getTopicAnimationsListAPI(topic?.id);
      dispatch({ type: 'success/getTopicAnimations', payload: animations });
    } catch (reason) {
      const error = new Error(reason);
      dispatch({
        type: 'reject/getTopicAnimations',
        payload: error,
      });
      enqueueSnackbar(reason, { variant: 'error' });
      throw error;
    }
  }, [dispatch, enqueueSnackbar, topic]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    state,
    actions: {
      createTopicAnimation,
      updateTopicAnimation,
      deleteTopicAnimation,
      refresh
    },
  };
}
