import React from 'react';
import Cohort from './cohort';
import { useSnackbar } from 'notistack';
import {
  getCohortsListAPI,
  createCohortAPI,
  deleteCohortAPI,
  updateCohortAPI,
} from './cohortAPI';

export interface CohortsState {
  isLoading: boolean;
  error: Error | null;
  data: Cohort[];
}

type CohortsAction =
  | { type: 'request/getCohorts' }
  | { type: 'success/getCohorts'; payload: Cohort[] }
  | { type: 'reject/getCohorts'; payload: Error }
  | { type: 'request/createCohort' }
  | { type: 'success/createCohort'; payload: Cohort }
  | { type: 'reject/createCohort'; payload: Error }
  | { type: 'request/updateCohort' }
  | { type: 'success/updateCohort'; payload: Cohort }
  | { type: 'reject/updateCohort'; payload: Error }
  | { type: 'request/deleteCohort' }
  | { type: 'success/deleteCohort'; payload: Cohort }
  | { type: 'reject/deleteCohort'; payload: Error };

function reducer(state: CohortsState, action: CohortsAction): CohortsState {
  switch (action.type) {
    case 'request/getCohorts':
    case 'request/createCohort':
    case 'request/updateCohort':
    case 'request/deleteCohort':
      return { ...state, isLoading: true };

    case 'success/getCohorts':
      return { ...state, isLoading: false, data: action.payload };

    case 'success/createCohort':
      return {
        ...state,
        isLoading: false,
        data: [...state.data, action.payload],
      };

    case 'success/updateCohort':
    case 'success/deleteCohort':
      const index = state.data.findIndex(
        cohort => cohort.id === action.payload.id
      );
      if (index > -1) {
        return {
          ...state,
          isLoading: false,
          data:
            action.type === 'success/updateCohort'
              ? [
                  ...state.data.slice(0, index),
                  action.payload,
                  ...state.data.slice(index + 1),
                ]
              : [...state.data.slice(0, index), ...state.data.slice(index + 1)],
        };
      }
      return state;

    case 'reject/getCohorts':
    case 'reject/createCohort':
    case 'reject/updateCohort':
    case 'reject/deleteCohort':
      return { ...state, isLoading: false, error: action.payload };

    default:
      throw new Error('Action does not exists in CohortsSlice');
  }
}

export default function useCohortsSlice() {
  const [state, dispatch] = React.useReducer(reducer, {
    isLoading: false,
    error: null,
    data: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  const createCohort = React.useCallback(
    async (name: string) => {
      try {
        dispatch({ type: 'request/createCohort' });
        const newCohort = await createCohortAPI(name);
        dispatch({ type: 'success/createCohort', payload: newCohort });
        enqueueSnackbar(`Cohort CREATED`, { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        dispatch({ type: 'reject/createCohort', payload: error });
        enqueueSnackbar(reason, { variant: 'error' });
        throw error;
      }
    },
    [dispatch, enqueueSnackbar]
  );

  const updateCohort = React.useCallback(
    async (
      id: number,
      args: Partial<Pick<Cohort, 'name' | 'enabled_features'>>
    ) => {
      try {
        dispatch({ type: 'request/updateCohort' });
        const updatedCohort = await updateCohortAPI(id, args);
        dispatch({ type: 'success/updateCohort', payload: updatedCohort });
        enqueueSnackbar(`Cohort UPDATED`, { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        dispatch({ type: 'reject/updateCohort', payload: error });
        enqueueSnackbar(reason, { variant: 'error' });
        throw error;
      }
    },
    [dispatch, enqueueSnackbar]
  );

  const deleteCohort = React.useCallback(
    async (id: number) => {
      try {
        dispatch({ type: 'request/deleteCohort' });
        const deletedCohort = await deleteCohortAPI(id);
        dispatch({ type: 'success/deleteCohort', payload: deletedCohort });
        enqueueSnackbar(`Cohort DELETED`, { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        dispatch({ type: 'reject/deleteCohort', payload: error });
        enqueueSnackbar(reason, { variant: 'error' });
      }
    },
    [dispatch, enqueueSnackbar]
  );

  const refresh = React.useCallback(async () => {
    try {
      dispatch({ type: 'request/getCohorts' });
      const cohorts = await getCohortsListAPI();
      dispatch({ type: 'success/getCohorts', payload: cohorts });
    } catch (reason) {
      const error = new Error(reason);
      dispatch({ type: 'reject/getCohorts', payload: error });
      enqueueSnackbar(reason, { variant: 'error' });
      throw error;
    }
  }, [dispatch, enqueueSnackbar]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    state,
    actions: { createCohort, updateCohort, deleteCohort, refresh },
  };
}

export type UseCohortsSlice = ReturnType<typeof useCohortsSlice>;
