import { Box } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import MaterialTable from 'material-table';
import React from 'react';
import { useSelector } from 'react-redux';

import SectionHeading from '../../components/SectionHeading';
import tableIcons from '../../config/tableIcons';
import { selectRolesList } from '../role/roleSlice';
import useUsersSlice from '../user/useUsersSlice';
import InterestGroup from './interestGroup';

interface InterestGroupMembersTableProps {
  group: InterestGroup;
}

export default function InterestGroupMembersTable(
  props: InterestGroupMembersTableProps
) {
  const { group } = props;

  const usersSlice = useUsersSlice({ interest_group_id: group.id }, false);
  const { data: members, isLoading } = usersSlice.state;
  const { refresh } = usersSlice.actions;

  const roles = useSelector(selectRolesList);

  return (
    <MaterialTable
      title={<SectionHeading heading="Group Members" />}
      isLoading={isLoading}
      icons={tableIcons}
      components={{ Container: Box }}
      columns={[
        {
          title: 'Study ID',
          field: 'studyID',
          filtering: false,
        },
        { title: 'Display Name', field: 'displayName', filtering: false },
        {
          title: 'Role',
          field: 'role',
          lookup: roles.reduce<{ [x: string]: string }>((accum, curr) => {
            accum[curr.name] = curr.name;
            return accum;
          }, {}),
        },
      ]}
      data={members.map(member => ({
        studyID: member.study_id,
        displayName: Boolean(member.settings.display_name)
          ? member.settings.display_name
          : 'n/a',
        role: member.roles[0] === '' ? 'n/a' : member.roles[0],
      }))}
      actions={[
        {
          icon: RefreshIcon,
          tooltip: 'Refresh Data',
          isFreeAction: true,
          onClick: () => refresh(),
        },
      ]}
      options={{
        filtering: true,
        exportButton: true,
        exportAllData: true,
        exportFileName: `${group.name}_group_members`,
      }}
    />
  );
}
