import { axios } from '../../config/axiosConfig';
import { Gains } from './gains';

const emoURI: string = process.env.REACT_APP_EMO_URI!;

export default async function GainsAPI(params: { user_uuid: string }) {
  try {
    const response = await axios.get<{
      gains: Gains;
    }>(`${emoURI}/api/v1/gains`, {
      params,
    });
    return response.data.gains;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
