import { axios } from '../../config/axiosConfig';
import Topic from './topic';

export async function createTopicAPI(
  name: string,
  handout_url?: string,
  quiz_url?: string
) {
  try {
    const data = new FormData();
    data.append('name', name);
    if (handout_url) {
      data.append('handout_url', handout_url);
    }
    if (quiz_url) {
      data.append('quiz_url', quiz_url);
    }

    const response = await axios.post<{ topic: Topic }>(
      '/api/v1/topic/create',
      data,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    return response.data.topic;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function updateTopicAPI(
  id: number,
  newName: string,
  handout_url: string,
  quiz_url: string,
) {
  try {
    const data = new FormData();
    data.append('name', newName);
    data.append('handout_url', handout_url);
    data.append('quiz_url', quiz_url);
    const response = await axios.put<{ topic: Topic }>(
      `/api/v1/topic/${id}`,
      data,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    return response.data.topic;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getTopicAPI(id: number) {
  try {
    const response = await axios.get<{ topic: Topic }>(`/api/v1/${id}`);
    return response.data.topic;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getTopicsListAPI() {
  try {
    const response = await axios.get<{ topics: Array<Topic> }>(
      '/api/v1/topics'
    );
    return response.data.topics;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function deleteTopicAPI(id: number) {
  try {
    const response = await axios.delete<{ topic: Topic }>(
      `/api/v1/topic/${id}/delete`
    );
    return response.data.topic;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
