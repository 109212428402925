import { useSnackbar } from 'notistack';
import React from 'react';

import { PrepareForATrigger, TriggerIncident } from './trigger';
import {
  getTriggerIncidentsListAPI,
  getTriggerPrepEventsListAPI,
} from './triggerAPI';

interface TriggerIncedentsState {
  isLoading: boolean;
  error: Error | null;
  data: TriggerIncident[];
}
interface PrepareForTriggersState {
  isLoading: boolean;
  error: Error | null;
  data: PrepareForATrigger[];
}

export function useTriggerEventsSlice(user_uuid: string) {
  const [state, setState] = React.useState<PrepareForTriggersState>({
    isLoading: false,
    error: null,
    data: [
      {
        app_action: '',
        id: 0,
        scheduled_event: {},
        user_uuid: '',
      },
    ],
  });

  const { enqueueSnackbar } = useSnackbar();

  const refresh = React.useCallback(async () => {
    try {
      setState(prev => ({ ...prev, isLoading: true }));
      const triggersEvents = await getTriggerPrepEventsListAPI({ user_uuid });
      setState(prev => ({ ...prev, isLoading: false, data: triggersEvents }));
    } catch (reason) {
      const error = new Error(reason);
      setState(prev => ({ ...prev, isLoading: false, error }));
      enqueueSnackbar(reason, { variant: 'error' });
    }
  }, [enqueueSnackbar, user_uuid]);

  React.useEffect(() => {
    refresh();
  }, [refresh, user_uuid]);
  return { state, actions: { refresh } };
}

export function useTriggerIncidentsSlice(user_uuid: string) {
  const [state, setState] = React.useState<TriggerIncedentsState>({
    isLoading: false,
    error: null,
    data: [
      {
        created: '',
        id: 0,
        location: {
          long: 0,
          lat: 0,
        },
        severity: 0,
        tags: [''],
        user_uuid: '',
      },
    ],
  });

  const { enqueueSnackbar } = useSnackbar();

  const refresh = React.useCallback(async () => {
    try {
      setState(prev => ({ ...prev, isLoading: true }));
      const triggersIncidents = await getTriggerIncidentsListAPI({ user_uuid });
      setState(prev => ({
        ...prev,
        isLoading: false,
        data: triggersIncidents,
      }));
    } catch (reason) {
      const error = new Error(reason);
      setState(prev => ({ ...prev, isLoading: false, error }));
      enqueueSnackbar(reason, { variant: 'error' });
    }
  }, [enqueueSnackbar, user_uuid]);

  React.useEffect(() => {
    refresh();
  }, [refresh, user_uuid]);
  return { state, actions: { refresh } };
}
