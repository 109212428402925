import { Box } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import MaterialTable from 'material-table';
import React from 'react';

import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import tableIcons from '../../config/tableIcons';
import { useTriggerIncidentsSlice } from './useTriggerSlice';

export default function TriggerIncidentTable(props: any) {
  const userUUID = props.userUUID;

  const triggersIncidentsSlice = useTriggerIncidentsSlice(userUUID);
  const { data, isLoading } = triggersIncidentsSlice.state;
  // Create Incident data for Table
  const createData = (
    date: string,
    severity: number | null,
    tags: string[] | null
  ) => {
    return { date, severity, tags };
  };
  // Converting Incident data to Rows
  const createTable = () => {
    let rows: any = [];
    if (data) {
      data.forEach(incident => {
        rows.push(
          createData(incident.created, incident.severity, incident.tags)
        );
      });
    }
    return rows;
  };

  return (
    <div>
      {data ? (
        <Section variant="outlined">
          <MaterialTable
            title={<SectionHeading heading="Trigger Incident" />}
            isLoading={isLoading}
            components={{ Container: Box }}
            icons={tableIcons}
            columns={[
              {
                title: 'Date',
                field: 'date',
                render: rowData => new Date(rowData.date).toLocaleString(),
              },

              {
                title: 'Severity Level',
                field: 'severity',
              },

              {
                title: 'Tags',
                field: 'tags',
                render: rowData => {
                  return rowData.tags.map((tag: any, i: any) => (
                    <p key={i}>{tag}</p>
                  ));
                },
              },
            ]}
            data={createTable()}
            actions={[
              {
                icon: RefreshIcon,
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => triggersIncidentsSlice.actions.refresh(),
              },
            ]}
            options={{
              exportButton: true,
              exportAllData: true,
              exportFileName: 'Trigger incident table',
              search: false,
              emptyRowsWhenPaging: false,
              pageSize: 20,
            }}
          />
        </Section>
      ) : null}
    </div>
  );
}
