import { axios } from '../../config/axiosConfig';
import { PrepareForATrigger, TriggerIncident } from './trigger';

export async function getTriggerPrepEventsListAPI(params?: {
  user_uuid?: string;
}) {
  try {
    const response = await axios.get<{
      trigger_prep_events: PrepareForATrigger[];
    }>(`/api/v1/trigger_prep_events`, { params });
    return response.data.trigger_prep_events;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getTriggerIncidentsListAPI(params?: {
  user_uuid?: string;
}) {
  try {
    const response = await axios.get<{
      trigger_incidents: TriggerIncident[];
    }>(`/api/v1/trigger_incidents`, { params });
    return response.data.trigger_incidents;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
