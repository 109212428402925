import { axios } from '../../config/axiosConfig';
import { Progress } from './progress';

export async function progressAPI(params: { user_uuid: string }) {
  try {
    const response = await axios.get<{
      progress: Progress;
    }>('/api/v1/progress', {
      params,
    });
    return response.data.progress;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
