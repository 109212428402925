import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { AppDispatch, AppState } from '../../config/storeConfig';
import { RequestState } from '../../requestState';
import Topic from './topic';
import {
  createTopicAPI,
  getTopicAPI,
  getTopicsListAPI,
  updateTopicAPI,
} from './topicAPI';

export interface TopicState extends RequestState {
  data: Array<Topic>;
}

const initTopicState: TopicState = {
  loading: false,
  errorMsg: null,
  data: [],
};

export const createTopic = createAsyncThunk<
  Topic,
  { name: string; handout_url?: string; quiz_url?: string },
  { dispatch: AppDispatch; state: AppState; rejectValue: string }
>('topic/createTopic', async (args, { rejectWithValue }) => {
  try {
    return await createTopicAPI(args.name, args.handout_url, args.quiz_url);
  } catch (reason) {
    return rejectWithValue(reason);
  }
});

export const updateTopic = createAsyncThunk<
  Topic,
  { id: number; name: string; handout_url: string; quiz_url: string },
  { dispatch: AppDispatch; state: AppState; rejectValue: string }
>('topic/updateTopic', async (args, { rejectWithValue }) => {
  try {
    // return await getTopicAPI(id);
    return await updateTopicAPI(
      args.id,
      args.name,
      args.handout_url,
      args.quiz_url
    );
  } catch (reason) {
    return rejectWithValue(reason);
  }
});

export const getTopic = createAsyncThunk<
  Topic,
  number,
  { dispatch: AppDispatch; state: AppState; rejectValue: string }
>('topic/getTopic', async (id, { rejectWithValue }) => {
  try {
    return await getTopicAPI(id);
  } catch (reason) {
    return rejectWithValue(reason);
  }
});

export const getTopicsList = createAsyncThunk<
  Array<Topic>,
  undefined,
  { dispatch: AppDispatch; state: AppState; rejectValue: string }
>('topic/getTopicsList', async (args, { rejectWithValue }) => {
  try {
    return await getTopicsListAPI();
  } catch (reason) {
    return rejectWithValue(reason);
  }
});

const topicSlice = createSlice({
  name: 'topic',
  initialState: initTopicState,
  reducers: {
    // setCohort: (state, action: PayloadAction<Cohort | null>) => {
    //   state.selected.data = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTopic.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createTopic.fulfilled, (state, action) => {
        state.loading = false;
        if (state.data === null) {
          state.data = new Array<Topic>();
        }
        state.data.push(action.payload);
      })
      .addCase(createTopic.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload ?? 'An error has occured.';
      })
      .addCase(updateTopic.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateTopic.fulfilled, (state, action) => {
        state.loading = false;
        const i = state.data.findIndex(
          (topic) => topic.id === action.payload.id
        );
        if (i) {
          state.data = [
            ...state.data.slice(0, i),
            action.payload,
            ...state.data.slice(i + 1),
          ];
        }
      })
      .addCase(updateTopic.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload ?? 'An error has occured.';
      })
      .addCase(getTopic.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getTopic.fulfilled, (state, action) => {
        state.loading = false;
        // state.data = action.payload;
        if (state.data) {
          const i = state.data.findIndex(
            (topic) => topic.id === action.payload.id
          );
          if (i) state.data[i] = action.payload;
        }
      })
      .addCase(getTopic.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload ?? 'An error has occured.';
      })
      .addCase(getTopicsList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getTopicsList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getTopicsList.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload ?? 'An error has occured.';
      });
  },
});

export const selectTopicsList = createSelector(
  (state: AppState) => state.topics.data,
  (list) => list
);

export const selectTopicsMap = createSelector(
  (state: AppState) => state.topics.data,
  (list) => {
    if (list.length > 0) {
      return list.reduce<{ [id: number]: Topic }>((accum, curr) => {
        accum[curr.id] = curr;
        return accum;
      }, {});
    }
    return null;
  }
);

const asyncActions = {
  createTopic,
  updateTopic,
  getTopic,
  getTopicsList,
};

export const topicActions = { ...topicSlice.actions, ...asyncActions };

// export const selectTopic = createSelector((state: AppState) => state)
export default topicSlice.reducer;
