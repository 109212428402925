import { Box } from '@material-ui/core';
import React from 'react';

import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import SelectionSearch from '../../containers/SelectionSearch';
import InterestGroup from './interestGroup';
import InterestGroupForm, { InterestGroupFormData } from './InterestGroupForm';
import InterestGroupMembersTable from './InterestGroupMembersTable';
import { UseInterestGroupsSlice } from './useInterestGroupsSlice';

interface EditInterestGroupProps {
  slice: UseInterestGroupsSlice;
}

export default function EditInterestGroup(props: EditInterestGroupProps) {
  const { slice } = props;

  const [
    selectedGroup,
    setSelectedGroup,
  ] = React.useState<InterestGroup | null>(null);

  const { data: interestGroupList } = slice.state;
  const actions = slice.actions;

  const handleSelection = async (group: string | InterestGroup | null) => {
    if (typeof group !== 'string') setSelectedGroup(group);
  };

  const handleInterestGroupDelete = async (group: InterestGroup) => {
    //! Logic is a little bad here... selectedGroup should equal group, which it does...
    if (selectedGroup) {
      await actions.deleteInterestGroup(selectedGroup.id);
      setSelectedGroup(null);
    }
  };

  const handleSubmit = (data: InterestGroupFormData) => {
    if (selectedGroup) {
      actions.updateInterestGroup({
        name: data.name,
        description: data.description,
        id: selectedGroup.id,
      });
    }
  };

  return (
    <Section variant="outlined">
      <SectionHeading heading="Select an Interest Group to Update" />
      <Box width="100%" my={2}>
        {interestGroupList && (
          <SelectionSearch
            label="Select Interest Group"
            options={interestGroupList}
            getOptionLabel={option => option.name}
            onSelect={handleSelection}
          />
        )}
      </Box>
      {selectedGroup && (
        <React.Fragment>
          <Section variant="outlined">
            <SectionHeading heading="Edit Interest Group Info" />
            <InterestGroupForm
              group={selectedGroup}
              onSubmit={handleSubmit}
              onDeleteRequest={handleInterestGroupDelete}
            />
          </Section>
          <Section variant="outlined">
            <InterestGroupMembersTable group={selectedGroup} />
          </Section>
        </React.Fragment>
      )}
    </Section>
  );
}
