import React from 'react';

import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import InterestGroupForm, { InterestGroupFormData } from './InterestGroupForm';
import { UseInterestGroupsSlice } from './useInterestGroupsSlice';

interface CreateInterestGroupProps extends Omit<UseInterestGroupsSlice, 'state'> {};

export default function CreateInterestGroup(props: CreateInterestGroupProps) {

  const {actions} = props;
  const handleSubmit = (data: InterestGroupFormData) => {
    actions.createInterestGroup({
      name: data.name,
      description: data.description,
    })
  };
  return (
    <Section variant="outlined">
      <SectionHeading heading="Create Interest Group"/>
      <InterestGroupForm onSubmit={handleSubmit} />
    </Section>
  );
}
