import { Grid, Typography } from '@material-ui/core';
import React from 'react';

import Section from '../../components/Section';
import UserAvatar from '../avatar/UserAvatar';
import User from './user';

export default function UserDetails(props: { user: User; app: string }) {
  const { user, app } = props;

  return (
    <Section variant="outlined">
      <Grid container spacing={4} justify="space-between">
        <Grid item xs={12} md={4}>
          <UserAvatar user={user} sa={app === 'sa'} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid
            container
            justify="space-between"
            spacing={4}
            style={{ width: '100%' }}
          >
            <Grid item xs={12} sm={6} lg={4}>
              <Typography variant="h6">First Name</Typography>
              <Typography variant="body1">
                {user.first_name ?? 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Typography variant="h6">Last Name</Typography>
              <Typography variant="body1">{user.last_name ?? 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Typography variant="h6">Public Display Name</Typography>
              <Typography variant="body1">
                {user.settings.display_name}
              </Typography>
            </Grid>
            {user.roles.length > 0 && (
              <Grid item xs={12} sm={6} lg={4}>
                <Typography variant="h6">Role</Typography>
                <Typography variant="body1">{user.roles[0]}</Typography>
              </Grid>
            )}
            {app === 'ss' && (
              <Grid item xs={12} sm={6} lg={4}>
                <Typography variant="h6">Cohort</Typography>
                <Typography variant="body1">
                  {user.cohort?.name ?? 'n/a'}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} sm={6} lg={4}>
              <Typography variant="h6">Status</Typography>
              <Typography variant="body1">
                {user.is_active ? 'Active' : 'Inactive'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Typography variant="h6">Onboarded</Typography>
              <Typography variant="body1">
                {user.settings.is_onboarded ? 'Complete' : 'InComplete'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Section>
  );
}
