import { axios } from '../../config/axiosConfig';

const emoURI: string = process.env.REACT_APP_EMO_URI!;

export interface DataDumpQuery {
  user_uuid?: string;
  start?: string;
  end?: string;
}

export async function getDataDumpAPI(query?: DataDumpQuery, sa?: boolean) {
  try {
    const baseURI = sa ? emoURI : '';

    const response = await axios.get<Blob>(`${baseURI}/api/v1/users/excel`, {
      params: query,
      responseType: 'blob',
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
