import { useSnackbar } from 'notistack';
import React from 'react';

import User from './user';
import {
  createSAUserAPI,
  CreateSAUserArgs,
  createSSUserAPI,
  CreateSSUserArgs,
  deactivateSAUserAPI,
  deactivateSSUserAPI,
  getSAUsersListAPI,
  getSSUsersListAPI,
  updateSAUserAPI,
  UpdateSAUserArgs,
  updateSSUserAPI,
  UpdateSSUserArgs,
} from './userAPI';

export interface UsersState {
  isLoading: boolean;
  error: Error | null;
  data: User[];
}

type UsersAction =
  | {
      type: 'request/getUsers';
    }
  | { type: 'success/getUsers'; payload: User[] }
  | { type: 'reject/getUsers'; payload: Error }
  | {
      type: 'request/createUser';
    }
  | { type: 'success/createUser'; payload: User }
  | { type: 'reject/createUser'; payload: Error }
  | {
      type: 'request/updateUser';
    }
  | { type: 'success/updateUser'; payload: User }
  | { type: 'reject/updateUser'; payload: Error }
  | {
      type: 'request/deleteUser';
    }
  | { type: 'success/deleteUser'; payload: User }
  | { type: 'reject/deleteUser'; payload: Error };

function reducer(state: UsersState, action: UsersAction): UsersState {
  switch (action.type) {
    case 'request/getUsers':
    case 'request/createUser':
    case 'request/updateUser':
    case 'request/deleteUser':
      return { ...state, isLoading: true };
    case 'success/getUsers':
      return { ...state, isLoading: false, data: action.payload };
    case 'success/createUser':
      return {
        ...state,
        isLoading: false,
        data: [...state.data, action.payload],
      };
    case 'success/updateUser':
      const updateIndex = state.data.findIndex(
        user => user.uuid === action.payload.uuid
      );
      if (updateIndex > -1) {
        return {
          ...state,
          isLoading: false,
          data: [
            ...state.data.slice(0, updateIndex),
            action.payload,
            ...state.data.slice(updateIndex + 1),
          ],
        };
      }
      return state;
    case 'success/deleteUser':
      const deleteIndex = state.data.findIndex(
        user => user.uuid === action.payload.uuid
      );
      if (deleteIndex > -1) {
        return {
          ...state,
          isLoading: false,
          data: [
            ...state.data.slice(0, deleteIndex),
            ...state.data.slice(deleteIndex + 1),
          ],
        };
      }
      return state;
    case 'reject/getUsers':
    case 'reject/createUser':
    case 'reject/updateUser':
    case 'reject/deleteUser':
      return { ...state, isLoading: false, error: action.payload };
    default:
      throw new Error();
  }
}

export default function useUsersSlice(
  filter: {
    role?: string;
    cohort_id?: number;
    interest_group_id?: number;
    registered?: boolean;
  },
  sa?: boolean
) {
  const [state, dispatch] = React.useReducer(reducer, {
    isLoading: false,
    error: null,
    data: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  const { role, cohort_id, interest_group_id, registered } = filter;

  const createUser = React.useCallback(
    async (args: CreateSSUserArgs | CreateSAUserArgs) => {
      try {
        dispatch({ type: 'request/createUser' });

        const newUser = sa
          ? await createSAUserAPI(args)
          : await createSSUserAPI(args);
        dispatch({ type: 'success/createUser', payload: newUser.user });
        enqueueSnackbar(`User ${newUser.user.study_id} CREATED`, {
          variant: 'success',
        });
        return newUser;
      } catch (reason) {
        const error = new Error(reason);
        dispatch({ type: 'reject/createUser', payload: error });
        enqueueSnackbar(reason, { variant: 'error' });
        throw error;
      }
    },
    [dispatch, enqueueSnackbar, sa]
  );

  const updateUser = React.useCallback(
    async (uuid: string, args: UpdateSSUserArgs | UpdateSAUserArgs) => {
      try {
        dispatch({ type: 'request/updateUser' });
        // enqueueSnackbar('Processing...');
        const updatedUser = sa
          ? await updateSAUserAPI(uuid, args)
          : await updateSSUserAPI(uuid, args);
        dispatch({ type: 'success/updateUser', payload: updatedUser });
        enqueueSnackbar(`User ${updatedUser.study_id} UPDATED`, {
          variant: 'success',
        });
      } catch (reason) {
        const error = new Error(reason);
        dispatch({ type: 'reject/updateUser', payload: error });
        enqueueSnackbar(reason, { variant: 'error' });
        throw error;
      }
    },
    [dispatch, enqueueSnackbar, sa]
  );

  const deleteUser = React.useCallback(
    async (uuid: string) => {
      try {
        dispatch({ type: 'request/deleteUser' });
        // enqueueSnackbar('Processing...');
        const deletedUser = sa
          ? await deactivateSAUserAPI(uuid)
          : await deactivateSSUserAPI(uuid);
        dispatch({ type: 'success/deleteUser', payload: deletedUser });
        enqueueSnackbar(`User ${deletedUser.study_id} DELETED`, {
          variant: 'success',
        });
      } catch (reason) {
        const error = new Error(reason);
        dispatch({ type: 'reject/deleteUser', payload: error });
        enqueueSnackbar(reason, { variant: 'error' });
        throw error;
      }
    },
    [dispatch, enqueueSnackbar, sa]
  );

  const refresh = React.useCallback(async () => {
    try {
      dispatch({ type: 'request/getUsers' });
      let users: User[] = [];
      if (sa === true) {
        users = await getSAUsersListAPI({
          role,
          interest_group_id,
          cohort_id,
          registered,
        });
      } else if (sa === false) {
        users = await getSSUsersListAPI({
          role,
          interest_group_id,
          cohort_id,
          registered,
        });
      }

      dispatch({ type: 'success/getUsers', payload: users });
    } catch (reason) {
      const error = new Error(reason);
      dispatch({ type: 'reject/getUsers', payload: error });
      enqueueSnackbar(reason, { variant: 'error' });
      throw error;
    }
  }, [
    dispatch,
    role,
    interest_group_id,
    cohort_id,
    enqueueSnackbar,
    sa,
    registered,
  ]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return { state, actions: { createUser, updateUser, deleteUser, refresh } };
}

export type UseUsersSlice = ReturnType<typeof useUsersSlice>;
