import React from 'react';
import { SafetyCheck } from './safetyCheck';
import { useSnackbar } from 'notistack';
import { getSafetyChecksListAPI } from './safetyCheckAPI';

interface SafetyChecksState {
  isLoading: boolean;
  error: Error | null;
  data: SafetyCheck[];
}

export default function useSafetyChecksSlice(filter?: {
  user_uuid?: string;
  cohort_id?: number;
}) {
  const [state, setState] = React.useState<SafetyChecksState>({
    isLoading: false,
    error: null,
    data: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  const refresh = React.useCallback(async () => {
    try {
      setState(prev => ({ ...prev, isLoading: true }));
      const checkIns = await getSafetyChecksListAPI(filter);
      setState(prev => ({ ...prev, isLoading: false, data: checkIns }));
    } catch (reason) {
      const error = new Error(reason);
      setState(prev => ({ ...prev, isLoading: false, error }));
      enqueueSnackbar(reason, { variant: 'error' });
    }
  }, [filter, setState, enqueueSnackbar]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return { state, actions: { refresh } };
}
