import { Box, Collapse, IconButton, Tooltip } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React from 'react';

import Section from './Section';

// import {TypographyProps} from '@material-ui/core/Typography'

interface CollapsePanelProps {
  children?: React.ReactNode;
  heading: React.ReactNode;
  footer?: React.ReactNode;
  initiallyCollapsed?: boolean;
  style?: React.CSSProperties;
  customActions?: {
    icon?: React.ReactNode;
    title: string;
    execute: Function;
  }[];
}

export default function CollapsableSection(props: CollapsePanelProps) {
  const {
    children,
    heading,
    footer,
    initiallyCollapsed,
    style,
    customActions,
  } = props;

  const [open, setOpen] = React.useState(initiallyCollapsed);

  const handleCollapse = () => {
    setOpen(!open);
  };

  return (
    <Section variant="outlined" style={style}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {/* <Typography variant={variant ? variant : 'h5'}>{heading}</Typography> */}
        {heading}
        <Box>
          {customActions?.map((action, index) => (
            <Tooltip key={index} title={action.title}>
              <IconButton
                aria-label="collapse"
                onClick={() => action.execute()}
              >
                {action.icon}
              </IconButton>
            </Tooltip>
          ))}
          <IconButton aria-label="collapse" onClick={handleCollapse}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
      </Box>
      <Collapse in={open} timeout="auto">
        {children}
      </Collapse>
      {footer}
    </Section>
  );
}
