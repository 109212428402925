import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  List,
  ListItem,
  makeStyles,
  TextField,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';

import { AppContext } from '../../AppContext';
import CollapsableSection from '../../components/CollapsableSection';
import ContentContainer from '../../components/ContentContainer';
import RouteTitle from '../../components/RouteTitle';
import Section from '../../components/Section';
import { updateCheckInPromptsAPI } from './checkInAPI';
import CheckInPrompt from './checkInPrompt';
import useCheckInPromptsSlice from './useCheckInPromptsSlice';

const useStyles = makeStyles(theme =>
  createStyles({
    promptActions: {
      margin: theme.spacing(1, 0),
      '& button': {
        margin: theme.spacing(0, 0.5),
        // display: 'none'
      },
    },
  })
);

interface CheckInPromptFormData {
  prompts: Partial<CheckInPrompt>[];
}

export default function CheckInPrompts() {
  // const [seletedType, setSelectedType] = React.useState('video');

  const checkInPromptsSlice = useCheckInPromptsSlice('video');
  const { data: prompts, isLoading } = checkInPromptsSlice.state;

  const { enqueueSnackbar } = useSnackbar();

  const { app } = React.useContext(AppContext);

  const classes = useStyles();

  const {
    register,
    handleSubmit,
    /* errors, */ control,
    reset,
  } = useForm<CheckInPromptFormData>({
    defaultValues: { prompts: [{ prompt: '' }] },
  });

  const {
    fields: formPrompts,
    append,
    // prepend,
    remove,
    // swap,
    move,
    // insert,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'prompts', // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
    keyName: 'fieldID',
  });

  // const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   setSelectedType(event.target.value as string);
  // };

  const handleAppendNewPrompt = React.useCallback(() => {
    append({ prompt: '', type: 'video' });
  }, [append, /* seletedType */]);

  const handleDeletePrompt = React.useCallback(
    (index: number) => () => {
      remove(index);
    },
    [remove]
  );

  const handleMove = React.useCallback(
    (from: number, to: number) => () => {
      move(from, to);
    },
    [move]
  );

  const _onSubmit = handleSubmit(async formData => {
    try {
      //* not using action because it causes undersireable UI render
      await updateCheckInPromptsAPI('video', {
        check_in_prompts: formData.prompts,
      });
      enqueueSnackbar('Check-in Prompts UPDATED', { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, { variant: 'error' });
    }
  });

  React.useEffect(() => {
    reset({ prompts: prompts });
  }, [prompts, reset]);

  if (app !== 'sa') {
    return <Redirect to="/" />;
  }

  return (
    <ContentContainer maxWidth="lg">
      <RouteTitle title="Check-in Prompts" />
      <Section variant="outlined">
        {/* <SectionHeading heading="Select Prompt Type" />
        <FormControl
          variant="filled"
          fullWidth
          margin="normal"
          // error={Boolean(errors.role)}
        >
          <InputLabel id="select-type-label">Check-in Prompt Type</InputLabel>
          <Select
            labelId="select-type-label"
            id="select-type"
            value={seletedType}
            onChange={handleChange}
          >
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="video">Video</MenuItem>
          </Select>
        </FormControl> */}

        {/* {seletedType !== '' && ( */}
        <form onSubmit={_onSubmit}>
          <List aria-label="prompts">
            {!isLoading ? (
              formPrompts.map((fP, index) => {
                return (
                  <ListItem key={fP.fieldID}>
                    <CollapsableSection
                      heading={`Prompt No. ${index + 1}`}
                      initiallyCollapsed
                      style={{
                        width: '100%',
                        margin: '4px 0',
                        padding: '4px 16px',
                      }}
                    >
                      <TextField
                        fullWidth
                        disabled
                        size="small"
                        margin="normal"
                        label="ID"
                        variant="filled"
                        name={`prompts[${index}].id`}
                        inputRef={register()}
                        defaultValue={fP.id}
                        style={{ display: 'none' }}
                      />
                      <TextField
                        fullWidth
                        disabled
                        size="small"
                        margin="normal"
                        label="Type"
                        variant="filled"
                        name={`prompts[${index}].type`}
                        inputRef={register()}
                        defaultValue={fP.type}
                        style={{ display: 'none' }}
                      />
                      <TextField
                        fullWidth
                        size="small"
                        margin="normal"
                        label="Prompt Message"
                        multiline
                        variant="filled"
                        name={`prompts[${index}].prompt`}
                        inputRef={register()}
                        defaultValue={fP.prompt}
                      />
                      <Box className={classes.promptActions}>
                        <Button
                          variant="contained"
                          color="primary"
                          aria-label="move up"
                          disableElevation
                          disabled={index === 0}
                          onClick={handleMove(index, index - 1)}
                        >
                          <ArrowUpwardIcon />
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          aria-label="move down"
                          disabled={index === formPrompts.length - 1}
                          disableElevation
                          onClick={handleMove(index, index + 1)}
                        >
                          <ArrowDownwardIcon />
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          aria-label="delete"
                          disableElevation
                          onClick={handleDeletePrompt(index)}
                        >
                          <DeleteIcon />
                        </Button>
                      </Box>
                    </CollapsableSection>
                  </ListItem>
                );
              })
            ) : (
              <Box textAlign="center" width="100%" p={2}>
                <CircularProgress color="primary" size={60} thickness={2} />
              </Box>
            )}
          </List>
          <Box>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleAppendNewPrompt}
            >
              Add Prompt
            </Button>
          </Box>
          <Box my={1}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disableElevation
            >
              Save
            </Button>
          </Box>
        </form>
        {/* )} */}
      </Section>
    </ContentContainer>
  );
}
