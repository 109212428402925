import { axios } from '../../config/axiosConfig';
import TopicAnimation from './topicAnimation';

export async function createTopicAnimationAPI(reqArgs: {
  file: File;
  topic_id: number;
}) {
  try {
    const data = new FormData();
    data.append('animation', reqArgs.file);
    data.append('topic_id', reqArgs.topic_id.toString());
    const response = await axios.post<{
      animation: TopicAnimation;
    }>('/api/v1/animation/create', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data.animation;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function deleteTopicAnimationAPI(uuid: string) {
  try {
    const response = await axios.delete<{
      animation: TopicAnimation;
    }>(`/api/v1/animation/${uuid}/delete`);
    return response.data.animation;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getTopicAnimationURLAPI(uuid: string) {
  try {
    const response = await axios.get<{
      animation: TopicAnimation;
    }>('/api/v1/animation');
    return response.data.animation;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getTopicAnimationsListAPI(topic_id?: number) {
  try {
    const response = await axios.get<{
      animations: Array<TopicAnimation>;
    }>(`/api/v1/animations`, { params: { topic_id } });
    return response.data.animations;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
