import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
} from '@material-ui/core';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import SelectionSearch from '../../containers/SelectionSearch';
import { selectAuthUser } from '../auth/authSlice';
import ZoomCallSetup from '../meeting/ZoomCallSetup';
import { selectTopicsList } from '../topic/topicSlice';
import Cohort from './cohort';
import CohortMembersTable from './CohortMembersTable';
import SyllabusTable from './SyllabusTable';
import { UseCohortsSlice } from './useCohortsSlice';
import RatingsTable from '../ratings/ratingsTable';

interface EditCohortProps {
  cohortsSlice: UseCohortsSlice;
}

interface EnableFeaturesFormData {
  safe_unsafe_picture: boolean;
  safety_boomerang: boolean;
  safety_plan: boolean;
  safety_surprise: boolean;
  talk_to_safe_self: boolean;
  tree_butterflies: boolean;
  tree_flowers: boolean;
  tree_fruit: boolean;
  tree_leaves: boolean;
  tree_lantern: boolean;
}

export default function EditCohort(props: EditCohortProps) {
  const { cohortsSlice } = props;
  const { data: cohorts } = cohortsSlice.state;
  const actions = cohortsSlice.actions;

  const [selected, setSelected] = React.useState<Cohort | null>(null);

  const topics = useSelector(selectTopicsList);

  const authUser = useSelector(selectAuthUser);

  const isLeader = authUser?.roles[0] === 'leader';

  const {
    /* register, */ handleSubmit,
    /* errors, */ control,
    reset,
  } = useForm<EnableFeaturesFormData>();

  const _onSubmit = handleSubmit(async formData => {
    if (!selected) return;
    try {
      actions.updateCohort(selected.id, {
        enabled_features: Object.entries(formData)
          .filter(entry => entry[1] === true)
          .map(entry => entry[0]),
      });
    } catch (error) {
      console.error(error);
    }
  });

  const handleSelection = async (cohort: string | Cohort | null) => {
    if (typeof cohort !== 'string') {
      setSelected(cohort);
    }
  };

  const handleCohortDelete = (cohort: Cohort) => async () => {
    try {
      await actions.deleteCohort(cohort.id);
      setSelected(null);
    } catch (reason) {
      console.error(reason);
    }
  };

  React.useEffect(() => {
    if (isLeader) {
      setSelected(authUser?.cohort ?? null);
    }
  }, [isLeader, authUser]);

  React.useEffect(() => {
    if (selected?.enabled_features) {
      reset(
        selected.enabled_features.reduce<EnableFeaturesFormData>(
          (accum, curr) => {
            accum[curr as keyof EnableFeaturesFormData] = true;
            return accum;
          },
          {
            safety_boomerang: false,
            safe_unsafe_picture: false,
            safety_plan: false,
            safety_surprise: false,
            talk_to_safe_self: false,
            tree_butterflies: false,
            tree_flowers: false,
            tree_fruit: false,
            tree_leaves: false,
            tree_lantern: false,
          }
        )
      );
    }
  }, [selected, reset]);

  return (
    <Section variant="outlined">
      {!isLeader && (
        <>
          <SectionHeading heading="Select a Cohort to Update" />
          <Box width="100%" my={2}>
            <SelectionSearch
              label="Search Cohort"
              options={cohorts}
              getOptionLabel={option => option.name}
              onSelect={handleSelection}
            />
          </Box>
        </>
      )}
      {selected && (
        <React.Fragment>
          <Box my={2} width="100%">
            <form onSubmit={_onSubmit}>
              <FormControl component="fieldset">
                <SectionHeading heading="Enable Safety Category Features" />
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Controller
                        name="safety_boomerang"
                        control={control}
                        defaultValue={false}
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <Checkbox
                            name={name}
                            onBlur={onBlur}
                            onChange={e => onChange(e.target.checked)}
                            checked={value}
                            inputRef={ref}
                          />
                        )}
                      />
                    }
                    label="Safety Boomerang"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Controller
                        // as={Checkbox}
                        name="safety_surprise"
                        control={control}
                        defaultValue={false}
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <Checkbox
                            name={name}
                            onBlur={onBlur}
                            onChange={e => onChange(e.target.checked)}
                            checked={value}
                            inputRef={ref}
                          />
                        )}
                      />
                    }
                    label="Safety Surprise"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Controller
                        // as={Checkbox}
                        name="talk_to_safe_self"
                        control={control}
                        defaultValue={false}
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <Checkbox
                            name={name}
                            onBlur={onBlur}
                            onChange={e => onChange(e.target.checked)}
                            checked={value}
                            inputRef={ref}
                          />
                        )}
                      />
                    }
                    label="Talk to Safe Self"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Controller
                        // as={Checkbox}
                        name="safe_unsafe_picture"
                        control={control}
                        defaultValue={false}
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <Checkbox
                            name={name}
                            onBlur={onBlur}
                            onChange={e => onChange(e.target.checked)}
                            checked={value}
                            inputRef={ref}
                          />
                        )}
                      />
                    }
                    label="Safe / Unsafe Picture"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Controller
                        // as={Checkbox}
                        name="safety_plan"
                        control={control}
                        defaultValue={false}
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <Checkbox
                            name={name}
                            onBlur={onBlur}
                            onChange={e => onChange(e.target.checked)}
                            checked={value}
                            inputRef={ref}
                          />
                        )}
                      />
                    }
                    label="Measures"
                    labelPlacement="end"
                  />
                </FormGroup>
                <SectionHeading heading="Enable AR Tree Features" />
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Controller
                        // as={Checkbox}
                        name="tree_butterflies"
                        control={control}
                        defaultValue={false}
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <Checkbox
                            name={name}
                            onBlur={onBlur}
                            onChange={e => onChange(e.target.checked)}
                            checked={value}
                            inputRef={ref}
                          />
                        )}
                      />
                    }
                    label="Tree Butterflies"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Controller
                        // as={Checkbox}
                        name="tree_flowers"
                        control={control}
                        defaultValue={false}
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <Checkbox
                            name={name}
                            onBlur={onBlur}
                            onChange={e => onChange(e.target.checked)}
                            checked={value}
                            inputRef={ref}
                          />
                        )}
                      />
                    }
                    label="Tree Flowers"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Controller
                        // as={Checkbox}
                        name="tree_fruit"
                        control={control}
                        defaultValue={false}
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <Checkbox
                            name={name}
                            onBlur={onBlur}
                            onChange={e => onChange(e.target.checked)}
                            checked={value}
                            inputRef={ref}
                          />
                        )}
                      />
                    }
                    label="Tree Fruits"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Controller
                        // as={Checkbox}
                        name="tree_leaves"
                        control={control}
                        defaultValue={false}
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <Checkbox
                            name={name}
                            onBlur={onBlur}
                            onChange={e => onChange(e.target.checked)}
                            checked={value}
                            inputRef={ref}
                          />
                        )}
                      />
                    }
                    label="Tree Leaves"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Controller
                        // as={Checkbox}
                        name="tree_lantern"
                        control={control}
                        defaultValue={false}
                        render={(
                          { onChange, onBlur, value, name, ref },
                          { invalid, isTouched, isDirty }
                        ) => (
                          <Checkbox
                            name={name}
                            onBlur={onBlur}
                            onChange={e => onChange(e.target.checked)}
                            checked={value}
                            inputRef={ref}
                          />
                        )}
                      />
                    }
                    label="Tree Lantern"
                    labelPlacement="end"
                  />
                </FormGroup>
                {/* <FormHelperText>Be careful</FormHelperText> */}
              </FormControl>
              <Box width="100%" my={2}>
                <Grid container spacing={1} justify="flex-end">
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disableElevation
                    >
                      Update Cohort
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleCohortDelete(selected)}
                      disableElevation
                    >
                      Delete Cohort
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Box>
          <Section variant="outlined">
            <CohortMembersTable cohort={selected} />
          </Section>
          {/* <Section variant="outlined">
            <SafetyFeaturesForm />
          </Section> */}
          <Section variant="outlined">
            <SyllabusTable cohort={selected} />
          </Section>
          <ZoomCallSetup topics={topics} cohort={selected} />
          <RatingsTable cohort={selected} />
        </React.Fragment>
      )}
    </Section>
  );
}
