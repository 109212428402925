import { Box, Button, TextField } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';

import InterestGroup from './interestGroup';

interface InterestGroupFromProps {
  group?: InterestGroup | null;
  onSubmit?: (data: InterestGroupFormData) => void;
  onDeleteRequest?: (group: InterestGroup) => void;
}

export type InterestGroupFormData = {
  name: string;
  description: string;
};


export default function InterestGroupForm(props: InterestGroupFromProps) {
  const { group, onSubmit, onDeleteRequest } = props;

  const { register, handleSubmit, errors, setValue } = useForm<
    InterestGroupFormData
  >({
    defaultValues: {
      name: group?.name,
      description: group?.description,
    },
  });

  const _onSubmit = handleSubmit((formData) => {
    if (onSubmit) onSubmit(formData);
  });

  const handleDeleteRequest = (group: InterestGroup) => () => {
    if (onDeleteRequest) onDeleteRequest(group);
  };

  React.useEffect(() => {
    if (group) {
      setValue('name', group.name);
      setValue('description', group.description);
    }
  }, [group, setValue]);

  return (
    <form onSubmit={_onSubmit}>
      <TextField
        fullWidth
        label="Group Name"
        variant="filled"
        name="name"
        margin="normal"
        inputRef={register({ required: 'Required' })}
        helperText={errors.name?.message}
        error={Boolean(errors.name)}
      />
      <TextField
        fullWidth
        label="Brief Description"
        variant="filled"
        name="description"
        margin="normal"
        inputRef={register({ required: 'Required' })}
        helperText={errors.description?.message}
        error={Boolean(errors.description)}
      />
      <Box display="flex" width="100%" my={1}>
        <Box flexGrow={1} />
        <Button
          variant="contained"
          disableElevation
          color="primary"
          type="submit"
          style={group ? { marginRight: 4 } : undefined}
        >
          {group ? 'Update' : 'Create'}
        </Button>
        {onDeleteRequest && group && (
          <Button
            variant="contained"
            disableElevation
            color="secondary"
            // type="submit"
            onClick={handleDeleteRequest(group)}
            style={{ marginLeft: 4 }}
          >
            Delete
          </Button>
        )}
      </Box>
    </form>
  );
}
