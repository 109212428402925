import { Box, CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from 'react-router-dom';

import MainLayout from '../components/MainLayout';
import NotAuthorized from '../components/NotAuthorized';
import PageNotFound from '../components/PageNotFound';
import Root from '../components/Root';
import { useAppDispatch } from '../config/storeConfig';
import { authActions, selectAuth } from '../features/auth/authSlice';
import Login from '../features/auth/Login';
import CheckInPrompts from '../features/check_in/CheckInPrompts';
import EmotionalAnalysis from '../features/check_in/emotion_analysis/EmotionalAnalysis';
import Cohorts from '../features/cohort/Cohorts';
import InterestGroups from '../features/interest_group/InterestGroups';
import LearnPage from '../features/learn/LearnPage';
import OfficeHours from '../features/schedule/OfficeHours';
import Topics from '../features/topic/Topics';
import CopingCoach from '../features/user/CopingCoach';
import Profiles from '../features/user/Profiles';
import UserAdmin from '../features/user/UserAdmin';
import Moderation from '../features/moderation/Moderation';
import DiagnosticAssessmentForm from '../features/diagnostic_analysis/DiagnosticURLForm';

const LoadingMessage = (
  <Box
    width="100vw"
    height="100vh"
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
  >
    <CircularProgress size={48} thickness={3} style={{ margin: 16 }} />
    <Typography variant="h4">Loading</Typography>
  </Box>
);

const ProtectedRoute: React.FC<RouteProps> = (props: RouteProps) => {
  const auth = useSelector(selectAuth);
  const dispatch = useAppDispatch();

  const { loading, errorMsg, data } = auth;

  React.useEffect(() => {
    if (!loading && data === null) {
      // verify login
      dispatch(authActions.login());
    }
  }, [loading, data, dispatch]);

  if (data === null && errorMsg === null) return LoadingMessage;

  if (data === null && errorMsg) {
    return <Redirect to="/login" />;
  }

  if (data === null) {
    return <Redirect to="/login" />;
  } else if (data.roles[0] !== 'leader' && data.roles[0] !== 'admin') {
    return <Redirect to="/forbidden" />;
  }

  return <Route {...props} />;
};

const routes = (
  <BrowserRouter>
    <Switch>
      <ProtectedRoute exact path="/">
        <Root />
      </ProtectedRoute>
      <ProtectedRoute exact path="/user-admin">
        <MainLayout content={<UserAdmin />} />
      </ProtectedRoute>
      <ProtectedRoute exact path="/profile">
        <MainLayout content={<Profiles />} />
      </ProtectedRoute>
      <ProtectedRoute exact path="/analysis">
        <MainLayout content={<EmotionalAnalysis />} />
      </ProtectedRoute>
      <ProtectedRoute exact path="/check-in">
        <MainLayout content={<CheckInPrompts />} />
      </ProtectedRoute>
      <ProtectedRoute exact path="/learn">
        <MainLayout content={<LearnPage />} />
      </ProtectedRoute>
      <ProtectedRoute exact path="/topics">
        <MainLayout content={<Topics />} />
      </ProtectedRoute>
      <ProtectedRoute exact path="/cohorts">
        <MainLayout content={<Cohorts />} />
      </ProtectedRoute>
      <ProtectedRoute exact path="/interest-groups">
        <MainLayout content={<InterestGroups />} />
      </ProtectedRoute>
      <ProtectedRoute exact path="/moderation">
        <MainLayout content={<Moderation />} />
      </ProtectedRoute>
      <ProtectedRoute exact path="/coping-coach">
        <MainLayout content={<CopingCoach />} />
      </ProtectedRoute>
      <ProtectedRoute exact path="/office_hours/leader">
        <MainLayout content={<OfficeHours />} />
      </ProtectedRoute>
      <ProtectedRoute exact path="/measures">
        <MainLayout content={<DiagnosticAssessmentForm />} />
      </ProtectedRoute>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/forbidden">
        <NotAuthorized />
      </Route>
      <Route exact path="*">
        <PageNotFound />
      </Route>
    </Switch>
  </BrowserRouter>
);

export default routes;
