import { Box, Button, TextField, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';

import { useAppDispatch } from '../../config/storeConfig';
import { authActions } from './authSlice';

// import { loginAPI } from '../../api/userAPI';
type LoginFormData = {
  study_id: string;
  study_initials: string;
};

export default function LoginForm() {
  const { register, handleSubmit, errors } = useForm<LoginFormData>();

  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = handleSubmit(async (formData) => {
    const result = await dispatch(
      authActions.login({
        study_id: formData.study_id,
        study_initials: formData.study_initials,
      })
    )
    if (typeof result.payload === 'string') {
      enqueueSnackbar(result.payload, {variant: 'error'});
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <Box width="100%" py={2} px={0}>
        <Typography variant="h4" align="center">
          Log In
        </Typography>
        <TextField
          fullWidth
          label="Study ID"
          variant="filled"
          name="study_id"
          inputRef={register({ required: 'Required' })}
          helperText={errors.study_id?.message}
          error={Boolean(errors.study_id)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Study Initials"
          variant="filled"
          name="study_initials"
          inputRef={register({ required: 'Required' })}
          helperText={errors.study_initials?.message}
          error={Boolean(errors.study_initials)}
          margin="normal"
        />
        <Box mt={2} width="100%" display="flex">
          <Box flexGrow={1} />
          <Button
            variant="contained"
            disableElevation
            color="primary"
            type="submit"
          >
            Enter
          </Button>
        </Box>
      </Box>
    </form>
  );
}
