import { Box, TextField } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import MaterialTable from 'material-table';
import React from 'react';
import { useSelector } from 'react-redux';

import SectionHeading from '../../components/SectionHeading';
import tableIcons from '../../config/tableIcons';
import useScheduleSlice from '../schedule/useScheduleSlice';
import Topic from '../topic/topic';
import { selectTopicsList } from '../topic/topicSlice';
import Cohort from './cohort';
import { dateToISOLikeButLocal } from '../../util/dateToISOLikeButLocal';

export default function SyllabusTable(props: { cohort: Cohort }) {
  const { cohort } = props;

  const filter = React.useMemo(
    () => ({
      resource_type: 'topic',
      invitees: [{ invitee_type: 'cohort', invitee_id: cohort.id.toString() }],
    }),
    [cohort]
  );

  const scheduleSlice = useScheduleSlice(filter);

  const { data: scheduleList, /* error, */ isLoading } = scheduleSlice.state;
  const actions = scheduleSlice.actions;

  const topics = useSelector(selectTopicsList);

  const topicsLookup = React.useMemo(() => {
    if (topics) {
      return topics.reduce<{ [x: string]: Topic }>((accum, curr) => {
        accum[curr.name] = curr;
        return accum;
      }, {});
    }
  }, [topics]);

  return (
    <MaterialTable
      title={<SectionHeading heading="Syllabus" />}
      isLoading={isLoading}
      icons={tableIcons}
      components={{ Container: Box }}
      columns={[
        {
          title: 'Topic',
          field: 'topic',
          lookup: topics.reduce<{ [x: string]: string }>((accum, curr) => {
            accum[curr.name] = curr.name;
            return accum;
          }, {}),
        },
        {
          title: 'Start',
          field: 'start',
          type: 'datetime',
          editComponent: props => (
            <TextField
              label="Start Time"
              type="datetime-local"
              value={
                props.value === undefined
                  ? ''
                  : dateToISOLikeButLocal(new Date(props.value))
              }
              onChange={e => props.onChange(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          ),
          render: rowData => new Date(rowData.start).toLocaleString(),
        },
        {
          title: 'End',
          field: 'end',
          type: 'datetime',
          editComponent: props => (
            <TextField
              label="End Time"
              type="datetime-local"
              value={
                props.value === undefined
                  ? ''
                  : dateToISOLikeButLocal(new Date(props.value))
              }
              onChange={e => props.onChange(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          ),
          render: rowData => new Date(rowData.end).toLocaleString(),
        },
      ]}
      data={
        scheduleList.map(event => {
          return {
            // start: dateToISOLikeButLocal(new Date(event.start_at)),
            start: new Date(event.start_at).toISOString(),
            // end: dateToISOLikeButLocal(new Date(event.end_at)),
            end: new Date(event.end_at).toISOString(),
            topic: topics.find(
              topic => topic.id === parseInt(event.resource_id as string)
            )?.name,
            id: event.id,
            resource_id: event.resource_id,
            resource_type: event.resource_type,
          };
        }) ?? []
      }
      editable={{
        onRowAdd: async newData => {
          try {
            actions.createScheduledEvent({
              start_at: new Date(newData.start).toISOString(),
              end_at: new Date(newData.end).toISOString(),
              resource_id: topicsLookup![newData.topic!].id,
              resource_type: 'topic',
              event_name: topicsLookup![newData.topic!].name,
              invitees: [{ invitee_id: cohort.id, invitee_type: 'cohort' }],
            });
          } catch (reason) {
            console.error(reason);
          }
        },
        onRowUpdate: async (newData, oldData) => {
          try {
            await actions.updateScheduledEvent(oldData!.id, {
              start_at: new Date(newData.start).toISOString(),
              end_at: new Date(newData.end).toISOString(),
              resource_id: topicsLookup![newData.topic!].id,
              resource_type: 'topic',
              event_name: newData.topic,
            });
          } catch (reason) {
            console.error(reason);
          }
        },
        onRowDelete: async oldData => {
          try {
            await actions.deleteScheduledEvent(oldData.id);
          } catch (reason) {
            console.error(reason);
          }
        },
      }}
      actions={[
        {
          icon: RefreshIcon,
          tooltip: 'Refresh Data',
          isFreeAction: true,
          onClick: () => actions.refresh(),
        },
      ]}
      options={{
        search: false,
        pageSize: 10,
        exportButton: true,
        exportAllData: true,
        exportFileName: `${cohort.name}_syllabus`,
      }}
    />
  );
}
