export function dateToISOLikeButLocal(datetime: Date, seconds?: boolean, date: boolean = true, time: boolean = true) {
  const offsetMs = datetime.getTimezoneOffset() * 60 * 1000;
  const msLocal = datetime.getTime() - offsetMs;
  const dateLocal = new Date(msLocal);
  const iso = dateLocal.toISOString();
  const isoLocal = iso.slice(0, seconds ? 19 : 16);
  if (!date) return isoLocal.split('T')[1];
  if (!time) return isoLocal.split('T')[0];
  return isoLocal;
}

// export function 
