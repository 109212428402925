import React from 'react';
import {Progress} from './progress';
import { progressAPI } from './progressAPI';
import { useSnackbar } from 'notistack';

interface ProgressState {
  isLoading: boolean;
  error: Error | null;
  data: Progress | null;
}

export default function useProgresssSlice(user_uuid: any) {
  const [state, setState] = React.useState<ProgressState>({
    isLoading: false,
    error: null,
    data: null,
  });

  const { enqueueSnackbar } = useSnackbar();

  const refresh = React.useCallback(async () => {
    try {
      setState(prev => ({ ...prev, isLoading: true }));
      const progress = await progressAPI({user_uuid});
      setState(prev => ({ ...prev, isLoading: false, data: progress }));
    } catch (reason) {
      const error = new Error(reason);
      setState(prev => ({ ...prev, isLoading: false, error }));
      enqueueSnackbar(reason, { variant: 'error' });
    }
  }, [ user_uuid,enqueueSnackbar]);

  React.useEffect(() => {
    refresh();
  }, [user_uuid,refresh]);

  return { state, actions: { refresh } };
}
