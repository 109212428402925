import { useSnackbar } from 'notistack';
import React from 'react';
import Notification from './notifications';
import createNotificationAPI from './notificationsAPI';

interface NotificationsState {
  isLoading: boolean;
  error: Error | null;
  data: Notification;
}

export default function useNotificationsSlice() {
  const [state, setState] = React.useState<NotificationsState>({
    isLoading: false,
    error: null,
    data: {
      notification_text: '',
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const createNotification = React.useCallback(
    async (
      params: { notification_text: string },
      user_uuid: string,
      cohort_id: number | string,
      app: string | undefined
    ) => {
      try {
        setState(prevState => ({ ...prevState, isLoading: true }));
        const message = await createNotificationAPI(
          params,
          user_uuid,
          app,
          cohort_id
        );
        setState(prevState => ({
          ...prevState,
          isLoading: false,
          data: message,
        }));
        enqueueSnackbar(`Notification Sent to Users`, { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        setState(prevState => ({
          ...prevState,
          isLoading: false,
          error: error,
        }));
        enqueueSnackbar(reason, { variant: 'error' });
        throw error;
      }
    },
    [setState, enqueueSnackbar]
  );

  return {
    state,
    actions: {
      createNotification,
    },
  };
}
