import { Box } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import MaterialTable from 'material-table';
import React from 'react';
import { useSelector } from 'react-redux';

import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import tableIcons from '../../config/tableIcons';
import Cohort from '../cohort/cohort';
import { selectRolesList } from '../role/roleSlice';
import { UseUsersSlice } from './useUsersSlice';

export default function EditUser(props: {
  cohorts: Cohort[];
  usersSlice: UseUsersSlice;
  app: string;
}) {
  const { usersSlice, cohorts, app } = props;
  const { data: users, /* error, */ isLoading } = usersSlice.state;
  const actions = usersSlice.actions;
  const roles = useSelector(selectRolesList);
  // const cohorts = useSelector(selectCohorts);

  const cohortUpdateLookup = React.useMemo(() => {
    if (cohorts) {
      return cohorts?.reduce<{ [x: string]: Cohort }>((accum, curr) => {
        accum[curr.name] = curr;
        return accum;
      }, {});
    }
  }, [cohorts]);

  let columns: any[] = [
    {
      title: 'Study Initials',
      field: 'studyInitials',
    },
    {
      title: 'Study ID',
      field: 'studyID',
    },

    {
      title: 'Display Name',
      field: 'displayName',
    },
    {
      title: 'Role',
      field: 'role',
      lookup: roles.reduce<{ [x: string]: string }>((accum, curr) => {
        accum[curr.name] = curr.name;
        return accum;
      }, {}),
    },
    // {
    //   title: 'First Name',
    //   field: 'firstName',
    // },
    // {
    //   title: 'Last Name',
    //   field: 'lastName',
    // },
    {
      title: 'Email',
      field: 'email',
    },
    {
      title: 'Mobile',
      field: 'mobile',
    },
  ];

  if (app === 'ss') {
    columns.push({
      title: 'Cohort',
      field: 'cohort',
      // render: (rowData) => rowData.cohort?.name,
      lookup: cohorts.reduce<{ [x: string]: string }>((accum, curr) => {
        accum[curr.name] = curr.name;
        return accum;
      }, {}),
      // lookup: cohortUpdateLookup,
    });
  }

  columns.push(
    ...[
      {
        title: 'Last Login',
        field: 'last_login',
        editable: 'never',
        type: 'datetime',
        render: (rowData: any) => {
          if (rowData.last_login === '--') return rowData.last_login;
          return new Date(rowData.last_login).toLocaleString(undefined, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
          });
        },
      },
      {
        title: 'First Login',
        field: 'first_login',
        editable: 'never',
        type: 'datetime',
        render: (rowData: any) => {
          if (rowData.first_login === '--') return rowData.first_login;
          return new Date(rowData.first_login).toLocaleString(undefined, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
          });
        },
      },
      { title: 'App Ver.', field: 'app_version', type: 'numeric' },
    ]
  );

  return (
    <Section variant="outlined">
      <Box width="100%" px={1}>
        {users && roles && cohorts && (
          <MaterialTable
            isLoading={isLoading}
            title={<SectionHeading heading="Users" />}
            icons={tableIcons}
            components={{ Container: Box }}
            columns={columns}
            data={users.map(user => ({
              studyID: user.study_id,
              studyInitials: user.study_initials,
              displayName: user.settings.display_name ?? '',
              role: user.roles[0],
              // firstName: user.first_name ?? '',
              // lastName: user.last_name ?? '',
              email: user.email,
              mobile: user.cell_phone ?? undefined,
              cohort: user.cohort?.name,
              uuid: user.uuid,
              last_login: Boolean(user.last_login?.modified)
                ? user.last_login?.modified
                : '--',
              first_login: Boolean(user.last_login?.created)
              ? user.last_login?.created
              : '--',
              app_version: user.last_login?.app_version ?? '--',
            }))}
            editable={{
              onRowUpdate: async (newData, oldData) => {
                try {
                  await actions.updateUser(oldData!.uuid, {
                    // first_name:
                    //   newData.firstName === '' ? undefined : newData.firstName,
                    // last_name:
                    //   newData.lastName === '' ? undefined : newData.lastName,
                    email: newData.email,
                    cell_phone: newData.mobile,
                    study_id: newData.studyID,
                    study_initials: newData.studyInitials,
                    // home_phone: string;
                    cohort_id:
                      app === 'ss'
                        ? cohortUpdateLookup![newData.cohort!].id
                        : undefined,
                    roles: newData.role,
                    settings: {
                      display_name:
                        newData.displayName === ''
                          ? 'Anonymous'
                          : newData.displayName,
                    },
                  });
                } catch (reason) {
                  console.error(reason);
                }
              },
              onRowDelete: async oldData => {
                try {
                  await actions.deleteUser(oldData.uuid);
                } catch (reason) {
                  console.error(reason);
                }
              },
            }}
            actions={[
              {
                icon: RefreshIcon,
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => actions.refresh(),
              },
            ]}
            options={{
              pageSize: 10,
              exportButton: true,
              exportAllData: true,
              exportFileName: `${
                app === 'ss' ? 'seaking_safety' : 'self_aware'
              }_users`,
            }}
          />
        )}
      </Box>
    </Section>
  );
}
