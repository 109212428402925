// import DateFnsUtils from '@date-io/date-fns';
import LuxonUtils from '@date-io/luxon';
import { createMuiTheme, CssBaseline, Fade, responsiveFontSizes, ThemeProvider } from '@material-ui/core';
import orange from '@material-ui/core/colors/orange';
import purple from '@material-ui/core/colors/purple';
import red from '@material-ui/core/colors/red';
import teal from '@material-ui/core/colors/teal';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
import React from 'react';

import { AppContext } from './AppContext';
import routes from './routes';
import useLocalStorage from './util/useLocalStorage';

// import { Admin } from 'react-admin';

// let baseTheme = createMuiTheme({ shape: { borderRadius: 0 }});
// baseTheme = responsiveFontSizes(baseTheme);

function App() {
  const [app, setApp] = useLocalStorage('app', 'ss');

  let baseTheme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          // type: enableDark ? 'dark' : 'light',
          // background: { default: enableDark ? '#303030' : '#f0f0f0' },
          secondary: { main: app === 'ss' ? orange['A400'] :red['A400'] },
          primary: { main: app === 'ss' ? purple[700] : teal[500] },
        },
        shape: {
          borderRadius: 5,
        },
        typography: {
          fontFamily: "'Roboto Slab', serif",
        },
        props: {
          MuiPaper: {
            variant: 'outlined',
          },
        },
      }),
    [app]
  );

  baseTheme = responsiveFontSizes(baseTheme);

  return (
    <AppContext.Provider value={{ setApp: setApp, app: app }}>
      <CssBaseline />
      <ThemeProvider theme={baseTheme}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          TransitionComponent={Fade as any}
        >
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            {routes}
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
