import { Canceler } from 'axios';

import { axios, CancelToken } from '../../config/axiosConfig';
import User from './user';
import { UserSettings } from './userSettings';


// Seeking Safety
export type CreateSSUserArgs = {
  first_name?: string;
  last_name?: string;
  email: string;
  cell_phone?: string;
  study_id?: string;
  study_initials?: string;
  settings?: Partial<
    Omit<UserSettings, 'emotional_status' | 'hide_interest_group' | 'id'>
  >;
  home_phone?: string;
  cohort_id?: number;
  roles: string;
};

export type UpdateSSUserArgs = Partial<CreateSSUserArgs>;

export async function createSSUserAPI(details: CreateSSUserArgs) {
  try {
    const response = await axios.post<{
      user: User;
    }>('/api/v2/user/create', details, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function updateSSUserAPI(uuid: string, details: UpdateSSUserArgs) {
  try {
    // @ts-ignore
    Object.keys(details).forEach(key => details[key] === undefined || details[key] === null ? delete details[key] : {});
    const response = await axios.put<{
      user: User;
    }>(`/api/v1/user/${uuid}`, details, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data.user;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getSSUsersListAPI(
  params?: {
    cohort_id?: number;
    interest_group_id?: number;
    role?: string;
    registered?: boolean;
  },
  executor?: (canceler: Canceler) => void
) {
  try {
    const response = await axios.get<{
      users: Array<User>;
    }>('/api/v1/users', {
      params: params,
      cancelToken: executor ? new CancelToken(executor) : undefined,
    });
    return response.data.users;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function deactivateSSUserAPI(uuid: string) {
  try {
    const response = await axios.delete<{
      user: User;
    }>(`/api/v1/user/${uuid}/deactivate`);
    return response.data.user;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function reactivateSSUserAPI(uuid: string) {
  try {
    const response = await axios.put<{
      user: User;
    }>(`/api/v1/user/${uuid}/reactivate`);
    return response.data.user;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

const emoURI: string = process.env.REACT_APP_EMO_URI!;

// Self Aware
export type CreateSAUserArgs = Omit<CreateSSUserArgs, 'cohort_id'>;
export type UpdateSAUserArgs = Partial<CreateSAUserArgs>;

export async function createSAUserAPI(details: CreateSAUserArgs) {
  try {
    const response = await axios.post<{
      user: User;
    }>(`${emoURI}/api/v2/user/create`, details, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function updateSAUserAPI(uuid: string, details: UpdateSAUserArgs) {
  try {
    // @ts-ignore
    Object.keys(details).forEach(key => details[key] === undefined || details[key] === null ? delete details[key] : {});
    const response = await axios.put<{
      user: User;
    }>(`${emoURI}/api/v1/user/${uuid}`, details, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data.user;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getSAUsersListAPI(params?: {
  cohort_id?: number;
  interest_group_id?: number;
  role?: string;
  is_active?: boolean;
  registered?: boolean;
}) {
  try {
    const response = await axios.get<{
      users: User[];
    }>(`${emoURI}/api/v1/users`, {
      params: params,
    });
    return response.data.users;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function deactivateSAUserAPI(uuid: string) {
  try {
    const response = await axios.delete<{
      user: User;
    }>(`${emoURI}/api/v1/user/${uuid}/deactivate`);
    return response.data.user;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function reactivateSAUserAPI(uuid: string) {
  try {
    const response = await axios.put<{
      user: User;
    }>(`${emoURI}/api/v1/user/${uuid}/reactivate`);
    return response.data.user;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
