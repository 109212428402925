import { Box, Button, TextField, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import ContentContainer from '../../components/ContentContainer';
import RouteTitle from '../../components/RouteTitle';
import Section from '../../components/Section';
import {
  createDiagnosticURLsAPI,
  updateDiagnosticURLsAPI,
} from './diagnosticAPI';
import { DiagnosticAssessmentUpdateForm } from './DiagnosticFormData';
import { useDiagnosticsSlice } from './useDiagnosticsSlice';

export default function DiagnosticAssessmentForm() {
  const { enqueueSnackbar } = useSnackbar();
  // cohort is equal to null for slice
  const diagnosticSlice = useDiagnosticsSlice(null);
  const data = React.useMemo(() => {
    return diagnosticSlice.state.data;
  }, [diagnosticSlice.state.data]);
  const {
    /* register, */ handleSubmit,
    /* errors, */ control /* reset */,
  } = useForm<DiagnosticAssessmentUpdateForm>();

  const onSubmit = handleSubmit((formData: DiagnosticAssessmentUpdateForm) => {
    let params = {
      cohort_id: null,
      perceived_stress_scale: formData.perceived_stress_scale,
      worst_event_stress_scale: formData.worst_event_stress_scale,
      coping_scale: formData.coping_scale,
      global_improvement_scale: formData.global_improvement_scale,
      addiction_monitor: formData.addiction_monitor,
    };
    // Diagnostic Assesment for cohort does exist
    if (data.diagnostic_assessments[0]) {
      updateDiagnosticURLsAPI(params, data.diagnostic_assessments[0].id);
      diagnosticSlice.actions.refresh();
      enqueueSnackbar(`Measures URLs Updated`, {
        variant: 'success',
      });
      // Diagnostic Assesment for cohort does NOT exist
    } else {
      createDiagnosticURLsAPI(params);
      diagnosticSlice.actions.refresh();
      enqueueSnackbar(`Measures URLs Created`, {
        variant: 'success',
      });
    }
  });

  return (
    <ContentContainer maxWidth="lg">
      <RouteTitle title="Measures" />
      <React.Fragment>
        <Box>
          {data.diagnostic_assessments &&
          data.diagnostic_assessments.length > 0 ? (
            // Diagnostic Assesment for cohort does exist
            <form onSubmit={onSubmit} key={data.diagnostic_assessments[0].id}>
              <React.Fragment>
                <Section variant="outlined">
                  <Typography>{'Beliefs About Self'}</Typography>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        label="Beliefs About Self URL"
                        variant="filled"
                        margin="normal"
                      />
                    }
                    name="perceived_stress_scale"
                    control={control}
                    defaultValue={
                      data.diagnostic_assessments[0].perceived_stress_scale
                    }
                  />
                </Section>
                <Section variant="outlined">
                  <Typography>{'Trauma Symptoms'}</Typography>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        label="Trauma Symptoms URL"
                        variant="filled"
                        margin="normal"
                      />
                    }
                    name="worst_event_stress_scale"
                    control={control}
                    defaultValue={
                      data.diagnostic_assessments[0].worst_event_stress_scale
                    }
                  />
                </Section>
                <Section variant="outlined">
                  <Typography>{'Coping'}</Typography>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        label="Coping URL"
                        variant="filled"
                        margin="normal"
                      />
                    }
                    name="coping_scale"
                    control={control}
                    defaultValue={data.diagnostic_assessments[0].coping_scale}
                  />
                </Section>
                <Section variant="outlined">
                  <Typography>{'Improvement'}</Typography>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        label="Improvement URL"
                        variant="filled"
                        margin="normal"
                      />
                    }
                    name="global_improvement_scale"
                    control={control}
                    defaultValue={
                      data.diagnostic_assessments[0].global_improvement_scale
                    }
                  />
                </Section>
                <Section variant="outlined">
                  <Typography>{'Addiction'}</Typography>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        label="Addiction URL"
                        variant="filled"
                        margin="normal"
                      />
                    }
                    name="addiction_monitor"
                    control={control}
                    defaultValue={
                      data.diagnostic_assessments[0].addiction_monitor
                    }
                  />
                </Section>
              </React.Fragment>

              <Box width="100%" my={1} display="flex" justifyContent="flex-end">
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disableElevation
                >
                  {'Update'}
                </Button>
              </Box>
            </form>
          ) : (
            // Diagnostic Assesment for cohort does not exist
            <form onSubmit={onSubmit}>
              <React.Fragment>
                <Section variant="outlined">
                  <Typography>{'Beliefs About Self'}</Typography>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        label="Beliefs About Self URL"
                        variant="filled"
                        margin="normal"
                      />
                    }
                    name="perceived_stress_scale"
                    control={control}
                    defaultValue="Input URL"
                  />
                </Section>
                <Section variant="outlined">
                  <Typography>{'Trauma Symptoms'}</Typography>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        label="Trauma Symptoms URL"
                        variant="filled"
                        margin="normal"
                      />
                    }
                    name="worst_event_stress_scale"
                    control={control}
                    defaultValue="Input URL"
                  />
                </Section>
                <Section variant="outlined">
                  <Typography>{'Coping'}</Typography>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        label="Coping URL"
                        variant="filled"
                        margin="normal"
                      />
                    }
                    name="coping_scale"
                    control={control}
                    defaultValue="Input URL"
                  />
                </Section>
                <Section variant="outlined">
                  <Typography>{'Improvement'}</Typography>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        label="Improvement URL"
                        variant="filled"
                        margin="normal"
                      />
                    }
                    name="global_improvement_scale"
                    control={control}
                    defaultValue="Input URL"
                  />
                </Section>
                <Section variant="outlined">
                  <Typography>{'Addiction'}</Typography>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        label="Addiction URL"
                        variant="filled"
                        margin="normal"
                      />
                    }
                    name="addiction_monitor"
                    control={control}
                    defaultValue="Input URL"
                  />
                </Section>
              </React.Fragment>

              <Box width="100%" my={1} display="flex" justifyContent="flex-end">
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disableElevation
                >
                  {'Create'}
                </Button>
              </Box>
            </form>
          )}
        </Box>
      </React.Fragment>
    </ContentContainer>
  );
}
