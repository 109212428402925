import React from 'react';
import {SafetySurpriseResponses} from './ratings';
import { SafetyReflectionAPI } from './ratingsAPI';
import { useSnackbar } from 'notistack';

interface SafetyReflectionState {
  isLoading: boolean;
  error: Error | null;
  data: SafetySurpriseResponses[] | null;
}

export default function useSafetyRefelectionsSlice(cohort_id: number) {
  const [state, setState] = React.useState<SafetyReflectionState>({
    isLoading: false,
    error: null,
    data: null,
  });

  const { enqueueSnackbar } = useSnackbar();

  const refresh = React.useCallback(async () => {
    try {
      setState(prev => ({ ...prev, isLoading: true }));
      const safetyReflection = await SafetyReflectionAPI({cohort_id});
      setState(prev => ({ ...prev, isLoading: false, data: safetyReflection }));
    } catch (reason) {
      const error = new Error(reason);
      setState(prev => ({ ...prev, isLoading: false, error }));
      enqueueSnackbar(reason, { variant: 'error' });
    }
  }, [ cohort_id,enqueueSnackbar]);

  React.useEffect(() => {
    refresh();
  }, [cohort_id,refresh]);

  return { state, actions: { refresh } };
}
