import { axios } from '../../config/axiosConfig';
import { LearnCategory, LearnPost } from './learn';

const emoURI: string = process.env.REACT_APP_EMO_URI!;

export interface CreateLearnPostArgs {
  category_id: number;
  subcategory?: string;
  text?: string;
}

//#region Category

export async function createLearnCategoryAPI(name: string, sa?: boolean) {
  try {
    const baseURI = sa ? emoURI : '';
    const response = await axios.post<{
      learn_category: LearnCategory;
    }>(
      `${baseURI}/api/v1/learn_category/create`,
      { name },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
    return response.data.learn_category;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getLearnCategoriesListAPI(
  sa?: boolean,
  query?: { order_by?: string; reverse?: boolean }
) {
  try {
    const baseURI = sa ? emoURI : '';
    const response = await axios.get<{
      learn_categories: LearnCategory[];
    }>(`${baseURI}/api/v1/learn_categories`, { params: query });
    return response.data.learn_categories;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function updateLearnCategoryAPI(
  id: number,
  name: string,
  sa?: boolean
) {
  try {
    const baseURI = sa ? emoURI : '';
    const response = await axios.put<{
      learn_category: LearnCategory;
    }>(
      `${baseURI}/api/v1/learn_category/${id}`,
      { name },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
    return response.data.learn_category;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function deleteLearnCategoryAPI(id: number, sa?: boolean) {
  try {
    const baseURI = sa ? emoURI : '';
    const response = await axios.delete<{
      learn_category: LearnCategory;
    }>(`${baseURI}/api/v1/learn_category/${id}/delete`);
    return response.data.learn_category;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function reIndexLearnCategoryAPI(data: {
  learn_category_ids: number[];
}, sa?: boolean) {
  try {
    const baseURI = sa ? emoURI : '';
    const response = await axios.put<{
      learn_categories: LearnCategory[];
    }>(`${baseURI}/api/v1/learn_categories/reindex`, data, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data.learn_categories;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

//#endregion

//#region Post

export async function createLearnPostAPI(
  args: CreateLearnPostArgs,
  sa?: boolean
) {
  try {
    const baseURI = sa ? emoURI : '';
    const response = await axios.post<{
      learn_post: LearnPost;
    }>(`${baseURI}/api/v1/learn_post/create`, args, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data.learn_post;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function getLearnPostsListAPI(
  query?: {
    category_id?: number;
    subcategory?: string;
  },
  sa?: boolean
) {
  try {
    const baseURI = sa ? emoURI : '';
    const response = await axios.get<{
      learn_posts: LearnPost[];
    }>(`${baseURI}/api/v1/learn_posts`, { params: query });
    return response.data.learn_posts;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function updateLearnPostAPI(
  id: number,
  args: Partial<CreateLearnPostArgs>,
  sa?: boolean
) {
  try {
    const baseURI = sa ? emoURI : '';
    const response = await axios.put<{
      learn_post: LearnPost;
    }>(`${baseURI}/api/v1/learn_post/${id}`, args, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data.learn_post;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

export async function deleteLearnPostAPI(id: number, sa?: boolean) {
  try {
    const baseURI = sa ? emoURI : '';
    const response = await axios.delete<{
      learn_post: LearnPost;
    }>(`${baseURI}/api/v1/learn_post/${id}/delete`);
    return response.data.learn_post;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}

//#endregion
