import { Box } from '@material-ui/core';
import React from 'react';

import { AppContext } from '../../AppContext';
import ContentContainer from '../../components/ContentContainer';
import RouteTitle from '../../components/RouteTitle';
import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import SelectionSearch from '../../containers/SelectionSearch';
import { LearnCategory, LearnPost } from './learn';
import LearnCategoryForm from './LearnCategoryForm';
import LearnPostForm, { LearnPostFormData } from './LearnPostForm';
import useLearnSlice from './useLearnSlice';

export default function LearnPage() {
  const [
    selectedCategory,
    setSelectedCategory,
  ] = React.useState<LearnCategory | null>(null);

  const [selectedPost, setSelectedPost] = React.useState<LearnPost | null>(
    null
  );

  const [priorityCatIDList, setPriorityCatIDList] = React.useState<number[]>(
    []
  );

  const { app } = React.useContext(AppContext);

  const postQuery = React.useMemo(
    () => ({ category_id: selectedCategory?.id }),
    [selectedCategory]
  );
  const catQuery = React.useMemo(() => ({ order_by: 'index' }), []);

  const learnSlice = useLearnSlice(postQuery, app === 'sa', catQuery);
  const { categories, posts, actions } = learnSlice;

  const handleOnCategorySelect = (category: string | LearnCategory | null) => {
    if (typeof category !== 'string') setSelectedCategory(category);
  };
  const handleOnPostSelect = (post: string | LearnPost | null) => {
    if (typeof post !== 'string') setSelectedPost(post);
  };

  const handleNewCategory = async (name: string) => {
    // setLearnCategories([name, ...learnCategories]);
    // console.log(name)
    try {
      actions.createCategory(name);
    } catch (reason) {
      console.error(reason);
    }
  };

  const handleUpdateCategory = (id: number) => async (
    name: string,
    reIndex?: { index: number; id: number }
  ) => {
    try {
      if (reIndex) {
        const reIndexed = priorityCatIDList.filter(id => id !== reIndex.id);
        reIndexed.splice(reIndex.index, 0, reIndex.id);
        await actions.reIndexCategory({
          learn_category_ids: reIndexed,
        });
      }
      actions.updateCategory(id, name);
    } catch (reason) {
      console.error(reason);
    }
  };

  const handleDeleteCategory = async (cat: LearnCategory) => {
    try {
      await actions.deleteCategory(cat.id);
      setSelectedCategory(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleNewPost = async (formData: LearnPostFormData) => {
    if (selectedCategory === null) return;
    try {
      actions.createPost({
        category_id: selectedCategory.id,
        subcategory: formData.subcategory,
        text: formData.text,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdatePost = (id: number) => async (
    formData: LearnPostFormData
  ) => {
    // if (selectedCategory === null) return;
    try {
      actions.updatePost(id, {
        subcategory: formData.subcategory,
        text: formData.text,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeletePost = async (post: LearnPost) => {
    try {
      await actions.deletePost(post.id);
      setSelectedPost(null);
    } catch (error) {
      console.error(error);
    }
  };

  const refreshPosts = React.useMemo(() => actions.refreshPosts, [
    actions.refreshPosts,
  ]);

  React.useEffect(() => {
    if (selectedCategory) {
      refreshPosts();
    }
    setSelectedPost(null);
  }, [selectedCategory, setSelectedPost, refreshPosts]);

  React.useEffect(() => {
    setSelectedCategory(null);
    setSelectedPost(null);
  }, [app]);

  React.useEffect(() => {
    setPriorityCatIDList(categories.data.map(cat => cat.id));
  }, [categories.data]);

  return (
    <ContentContainer maxWidth="lg">
      <RouteTitle title="Learn" />
      <Section variant="outlined">
        <SectionHeading heading="Create Category" />
        <LearnCategoryForm onSubmit={handleNewCategory} />
      </Section>
      <Section variant="outlined">
        <SectionHeading heading="Select Category to Update" />
        <SelectionSearch
          label="Select Category"
          options={categories.data}
          getOptionLabel={option => `${option.name}`}
          onSelect={handleOnCategorySelect}
        />
        {selectedCategory && (
          <LearnCategoryForm
            category={selectedCategory}
            onSubmit={handleUpdateCategory(selectedCategory.id)}
            onDeleteSubmit={handleDeleteCategory}
            // onPriorityReindex={handlePriorityReindex}
            priorityList={priorityCatIDList}
          />
        )}
        {selectedCategory && (
          <>
            <Section variant="outlined">
              <SectionHeading heading="Create Post" />
              <LearnPostForm onSubmit={handleNewPost} />
            </Section>
            <Section variant="outlined">
              <SectionHeading heading="Select Post to Update" />
              <Box width="100%" my={2}>
                <SelectionSearch
                  label="Select Post"
                  options={posts.data}
                  loading={posts.isLoading}
                  getOptionLabel={option => option.subcategory ?? ''}
                  onSelect={handleOnPostSelect}
                />
              </Box>
              {selectedPost && (
                <LearnPostForm
                  post={selectedPost}
                  onSubmit={handleUpdatePost(selectedPost.id)}
                  onDelete={handleDeletePost}
                />
              )}
            </Section>
          </>
        )}
      </Section>
    </ContentContainer>
  );
}
