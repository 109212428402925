import React from 'react';
import {Threshold} from './checkIn';
import { getThresholdAPI, updateThresholdsAPI } from './checkInAPI';
import { useSnackbar } from 'notistack';

interface ThresholdsState {
  isLoading: boolean;
  error: Error | null;
  data: Threshold;
}

export default function useThresholdsSlice(
) {
  const [state, setState] = React.useState<ThresholdsState>({
    isLoading: false,
    error: null,
    data: {
      audio: 0,
      text: 0,
      video: 0
    }
    
  });
  

  const { enqueueSnackbar } = useSnackbar();

  const updateThresholds = React.useCallback(
    async (threshold) => {
      try {
        setState((prev) => ({ ...prev, isLoading: true }));
        const updatedThreshold = await updateThresholdsAPI(threshold);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          data: updatedThreshold,
        }));
        enqueueSnackbar('Threshold UPDATED', { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        setState((prev) => ({ ...prev, isLoading: false, error }));
        enqueueSnackbar(reason, { variant: 'error' });
      }
    },
    [setState, enqueueSnackbar]
  );

  const refresh = React.useCallback(async () => {
    try {
      setState(prev => ({ ...prev, isLoading: true }));
      const threshold = await getThresholdAPI();
      setState(prev => ({ ...prev, isLoading: false, data: threshold }));
    } catch (reason) {
      const error = new Error(reason);
      setState(prev => ({ ...prev, isLoading: false, error }));
      enqueueSnackbar(reason, { variant: 'error' });
    }
  }, [ enqueueSnackbar]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return { state, actions: { refresh, updateThresholds } };
}

