import { Box, Button, Checkbox, FormGroup, Grid, TextField, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React from 'react';

import { AppContext } from '../../AppContext';
import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import SelectionSearch from '../../containers/SelectionSearch';
import User from '../user/user';
import { getDataDumpAPI } from './dataDumpAPI';

export default function DataDumpForm(props: { users: User[] }) {
  const { users } = props;
  const [selectedUser, setSelectedUser] = React.useState<User | null>(null);
  const [dateToggle, setDateToggle] = React.useState(false);
  const [userToggle, setUserToggle] = React.useState(false);
  // const [blobData, setBlobData] = React.useState<any>();
  const [start, setStart] = React.useState(
    // dateToISOLikeButLocal(new Date(), false, true, false)
    ''
  );
  const [end, setEnd] = React.useState(
    // dateToISOLikeButLocal(new Date(), false, true, false)
    ''
  );

  const { app } = React.useContext(AppContext);

  const handleSearchChange = (user: string | User | null) => {
    if (typeof user === 'string') return;
    setSelectedUser(user);
  };

  const handleDateToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setDateToggle(checked);
  };

  const handleUserToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setUserToggle(checked);
  };

  const handleStartChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setStart(e.target.value);
  };

  const handleEnd = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setEnd(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const excelData = await getDataDumpAPI(
        {
          user_uuid: selectedUser?.uuid,
          start: Boolean(start) ? new Date(start).toISOString() : undefined,
          end: Boolean(end) ? new Date(end).toISOString() : undefined,
        },
        app === 'sa'
      );
      const link = document.createElement('a');
      const downloadLink = URL.createObjectURL(excelData);
      link.setAttribute('href', downloadLink);
      link.setAttribute(
        'download',
        `${
          selectedUser ? selectedUser.settings.display_name : 'All Users'
        }.xlsx`
      );
      document.body.appendChild(link); // Required for FF

      link.click();
      // URL.revokeObjectURL(downloadLink);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Section variant="outlined">
      <SectionHeading heading="Data Dump" />
      <FormGroup>
        {users.length > 0 ? (
          <FormGroup>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={userToggle}
                    onChange={handleUserToggle}
                    name="userNameSelected"
                  />
                }
                label="Filter by Study ID"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dateToggle}
                    onChange={handleDateToggle}
                    name="dateSelected"
                  />
                }
                label="Filter by Date"
              />
            </FormGroup>
            <FormGroup>
              {userToggle ? (
                <Box width="100%" my={2}>
                  <Typography variant="h6" gutterBottom>
                    Select User
                  </Typography>
                  <SelectionSearch
                    label="Search Users"
                    options={users.filter(
                      user => user.roles[0] !== 'coping_coach'
                    )}
                    getOptionLabel={option =>
                      `<ID: [${option.study_id}]>  <Initials: [${
                        option.study_initials
                      }]>  [${
                        !Boolean(option.settings.display_name)
                          ? 'Anonymous'
                          : option.settings.display_name
                      }]`
                    }
                    onSelect={handleSearchChange}
                  />
                </Box>
              ) : null}
              {dateToggle ? (
                <Box width="100%" mb={2}>
                  <Typography variant="h6" gutterBottom>
                    Select Time Frame
                  </Typography>
                  <Grid container spacing={6}>
                    <Grid item xs={6}>
                      <TextField
                        type="date"
                        fullWidth
                        variant="filled"
                        label="Start"
                        value={start}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleStartChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        type="date"
                        fullWidth
                        variant="filled"
                        label="End"
                        InputLabelProps={{ shrink: true }}
                        value={end}
                        onChange={handleEnd}
                      />
                    </Grid>
                  </Grid>
                </Box>
              ) : null}
            </FormGroup>
          </FormGroup>
        ) : null}
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disableElevation
            style={{ margin: '0 0 0 auto', display: 'block' }}
          >
            Download Data
          </Button>
        </Grid>
      </FormGroup>
    </Section>
  );
}
