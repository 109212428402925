import React from 'react';
import { Note } from './noteToSelf';
import getNoteToSelfListAPI from './noteToSelfAPI';
import { useSnackbar } from 'notistack';

interface NoteToSelfState {
  isLoading: boolean;
  error: Error | null;
  data: Note[];
}

export default function useNoteToSelfsSlice(userUUID?: string) {
  const [state, setState] = React.useState<NoteToSelfState>({
    isLoading: false,
    error: null,
    data: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  const refresh = React.useCallback(async () => {
    try {
      setState(prev => ({ ...prev, isLoading: true }));
      const notes = await getNoteToSelfListAPI(userUUID);
      setState(prev => ({ ...prev, isLoading: false, data: notes }));
    } catch (reason) {
      const error = new Error(reason);
      setState(prev => ({ ...prev, isLoading: false, error }));
      enqueueSnackbar(reason, { variant: 'error' });
    }
  }, [userUUID, enqueueSnackbar]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return { state, actions: { refresh } };
}
