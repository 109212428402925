import { List, ListItem, ListItemText } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { AppContext } from '../AppContext';
import { useAppDispatch } from '../config/storeConfig';
import { authActions, selectAuthUser } from '../features/auth/authSlice';

// let allRoutes = [
//   { title: 'User Admin', path: '/user-admin' },
//   {
//     title: 'Seeking Safety',
//     subRoutes: [
//       { title: 'User Profile', path: '/ss/profile' },
//       { title: 'Topics', path: '/ss/topics' },
//       { title: 'Cohorts', path: '/ss/cohorts' },
//       { title: 'Interest Groups', path: '/ss/interest-groups' },
//       // { title: 'Chat Moderation', path: '/ss/chat-moderation' },
//       { title: 'Coping Coach', path: '/ss/coping-coach' },
//       { title: 'Office Hours', path: '/ss/office_hours/leader' },
//     ],
//   },
// ];

let allRoutes = [
  { title: 'User Admin', path: '/user-admin' },
  { title: 'User Profile', path: '/profile' },
  { title: 'Emotional Analysis', path: '/analysis' },
  { title: 'Check-in Prompts', path: '/check-in' },
  { title: 'Learn', path: '/learn' },
  { title: 'Topics', path: '/topics' },
  { title: 'Cohorts', path: '/cohorts' },
  { title: 'Interest Groups', path: '/interest-groups' },
  { title: 'Moderation', path: '/moderation' },
  { title: 'Coping Coach', path: '/coping-coach' },
  { title: 'Office Hours', path: '/office_hours/leader' },
  { title: 'Measures', path: '/measures' },
];

export default function NavList() {
  const authUser = useSelector(selectAuthUser);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { app } = React.useContext(AppContext);

  const routes = React.useMemo(() => {
    let routes = allRoutes;
    if (authUser && authUser.roles[0] === 'leader') {
      routes = routes.filter(
        route =>
          route.path !== '/user-admin' &&
          route.path !== '/topics' &&
          route.path !== '/interest-groups' &&
          route.path !== '/moderation' &&
          route.path !== '/coping-coach' &&
          route.path !== '/learn'
      );
    }
    if (app && app === 'sa')
      return routes.filter(
        route =>
          route.path === '/user-admin' ||
          route.path === '/profile' ||
          route.path === '/analysis' ||
          route.path === '/check-in' ||
          route.path === '/learn' ||
          route.path === '/coping-coach'
      );
    return routes.filter(
      route => route.path !== '/analysis' && route.path !== '/check-in'
    );
  }, [authUser, app]);

  const handleLogout = async () => {
    const result = await dispatch(authActions.logout());
    if (typeof result.payload === 'string') {
      enqueueSnackbar(result.payload, { variant: 'error' });
    } else {
      enqueueSnackbar('Logged out.');
    }
  };

  return (
    <List>
      {routes.map((route, i) => {
        const itemText = (
          <ListItemText
            primary={route.title}
            primaryTypographyProps={{
              noWrap: true,
            }}
          />
        );
        // if (route.subRoutes) {
        //   return (
        //     <React.Fragment key={i}>
        //       <ListItem button onClick={handleClick}>
        //         {itemText}
        //         {open ? <ExpandLess /> : <ExpandMore />}
        //       </ListItem>
        //       <Collapse in={open} timeout="auto" unmountOnExit>
        //         <List component="div" disablePadding>
        //           {route.subRoutes.map((route) => (
        //             <ListItem
        //               key={route.title}
        //               component={RouterLink}
        //               to={route.path}
        //               button
        //               selected={location.pathname === route.path}
        //               className={classes.nested}
        //             >
        //               <ListItemText primary={route.title} />
        //             </ListItem>
        //           ))}
        //         </List>
        //       </Collapse>
        //     </React.Fragment>
        //   );
        // }
        return (
          <ListItem
            key={i}
            component={RouterLink}
            to={route.path}
            button
            selected={
              location.pathname === route.path ||
              (route.path === '/user-admin' && location.pathname === '/')
            }
          >
            {itemText}
          </ListItem>
        );
      })}
      <ListItem button onClick={handleLogout}>
        <ListItemText
          primary="Logout"
          primaryTypographyProps={{
            noWrap: true,
          }}
        />
      </ListItem>
    </List>
  );
}
