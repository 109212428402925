import { axios } from '../../config/axiosConfig';
import { SafetyCheck } from './safetyCheck';

export async function getSafetyChecksListAPI(params?: {
  user_uuid?: string;
  cohort_id?: number;
}) {
  try {
    const response = await axios.get<{ safety_checks: SafetyCheck[] }>(
      '/api/v1/safety_checks',
      { params }
    );
    return response.data.safety_checks;
  } catch (error) {
    if (error.response) {
      throw error.response.data.error;
    }
    throw error.config;
  }
}
