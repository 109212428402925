import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { AppContext } from '../../AppContext';
import ContentContainer from '../../components/ContentContainer';
import RouteTitle from '../../components/RouteTitle';
import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import { selectAuthUser } from '../auth/authSlice';
import CohortForm from './CohortForm';
import EditCohort from './EditCohort';
import useCohortsSlice from './useCohortsSlice';

export default function Cohorts() {
  const authUser = useSelector(selectAuthUser);
  const cohortsSlice = useCohortsSlice();
  const { app } = React.useContext(AppContext);

  const isLeader = authUser?.roles[0] === 'leader';

  if (app !== 'ss') {
    return <Redirect to="/" />;
  }

  return (
    <ContentContainer maxWidth="lg">
      <RouteTitle title="Cohorts" />
      <SectionHeading heading="Create Cohort" />
      {!isLeader && (
        <Section variant="outlined">
          <CohortForm cohortsSlice={cohortsSlice} />
        </Section>
      )}

      <EditCohort cohortsSlice={cohortsSlice} />
    </ContentContainer>
  );
}
