import { Box, CircularProgress } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import MaterialTable from 'material-table';
import React from 'react';

import SectionHeading from '../../components/SectionHeading';
import tableIcons from '../../config/tableIcons';
import User from '../user/user';
import useCommitmentsSlice from './useCommitmentsSlice';

interface WeeklyCommitmentsTableProps {
  user: User;
}

export default function WeeklyCommitmentsTable(
  props: WeeklyCommitmentsTableProps
) {
  const { user } = props;
  const commitmentsSlice = useCommitmentsSlice({ user_uuid: user.uuid });
  const { data: commitments, /* error, */ isLoading } = commitmentsSlice.state;
  const { refresh } = commitmentsSlice.actions;

  if (isLoading) {
    return (
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" thickness={2} size={48} />
      </Box>
    );
  }

  return (
    <MaterialTable
      isLoading={isLoading}
      title={<SectionHeading heading="Weekly Commitments" />}
      components={{ Container: Box }}
      icons={tableIcons}
      columns={[
        {
          title: 'Why important?',
          field: 'planA',
        },
        {
          title: 'What challenge?',
          field: 'planB',
        },
        {
          title: 'My commitment',
          field: 'promise',
        },
        { title: 'How?', field: 'reason' },
        { title: 'How will you feel?', field: 'reward' },
        { title: 'Public', field: 'isPublic', type: 'boolean' },
      ]}
      data={commitments.map(commitment => ({
        planA: commitment.plan_a,
        planB: commitment.plan_b,
        promise: commitment.promise,
        reason: commitment.reason,
        reward: commitment.reward,
        isPublic: commitment.is_public,
      }))}
      actions={[
        {
          icon: RefreshIcon,
          tooltip: 'Refresh Data',
          isFreeAction: true,
          onClick: () => refresh(),
        },
      ]}
      options={{
        search: false,
        exportButton: true,
        exportAllData: true,
        exportFileName: `${user.study_id}_weekly_commitments`,
      }}
    />
  );
}
