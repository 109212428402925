import React from 'react';

import CollapsableSection from '../../components/CollapsableSection';
import TopicForm from './TopicForm';
import SectionHeading from '../../components/SectionHeading';

export default function CreateTopic() {
  return (
    <CollapsableSection
      heading={<SectionHeading heading="Create Topic" />}
      initiallyCollapsed
    >
      <TopicForm />
    </CollapsableSection>
  );
}
