import {
  Button,
  createStyles,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Box,
  Modal,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import Cohort from "../cohort/cohort";
import { selectRolesList } from "../role/roleSlice";
import { UseUsersSlice } from "./useUsersSlice";
import { v4 as uuidv4 } from "uuid";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface CreateUserFormProps {
  app: string;
  cohorts: Cohort[];
  usersSlice: UseUsersSlice;
}

export type CreateUserFormData = {
  studyInitials: string;
  studyID: string;
  role: string;
  firstName: string;
  lastName: string;
  email: string;
  cellPhone: string;
  displayName: string;
  cohortID: number | undefined;
  // startDate: number;
  // endDate: number;
  // ss: boolean;
  // sa: boolean;
  // gn: boolean;
  // game: boolean;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    mainGrid: {
      width: "100%",
      margin: theme.spacing(1, 0),
      // padding: theme.spacing(0, 2),
    },
    gridItem: {
      padding: theme.spacing(1),
    },
  })
);

export default function CreateUserForm(props: CreateUserFormProps) {
  const { app, cohorts, usersSlice } = props;
  const actions = usersSlice.actions;
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [newUser, setNewUser] = React.useState<any | null>(null);

  const classes = useStyles();
  let roles = useSelector(selectRolesList);
  console.log(roles);

  if (app === "sa") {
    roles = roles.filter((role) => role.name !== "leader");
  }

  const { register, handleSubmit, errors, control, reset } =
    useForm<CreateUserFormData>();

  const _onSubmit = handleSubmit(async (formData) => {
    try {
      const user = await actions.createUser({
        first_name: formData.firstName === "" ? undefined : formData.firstName,
        last_name: formData.lastName === "" ? undefined : formData.lastName,
        email: formData.email,
        cell_phone: formData.cellPhone === "" ? undefined : formData.cellPhone,
        study_id:
          app === "sa" ? uuidv4().replaceAll("-", "") : formData.studyID,
        study_initials:
          app === "sa" ? uuidv4().replaceAll("-", "") : formData.studyInitials,
        cohort_id: formData.cohortID,
        roles: formData.role,
        settings: {
          display_name:
            formData.displayName === "" ? "Anonymous" : formData.displayName,
        },
      });
      setOpen(!!user);
      setNewUser(user);
      reset({
        /* ss: false, sa: false,  */ /* gn: false, game: false, */ role: "",
      });
    } catch (reason) {
      console.error(reason);
    }
  });

  return (
    <form onSubmit={_onSubmit}>
      <Grid
        className={classes.mainGrid}
        container
        spacing={2}
        justify="space-evenly"
      >
        {/* Disable Study ID and Study initials for SS */}
        {false && app === "ss" ? (
          <>
            <Grid className={classes.gridItem} item xs={12} md={4}>
              <TextField
                fullWidth
                label="Study Intials"
                variant="filled"
                name="studyInitials"
                inputRef={register({ required: "Required" })}
                helperText={errors.studyInitials?.message}
                error={Boolean(errors.studyInitials)}
              />
            </Grid>
            <Grid className={classes.gridItem} item xs={12} md={4}>
              <TextField
                fullWidth
                label="Study ID"
                variant="filled"
                name="studyID"
                inputRef={register({ required: "Required" })}
                helperText={errors.studyID?.message}
                error={Boolean(errors.studyID)}
              />
            </Grid>
          </>
        ) : null}
        <Grid className={classes.gridItem} item xs={12} md={4}>
          <TextField
            fullWidth
            label="Email"
            variant="filled"
            name="email"
            inputRef={register({
              pattern: {
                value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                message: "Invalid format.",
              },
            })}
            helperText={errors.email?.message}
            error={Boolean(errors.email)}
          />
        </Grid>

        <Grid className={classes.gridItem} item xs={12} md={4}>
          <TextField
            fullWidth
            label="First Name (Optional)"
            variant="filled"
            name="firstName"
            inputRef={register({ required: false })}
            helperText={errors.firstName?.message}
            error={Boolean(errors.firstName)}
          />
        </Grid>
        <Grid className={classes.gridItem} item xs={12} md={4}>
          <TextField
            fullWidth
            label="Last Name (Optional)"
            variant="filled"
            name="lastName"
            inputRef={register({ required: false })}
            helperText={errors.lastName?.message}
            error={Boolean(errors.lastName)}
          />
        </Grid>
        <Grid className={classes.gridItem} item xs={12} md={4}>
          <TextField
            fullWidth
            label="Public Display Name (Optional)"
            placeholder="Anonymous..."
            variant="filled"
            name="displayName"
            inputRef={register({ required: false })}
            helperText={errors.displayName?.message}
            error={Boolean(errors.displayName)}
          />
        </Grid>

        <Grid className={classes.gridItem} item xs={12} md={4}>
          <TextField
            fullWidth
            label="Mobile Number (Optional)"
            variant="filled"
            name="cellPhone"
            inputRef={register({
              // pattern: {
              //   value: /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
              //   message: 'Invalid Format',
              // },
              required: false,
            })}
            helperText={errors.cellPhone?.message}
            error={Boolean(errors.cellPhone)}
          />
        </Grid>
        {roles && (
          <Grid className={classes.gridItem} item xs={12} md={4}>
            <FormControl
              variant="filled"
              fullWidth
              error={Boolean(errors.role)}
            >
              <InputLabel id="select-role-label">Select Role</InputLabel>
              <Controller
                as={
                  <Select labelId="select-role-label" id="select-roles">
                    {roles.map((role) => (
                      <MenuItem key={role.name} value={role.name}>
                        {role.name}
                      </MenuItem>
                    ))}
                  </Select>
                }
                name="role"
                rules={{ required: "Selection Required" }}
                control={control}
                defaultValue={""}
              />
              {errors.role && <FormHelperText>Required</FormHelperText>}
            </FormControl>
          </Grid>
        )}
        {app === "ss" && (
          <Grid className={classes.gridItem} item xs={12} md={4}>
            <FormControl
              variant="filled"
              fullWidth
              error={Boolean(errors.cohortID)}
            >
              <InputLabel id="select-cohort-label">Select Cohort</InputLabel>
              <Controller
                as={
                  <Select labelId="select-cohort-label" id="select-cohort">
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {cohorts?.map((cohort) => (
                      <MenuItem key={cohort.id} value={cohort.id}>
                        {cohort.name}
                      </MenuItem>
                    ))}
                  </Select>
                }
                name="cohortID"
                rules={{ required: "Selection Required" }}
                control={control}
                defaultValue=""
              />
              {errors.cohortID && (
                <FormHelperText>{errors.cohortID.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            type="submit"
            style={{ margin: "0 0 0 auto", display: "block" }}
          >
            Create
          </Button>
        </Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Password
            </Typography>
            <Typography id="modal-modal-description">
              {newUser?.password}
            </Typography>
          </Box>
        </Modal>
      </Grid>
    </form>
  );
}
