import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import ContentContainer from '../../components/ContentContainer';
import RouteTitle from '../../components/RouteTitle';
import { selectAuthUser } from '../auth/authSlice';
import CreateTopic from './CreateTopic';
import EditTopic from './EditTopic';
import { AppContext } from '../../AppContext';

export default function Topics() {
  const authUser = useSelector(selectAuthUser);
  const hasAccess = authUser?.roles[0] !== 'leader';
  const { app } = React.useContext(AppContext);

  if (!hasAccess) {
    return <Redirect to="/forbidden" />;
  }

  if (app !== 'ss') {
    return <Redirect to="/" />;
  }

  return (
    <ContentContainer maxWidth="lg">
      <RouteTitle title="Topics" />
      <CreateTopic />
      <EditTopic />
    </ContentContainer>
  );
}
