import { Box } from '@material-ui/core';
import MaterialTable from 'material-table';
import React from 'react';

import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import tableIcons from '../../config/tableIcons';
import Cohort from '../cohort/cohort';
import useTopicsSlice from '../topic/useTopicsSlice';
import useSafetyRefelectionsSlice from './useSafetyRatingsSlice';
import useTopicRefelectionsSlice from './useTopicRatingsSlice';
import User from '../user/user';
import useUsersSlice from '../user/useUsersSlice';
interface RatingsColumns {
  studyInitials: string;
  displayName: string;
  type: string;
  date: string;
  feedback: string;
  rating: number;
}

export default function RatingsTable(props: { cohort: Cohort }) {
  const topicReflectionSlice = useTopicRefelectionsSlice(props.cohort.id);
  const { data: topicReflection } = topicReflectionSlice.state;
  const safetyReflectionSlice = useSafetyRefelectionsSlice(props.cohort.id);
  const { data: safetyReflection } = safetyReflectionSlice.state;
  const topicsSlice = useTopicsSlice();
  const { data: topics, isLoading } = topicsSlice.state;
  const usersSlice = useUsersSlice({ cohort_id: props.cohort.id }, false);

  // creates object for rating table
  const createRatingsData = (
    studyInitials: string,
    displayName: string,
    type: string,
    date: string,
    feedback: string,
    rating: number
  ) => {
    return { studyInitials, displayName, type, date, feedback, rating };
  };

  // creates rows array for 2 tables. 1 is for topic reflection ratings and the other is for safety surprise
  const createRatingTable = () => {
    let topicRows: RatingsColumns[] = [];
    let safetyRows: RatingsColumns[] = [];
    if (topicReflection && safetyReflection && topics.length > 0) {
      topicReflection.forEach(reflection => {
        let topic = topics.filter(topic => {
          return topic.id === reflection.topic_id;
        });
        topicRows.push(
          createRatingsData(
            getUserWithUUID(reflection.user_uuid).study_initials,
            getUserWithUUID(reflection.user_uuid).displayName,
            topic[0].name,
            reflection.modified,
            reflection.feedback,
            reflection.rating
          )
        );
      });
      safetyReflection.forEach(reflection => {
        if (reflection.rating) {
          safetyRows.push(
            createRatingsData(
              getUserWithUUID(reflection.user_uuid).study_initials,
              getUserWithUUID(reflection.user_uuid).displayName,
              'Safety Surprise',
              reflection.modified,
              reflection.text,
              reflection.rating
            )
          );
        }
      });
    }
    return { topicRows, safetyRows };
  };

  //function to return a user with uuid because the endpoint for the ratings does not send any info about user except uuid
  const getUserWithUUID = (uuid: string) => {
    let foundUser = usersSlice.state.data.filter((user: User) => {
      return user.uuid === uuid;
    });
    if (foundUser.length > 0) {
      if (foundUser[0].settings.display_name) {
        return {
          study_initials: foundUser[0].study_initials,
          displayName: foundUser[0].settings.display_name,
        };
      } else {
        return {
          study_initials: 'No Study Initial',
          displayName: 'No Display Name',
        };
      }
    } else {
      return {
        study_initials: 'No Study Initial',
        displayName: 'No Display Name',
      };
    }
  };

  return (
    <React.Fragment>
      <Section variant="outlined">
        <MaterialTable
          title={<SectionHeading heading=" Topic Ratings" />}
          isLoading={isLoading}
          components={{ Container: Box }}
          icons={tableIcons}
          columns={[
            {
              title: 'Date',
              field: 'date',
              defaultSort: 'asc',
              render: rowData => new Date(rowData.date).toLocaleString(),
            },

            {
              title: 'Study Initials',
              field: 'studyInitials',
            },
            {
              title: 'Display Name',
              field: 'displayName',
            },

            {
              title: 'Topic',
              field: 'type',
            },

            {
              title: 'Feedback',
              field: 'feedback',
            },

            {
              title: 'Rating',
              field: 'rating',
            },
          ]}
          data={createRatingTable().topicRows}
          options={{
            exportButton: true,
            exportAllData: true,
            exportFileName: 'Ratings table',
            search: false,
            emptyRowsWhenPaging: false,
          }}
        />
      </Section>
      <Section variant="outlined">
        <MaterialTable
          title={<SectionHeading heading="Safety Surprise Ratings" />}
          isLoading={isLoading}
          components={{ Container: Box }}
          icons={tableIcons}
          columns={[
            {
              title: 'Date',
              field: 'date',
              defaultSort: 'asc',
              render: rowData => new Date(rowData.date).toLocaleString(),
            },

            {
              title: 'Study Initials',
              field: 'studyInitials',
            },
            {
              title: 'Display Name',
              field: 'displayName',
            },

            // {
            //   title: 'Type',
            //   field: 'type',
            // },

            {
              title: 'Feedback',
              field: 'feedback',
            },

            {
              title: 'Rating',
              field: 'rating',
            },
          ]}
          data={createRatingTable().safetyRows}
          options={{
            exportButton: true,
            exportAllData: true,
            exportFileName: 'Ratings table',
            search: false,
            emptyRowsWhenPaging: false,
          }}
        />
      </Section>
    </React.Fragment>
  );
}
