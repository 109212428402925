import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MaterialTable from 'material-table';
import React from 'react';
import User from '../user/user';
import tableIcons from '../../config/tableIcons';
import useProgresssSlice from './useProgressSlice';

export default function ProgressTable(props: { user: User }) {
  const ProgressSlice = useProgresssSlice(props.user.uuid);
  const { data, isLoading } = ProgressSlice.state;
  const safetyTools: any = data?.set_up_safety_tools;
  // needed to track 84 days for each user
  let days: any = {
    day0: 0,
    day1: 0,
    day2: 0,
    day3: 0,
    day4: 0,
    day5: 0,
    day6: 0,
    day7: 0,
    day8: 0,
    day9: 0,
    day10: 0,
    day11: 0,
    day12: 0,
    day13: 0,
    day14: 0,
    day15: 0,
    day16: 0,
    day17: 0,
    day18: 0,
    day19: 0,
    day20: 0,
    day21: 0,
    day22: 0,
    day23: 0,
    day24: 0,
    day25: 0,
    day26: 0,
    day27: 0,
    day28: 0,
    day29: 0,
    day30: 0,
    day31: 0,
    day32: 0,
    day33: 0,
    day34: 0,
    day35: 0,
    day36: 0,
    day37: 0,
    day38: 0,
    day39: 0,
    day40: 0,
    day41: 0,
    day42: 0,
    day43: 0,
    day44: 0,
    day45: 0,
    day46: 0,
    day47: 0,
    day48: 0,
    day49: 0,
    day50: 0,
    day51: 0,
    day52: 0,
    day53: 0,
    day54: 0,
    day55: 0,
    day56: 0,
    day57: 0,
    day58: 0,
    day59: 0,
    day60: 0,
    day61: 0,
    day62: 0,
    day63: 0,
    day64: 0,
    day65: 0,
    day66: 0,
    day67: 0,
    day68: 0,
    day69: 0,
    day70: 0,
    day71: 0,
    day72: 0,
    day73: 0,
    day74: 0,
    day75: 0,
    day76: 0,
    day77: 0,
    day78: 0,
    day79: 0,
    day80: 0,
    day81: 0,
    day82: 0,
    day83: 0,
  };

  // Creates an object for Safety Tools Progress for Table
  const createSafetyToolsData = (
    category: string,
    response: boolean | string
  ) => {
    return { category, response };
  };

  // Converts Safety Tool Progress Data into Rows for Table
  const createSafetyToolsRows = () => {
    let rows: any = [];
    if (data) {
      rows = [
        createSafetyToolsData(
          'Safe / Unsafe Pictures',
          safetyTools.safe_unsafe_picture
        ),
        createSafetyToolsData(
          'Safety Plan Wizard',
          safetyTools.safety_plan_wizard
        ),
        createSafetyToolsData(
          'Talk to Safe Self',
          safetyTools.talk_to_safe_self
        ),
      ];
    }
    return rows;
  };

  // Creates an object for Completion Progress for Table
  const createProgressData = (
    category: string,
    userScore: number,
    cohortScore: number
  ) => {
    return { category, userScore, cohortScore };
  };

  // Converts Completion Progress Data into Rows for Table
  const createProgressTable = () => {
    let rows: any = [];
    if (data) {
      rows = [
        createProgressData(
          'Prepare for a Trigger',
          data.prepare_for_a_trigger.my_trigger_preps,
          data.prepare_for_a_trigger.avg_cohort_trigger_preps
        ),
        createProgressData(
          'Learn Reflection',
          data.learn_reflections.my_reflections,
          data.learn_reflections.avg_cohort_reflections
        ),
        createProgressData(
          'My Posts(PUB)',
          data.social_feed_posts.my_posts,
          data.social_feed_posts.avg_cohort_posts
        ),
        createProgressData(
          'My Safe Place(PRIV)',
          data.my_safe_place_posts.my_posts,
          data.my_safe_place_posts.avg_cohort_posts
        ),
        createProgressData(
          'Daily Safety Check',
          data.daily_safety_check.my_checks,
          data.daily_safety_check.avg_cohort_checks
        ),
        createProgressData(
          'Daily Safety Reflection',
          data.daily_safety_reflection.my_reflections,
          data.daily_safety_reflection.avg_cohort_reflections
        ),
        createProgressData(
          'Daily Quiz',
          data.daily_quiz.my_quizzes,
          data.daily_quiz.avg_cohort_quizzes
        ),
        createProgressData(
          'Daily Quest',
          data.daily_quest.my_quests,
          data.daily_quest.avg_cohort_quests
        ),
        createProgressData(
          'Safety Plan Check-in',
          data.safety_plan_check.my_checks,
          data.safety_plan_check.avg_cohort_checks
        ),
        createProgressData(
          'Safety Boomerang',
          data.safety_boomerang.my_boomerangs,
          data.safety_boomerang.avg_cohort_boomerangs
        ),
        createProgressData(
          'Safety Surprise',
          data.safety_surprise.my_surprises,
          data.safety_surprise.avg_cohort_surprises
        ),
      ];
    }
    return rows;
  };

  // Creates an object for Weekly Progress for Table
  const createWeeklyProgressData = (
    topic: string,
    commitments: boolean,
    powerUps: boolean,
    joinACall: string | null
  ) => {
    return { topic, commitments, powerUps, joinACall };
  };

  // Converts Weekly Commitments Data into Boolean for Rows (Only 1 per week so takes the First)
  const weeklyCommitments = (i: number) => {
    let commitmentBoolean = true;

    if (data) {
      if (data.weekly_commitment[i].did_commitment === true) {
        commitmentBoolean = true;
      } else if (data.weekly_commitment[i].did_commitment === false) {
        commitmentBoolean = false;
      }
    }
    return commitmentBoolean;
  };

  // Converts Weekly Powerups Data into Boolean for Rows  (Only 1 per week so takes the First)
  const weeklyPowerUps = (i: number) => {
    let powerupBoolean = true;

    if (data) {
      if (data.weekly_power_up[i].did_power_up === true) {
        powerupBoolean = true;
      } else if (data.weekly_power_up[i].did_power_up === false) {
        powerupBoolean = false;
      }
    }
    return powerupBoolean;
  };
  // Converts Weekly Attendance Data into Boolean for Rows  (Only 1 per week so takes the First)
  const weeklyAttendance = (i: number) => {
    let weeeklyAttendance: string | null = 'None';
    if (data) {
      if (!data.weekly_meeting_attendance[i]) {
        weeeklyAttendance = 'None';
      } else {
        if (data.weekly_meeting_attendance[i].attendance === null) {
          weeeklyAttendance = 'None';
        } else {
          weeeklyAttendance = data.weekly_meeting_attendance[i].attendance;
        }
      }
    }
    return weeeklyAttendance;
  };
  // Makes a list of all topics for Each Row
  const allTopicsSoFar = () => {
    let allTopics: any = [];
    if (data) {
      data?.weekly_commitment.forEach(commitment => {
        if (commitment.is_future === false) {
          allTopics.push(commitment.topic_name);
        }
      });
    }
    return allTopics;
  };

  // Converts Weekly Progress Data into Rows for Table
  const createWeeklyProgressTable = () => {
    let rows: any = [];
    if (data) {
      allTopicsSoFar().forEach((topic: string, i: number) => {
        rows.push(
          createWeeklyProgressData(
            topic,
            weeklyCommitments(i),
            weeklyPowerUps(i),
            weeklyAttendance(i)
          )
        );
      });
    }
    return rows;
  };

  // Creates an object for Trigger Severity Progress for Table
  const createPorgressSeverityData = (
    week: string | null,
    monday: number | null,
    tuesday: number | null,
    wednesday: number | null,
    thursday: number | null,
    friday: number | null,
    saturday: number | null,
    sunday: number | null
  ) => {
    return {
      week,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
    };
  };

  // Converts Safety Tool Progress Data into Rows for Table
  const createPorgressSeveritysRows = () => {
    let rows: any = [];

    if (data) {
      if (data.trigger_severity.length > 0) {
        data.trigger_severity.forEach((incident, i) => {
          days[`day${i}`] = incident.severity;
        });

        rows = [
          createPorgressSeverityData(
            'Week 1',
            days.day0,
            days.day1,
            days.day2,
            days.day3,
            days.day4,
            days.day5,
            days.day6
          ),
          createPorgressSeverityData(
            'Week 2',
            days.day7,
            days.day8,
            days.day9,
            days.day10,
            days.day11,
            days.day12,
            days.day13
          ),
          createPorgressSeverityData(
            'Week 3',
            days.day14,
            days.day15,
            days.day16,
            days.day17,
            days.day18,
            days.day19,
            days.day20
          ),
          createPorgressSeverityData(
            'Week 4',
            days.day21,
            days.day22,
            days.day23,
            days.day24,
            days.day25,
            days.day26,
            days.day27
          ),
          createPorgressSeverityData(
            'Week 5',
            days.day28,
            days.day29,
            days.day30,
            days.day31,
            days.day32,
            days.day33,
            days.day34
          ),
          createPorgressSeverityData(
            'Week 6',
            days.day35,
            days.day36,
            days.day37,
            days.day38,
            days.day39,
            days.day40,
            days.day41
          ),
          createPorgressSeverityData(
            'Week 7',
            days.day42,
            days.day43,
            days.day44,
            days.day45,
            days.day46,
            days.day47,
            days.day48
          ),
          createPorgressSeverityData(
            'Week 8',
            days.day49,
            days.day50,
            days.day51,
            days.day52,
            days.day53,
            days.day54,
            days.day55
          ),
          createPorgressSeverityData(
            'Week 9',
            days.day56,
            days.day57,
            days.day58,
            days.day59,
            days.day60,
            days.day61,
            days.day62
          ),
          createPorgressSeverityData(
            'Week 10',
            days.day63,
            days.day64,
            days.day65,
            days.day66,
            days.day67,
            days.day68,
            days.day69
          ),
          createPorgressSeverityData(
            'Week 11',
            days.day70,
            days.day71,
            days.day72,
            days.day73,
            days.day74,
            days.day75,
            days.day76
          ),
          createPorgressSeverityData(
            'Week 12',
            days.day77,
            days.day78,
            days.day79,
            days.day80,
            days.day81,
            days.day82,
            days.day83
          ),
        ];
      }
    }
    return rows;
  };

  return (
    <Accordion
      variant="outlined"
      TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
      expanded={true}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
      >
        <Typography>{'Progress'}</Typography>
      </AccordionSummary>

      <AccordionDetails style={{ display: 'block' }}>
        {/* <Section variant="outlined"> */}

        <Accordion
          variant="outlined"
          TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography>{'Completion Progress'}</Typography>
          </AccordionSummary>

          <AccordionDetails style={{ display: 'block' }}>
            {data ? (
              <MaterialTable
                isLoading={isLoading}
                components={{ Container: Box }}
                icons={tableIcons}
                columns={[
                  {
                    title: 'Category',
                    field: 'category',
                  },

                  {
                    title: `${props.user.study_id}`,
                    field: 'userScore',
                  },

                  {
                    title: 'Cohort Avg',
                    field: 'cohortScore',
                  },
                ]}
                data={createProgressTable()}
                options={{
                  showTitle: false,
                  exportButton: true,
                  exportAllData: true,
                  exportFileName: 'Completion Progress',
                  search: false,
                  emptyRowsWhenPaging: false,
                  pageSize: 20,
                }}
              />
            ) : (
              <div>"not Working"</div>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          variant="outlined"
          TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography>{'Safety Tools Progress'}</Typography>
          </AccordionSummary>

          <AccordionDetails style={{ display: 'block' }}>
            {data ? (
              <MaterialTable
                isLoading={isLoading}
                components={{ Container: Box }}
                icons={tableIcons}
                columns={[
                  {
                    title: 'Category',
                    field: 'category',
                  },

                  {
                    title: `${props.user.study_id}`,
                    field: 'response',
                    render: rowData => {
                      if (rowData.response === true) {
                        return 'True';
                      } else if (rowData.response === false) {
                        return 'False';
                      } else {
                        return rowData.response;
                      }
                    },
                  },
                ]}
                data={createSafetyToolsRows()}
                options={{
                  showTitle: false,
                  exportButton: true,
                  exportAllData: true,
                  exportFileName: 'Safety Tools Progress',
                  search: false,
                  emptyRowsWhenPaging: false,
                  pageSize: 20,
                }}
              />
            ) : (
              <div>"not Working"</div>
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion
          variant="outlined"
          TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography>{'Trigger Severity Progress'}</Typography>
          </AccordionSummary>

          <AccordionDetails style={{ display: 'block' }}>
            {data ? (
              <MaterialTable
                title={'Severity Level'}
                isLoading={isLoading}
                components={{ Container: Box }}
                icons={tableIcons}
                columns={[
                  {
                    title: 'Week',
                    field: 'week',
                  },

                  {
                    title: 'Monday',
                    field: 'monday',
                    render: rowData => {
                      if (rowData.monday === 0) {
                        return 'No Input';
                      } else {
                        return `Level: ${rowData.monday}`;
                      }
                    },
                  },
                  {
                    title: 'Tuesday',
                    field: 'tuesday',
                    render: rowData => {
                      if (rowData.tuesday === 0) {
                        return 'No Input';
                      } else {
                        return `Level: ${rowData.tuesday}`;
                      }
                    },
                  },
                  {
                    title: 'Wednesday',
                    field: 'wednesday',
                    render: rowData => {
                      if (rowData.wednesday === 0) {
                        return 'No Input';
                      } else {
                        return `Level: ${rowData.wednesday}`;
                      }
                    },
                  },
                  {
                    title: 'Thursday',
                    field: 'thursday',
                    render: rowData => {
                      if (rowData.thursday === 0) {
                        return 'No Input';
                      } else {
                        return `Level: ${rowData.thursday}`;
                      }
                    },
                  },
                  {
                    title: 'Friday',
                    field: 'friday',
                    render: rowData => {
                      if (rowData.friday === 0) {
                        return 'No Input';
                      } else {
                        return `Level: ${rowData.friday}`;
                      }
                    },
                  },
                  {
                    title: 'Saturday',
                    field: 'saturday',
                    render: rowData => {
                      if (rowData.saturday === 0) {
                        return 'No Input';
                      } else {
                        return `Level: ${rowData.saturday}`;
                      }
                    },
                  },
                  {
                    title: 'Sunday',
                    field: 'sunday',
                    render: rowData => {
                      if (rowData.sunday === 0) {
                        return 'No Input';
                      } else {
                        return `Level: ${rowData.sunday}`;
                      }
                    },
                  },
                ]}
                data={createPorgressSeveritysRows()}
                options={{
                  showTitle: false,
                  exportButton: true,
                  exportAllData: true,
                  exportFileName: 'Trigger Severity Progress',
                  search: false,
                  emptyRowsWhenPaging: false,
                  pageSize: 20,
                }}
              />
            ) : (
              <div>"not Working"</div>
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion
          variant="outlined"
          TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography>{'Weekly Progress'}</Typography>
          </AccordionSummary>

          <AccordionDetails style={{ display: 'block' }}>
            {data ? (
              <MaterialTable
                isLoading={isLoading}
                components={{ Container: Box }}
                icons={tableIcons}
                columns={[
                  {
                    title: 'Topic',
                    field: 'topic',
                  },
                  {
                    title: 'Commitments',
                    field: 'commitments',
                  },
                  {
                    title: 'Power Ups',
                    field: 'powerUps',
                  },
                  {
                    title: 'Join a Call',
                    field: 'joinACall',
                  },
                ]}
                data={createWeeklyProgressTable()}
                options={{
                  showTitle: false,
                  exportButton: true,
                  exportAllData: true,
                  exportFileName: 'Weekly Progress',
                  search: false,
                  emptyRowsWhenPaging: false,
                  pageSize: 20,
                }}
              />
            ) : null}
          </AccordionDetails>
        </Accordion>
      </AccordionDetails>
    </Accordion>
  );
}
