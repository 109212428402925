import {
  Box,
  Button,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import CollapsableSection from '../../components/CollapsableSection';
import PopupButton from '../../components/PopupButton';
import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import { Question } from './quiz';
import { updateTopicQuizAPI } from './quizAPI';
import useQuizzesSlice from './useQuizzesSlice';

const useStyles = makeStyles(theme =>
  createStyles({
    promptActions: {
      margin: theme.spacing(1, 0),
      '& button': {
        margin: theme.spacing(0, 0.5),
        // display: 'none'
      },
    },
  })
);

interface QuizFormProps {
  topicID: number;
  topicName: string;
}

interface QuizFormData {
  questions: Question[];
}

export default function QuizForm(props: QuizFormProps) {
  const { topicID, topicName } = props;
  const { enqueueSnackbar } = useSnackbar();
  const quizzesSlice = useQuizzesSlice({ topic_id: topicID });
  const { data: quizzes, isLoading } = quizzesSlice.state;
  const actions = quizzesSlice.actions;

  const quiz = quizzes.length > 0 ? quizzes[0] : null;

  const classes = useStyles();

  const {
    register,
    handleSubmit,
    /* errors, */ control,
    reset,
  } = useForm<QuizFormData>({
    defaultValues: { questions: quiz?.questions ?? [] },
  });

  const {
    fields: questions,
    append,
    // prepend,
    remove,
    // swap,
    move,
    // insert,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'questions', // unique name for your Field Array
    keyName: 'key', //default to "id", you can change the key name
  });

  const handleQuizCreation = async () => {
    try {
      await actions.createQuiz({
        topic_id: topicID,
        name: topicName,
        questions: null,
      });
    } catch (error) {
      console.error(error);
    }
  };

  //! Consider making this function smaller
  const handleAppendNewQuestion = React.useCallback(
    (type: string) => () => {
      if (type !== 'categorize') {
        append({
          question: '',
          type,
          choices: type === 'true_false' ? ['', ''] : ['', '', '', ''],
          correct_choice_indexes: [],
          id: null,
          text_if_correct: '',
          text_if_incorrect: '',
        });
      } else {
        append({
          question: '',
          type,
          choices: ['', '', '', ''],
          correct_choice_indexes: [],
          id: null,
          label_a: '',
          label_b: '',
          text_if_correct: '',
          text_if_incorrect: '',
        });
      }
    },
    [append]
  );

  const handleDeleteQuestion = React.useCallback(
    (index: number) => () => {
      remove(index);
    },
    [remove]
  );

  const handleMove = React.useCallback(
    (from: number, to: number) => () => {
      move(from, to);
    },
    [move]
  );

  React.useEffect(() => {
    if (quiz) {
      reset({ questions: quiz.questions });
    }
  }, [quiz, reset]);

  const _onSubmit = handleSubmit(async (formData: QuizFormData) => {
    // console.log(formData)
    try {
      const reformattedQuestions = formData.questions.map(question => {
        return {
          ...question,
          correct_choice_indexes: question.correct_choice_indexes.reduce<
            number[]
          >((accum, curr, index) => {
            if (curr === true) {
              accum.push(index);
            }
            return accum;
          }, []),
        };
      });
      if (quiz) {
        //* not using action because it causes undesireable UI render
        await updateTopicQuizAPI(quiz.id, {
          topic_id: topicID,
          questions: reformattedQuestions,
        });
        enqueueSnackbar(`Quiz UPDATED`, { variant: 'success' });
      } else {
        throw new Error('No quiz associated to create a questions.');
      }
    } catch (error) {
      console.error(error);
    }
  });

  if (isLoading) {
    return (
      <Box
        width="100%"
        height={300}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="body1">Loading...</Typography>
      </Box>
    );
  }

  return (
    <Section variant="outlined">
      <SectionHeading heading="Daily Topic Quiz" />
      {quiz ? (
        <form onSubmit={_onSubmit}>
          <List aria-label="questions">
            {questions.map((q, i) => {
              let correctChoices: boolean[] = q.choices.map((c: any) => false);
              if (q.type === 'categorize') {
                correctChoices = correctChoices.concat(correctChoices);
              }
              q.correct_choice_indexes.forEach((el: number) => {
                correctChoices[el] = true;
              });
              return (
                <ListItem key={q.id} style={{ display: 'block' }}>
                  <CollapsableSection
                    heading={
                      <Typography variant="h6">{`Question No. ${i + 1} [${
                        q.type
                      }]`}</Typography>
                    }
                    initiallyCollapsed
                    footer={
                      <Box className={classes.promptActions}>
                        <Button
                          variant="contained"
                          color="primary"
                          aria-label="move up"
                          disableElevation
                          disabled={i === 0}
                          onClick={handleMove(i, i - 1)}
                        >
                          <ArrowUpwardIcon />
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          aria-label="move down"
                          disabled={i === questions.length - 1}
                          disableElevation
                          onClick={handleMove(i, i + 1)}
                        >
                          <ArrowDownwardIcon />
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          aria-label="delete"
                          disableElevation
                          onClick={handleDeleteQuestion(i)}
                        >
                          <DeleteIcon />
                        </Button>
                      </Box>
                    }
                    style={{
                      width: '100%',
                      margin: '4px 0',
                      padding: '4px 16px',
                    }}
                  >
                    <TextField
                      fullWidth
                      // disabled
                      size="small"
                      margin="normal"
                      label="ID"
                      variant="filled"
                      name={`questions[${i}].id`}
                      inputRef={register()}
                      defaultValue={q.id}
                      style={{ display: 'none' }}
                    />
                    <TextField
                      fullWidth
                      // disabled
                      size="small"
                      margin="normal"
                      label="Type"
                      variant="filled"
                      name={`questions[${i}].type`}
                      inputRef={register()}
                      defaultValue={q.type}
                      style={{ display: 'none' }}
                    />
                    <Controller
                      control={control}
                      name={`questions[${i}].question`}
                      defaultValue={q.question}
                      render={(
                        { onChange, onBlur, value, name, ref },
                        { invalid, isTouched, isDirty }
                      ) => (
                        <TextField
                          fullWidth
                          size="small"
                          margin="normal"
                          label="Question"
                          variant="filled"
                          name={name}
                          onChange={e => onChange(e.target.value)}
                          onBlur={onBlur}
                          inputRef={ref}
                          value={value}
                          helperText={`Character count: ${value.length}`}
                        />
                      )}
                    />
                    <Section variant="outlined">
                      <Grid container alignItems="center" spacing={2}>
                        {q.type === 'categorize' && (
                          <React.Fragment>
                            <Grid item xs={12}>
                              <Typography variant="subtitle1" align="left">
                                Categories
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Controller
                                control={control}
                                name={`questions[${i}].label_a`}
                                defaultValue={q.label_a}
                                render={(
                                  { onChange, onBlur, value, name, ref },
                                  { invalid, isTouched, isDirty }
                                ) => (
                                  <TextField
                                    fullWidth
                                    size="small"
                                    margin="normal"
                                    label="Category A"
                                    variant="filled"
                                    name={name}
                                    onChange={e => onChange(e.target.value)}
                                    onBlur={onBlur}
                                    inputRef={ref}
                                    value={value}
                                    helperText={`Character count: ${value.length}`}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Controller
                                control={control}
                                name={`questions[${i}].label_b`}
                                defaultValue={q.label_b}
                                render={(
                                  { onChange, onBlur, value, name, ref },
                                  { invalid, isTouched, isDirty }
                                ) => (
                                  <TextField
                                    fullWidth
                                    size="small"
                                    margin="normal"
                                    label="Category B"
                                    variant="filled"
                                    name={name}
                                    onChange={e => onChange(e.target.value)}
                                    onBlur={onBlur}
                                    inputRef={ref}
                                    value={value}
                                    helperText={`Character count: ${value.length}`}
                                  />
                                )}
                              />
                            </Grid>
                          </React.Fragment>
                        )}

                        <Grid item xs={q.type === 'categorize' ? 9 : 10}>
                          <Typography variant="subtitle1" align="left">
                            Answers
                          </Typography>
                        </Grid>
                        <Grid item xs={q.type === 'categorize' ? 3 : 2}>
                          <Typography variant="subtitle1" align="center">
                            Correct
                          </Typography>
                        </Grid>

                        {q.choices.map((choice: string, index: number) => (
                          <React.Fragment key={index}>
                            <Grid item xs={q.type === 'categorize' ? 9 : 10}>
                              <Controller
                                control={control}
                                name={`questions[${i}].choices[${index}]`}
                                defaultValue={choice}
                                render={(
                                  { onChange, onBlur, value, name, ref },
                                  { invalid, isTouched, isDirty }
                                ) => (
                                  <TextField
                                    fullWidth
                                    size="small"
                                    margin="normal"
                                    label={
                                      q.type === 'true_false'
                                        ? index === 0
                                          ? 'True'
                                          : 'False'
                                        : String.fromCharCode(
                                            'A'.charCodeAt(0) + index
                                          )
                                    }
                                    variant="filled"
                                    name={name}
                                    onChange={e => onChange(e.target.value)}
                                    onBlur={onBlur}
                                    inputRef={ref}
                                    value={value}
                                    helperText={`Character count: ${value.length}`}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={q.type === 'categorize' ? 3 : 2}
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              {q.type === 'categorize' ? (
                                <FormControl component="fieldset" fullWidth>
                                  <FormGroup
                                    aria-label="select category"
                                    row
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-evenly',
                                    }}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Controller
                                          // as={Checkbox}
                                          name={`questions[${i}].correct_choice_indexes[${
                                            index * 2
                                          }]`}
                                          control={control}
                                          defaultValue={
                                            correctChoices[index * 2]
                                          }
                                          render={(
                                            {
                                              onChange,
                                              onBlur,
                                              value,
                                              name,
                                              ref,
                                            },
                                            { invalid, isTouched, isDirty }
                                          ) => (
                                            <Checkbox
                                              name={name}
                                              onBlur={onBlur}
                                              onChange={e =>
                                                onChange(e.target.checked)
                                              }
                                              checked={value}
                                              inputRef={ref}
                                            />
                                          )}
                                        />
                                      }
                                      label="A"
                                      labelPlacement="end"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Controller
                                          // as={Checkbox}
                                          name={`questions[${i}].correct_choice_indexes[${
                                            index * 2 + 1
                                          }]`}
                                          control={control}
                                          defaultValue={
                                            correctChoices[index * 2 + 1]
                                          }
                                          render={(
                                            {
                                              onChange,
                                              onBlur,
                                              value,
                                              name,
                                              ref,
                                            },
                                            { invalid, isTouched, isDirty }
                                          ) => (
                                            <Checkbox
                                              name={name}
                                              onBlur={onBlur}
                                              onChange={e =>
                                                onChange(e.target.checked)
                                              }
                                              checked={value}
                                              inputRef={ref}
                                            />
                                          )}
                                        />
                                      }
                                      label="B"
                                      labelPlacement="end"
                                    />
                                  </FormGroup>
                                </FormControl>
                              ) : (
                                <Controller
                                  // as={Checkbox}
                                  name={`questions[${i}].correct_choice_indexes[${index}]`}
                                  control={control}
                                  defaultValue={correctChoices[index]}
                                  render={(
                                    { onChange, onBlur, value, name, ref },
                                    { invalid, isTouched, isDirty }
                                  ) => (
                                    <Checkbox
                                      name={name}
                                      onBlur={onBlur}
                                      onChange={e => onChange(e.target.checked)}
                                      checked={value}
                                      inputRef={ref}
                                    />
                                  )}
                                />
                              )}
                            </Grid>
                          </React.Fragment>
                        ))}
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" align="left">
                            Responses
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Controller
                            control={control}
                            name={`questions[${i}].text_if_correct`}
                            defaultValue={q.text_if_correct}
                            render={(
                              { onChange, onBlur, value, name, ref },
                              { invalid, isTouched, isDirty }
                            ) => (
                              <TextField
                                fullWidth
                                size="small"
                                margin="normal"
                                label="Response for correct answer"
                                multiline
                                variant="filled"
                                name={name}
                                onChange={e => onChange(e.target.value)}
                                onBlur={onBlur}
                                inputRef={ref}
                                value={value}
                                helperText={`Character count: ${value.length}`}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Controller
                            control={control}
                            name={`questions[${i}].text_if_incorrect`}
                            defaultValue={q.text_if_incorrect}
                            render={(
                              { onChange, onBlur, value, name, ref },
                              { invalid, isTouched, isDirty }
                            ) => (
                              <TextField
                                fullWidth
                                size="small"
                                margin="normal"
                                label="Response for wrong answer"
                                multiline
                                variant="filled"
                                name={name}
                                onChange={e => onChange(e.target.value)}
                                onBlur={onBlur}
                                inputRef={ref}
                                value={value}
                                helperText={`Character count: ${value.length}`}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Section>
                  </CollapsableSection>
                </ListItem>
              );
            })}
          </List>
          {questions.length === 0 && (
            <Typography variant="subtitle1" align="center">
              Quiz does not have any questions. Start creating some!
            </Typography>
          )}
          <Box my={1}>
            <PopupButton
              options={[
                {
                  label: 'True or False',
                  onClickHandler: handleAppendNewQuestion('true_false'),
                },
                {
                  label: 'Multiple Choice',
                  onClickHandler: handleAppendNewQuestion('multiple_choice'),
                },
                {
                  label: 'Fill in the Blank',
                  onClickHandler: handleAppendNewQuestion('fill_in_the_blank'),
                },
                {
                  label: 'Categorize',
                  // disabled: true,
                  onClickHandler: handleAppendNewQuestion('categorize'),
                },
              ]}
            >
              Add Question
            </PopupButton>
          </Box>
          <Box my={1}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disableElevation
            >
              Update Quiz
            </Button>
          </Box>
        </form>
      ) : (
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleQuizCreation}
            disableElevation
          >
            Create Daily Quiz
          </Button>
        </Box>
      )}
    </Section>
  );
}
