import {
  Box,
  Button,
  Checkbox,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AppContext } from '../../AppContext';

import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import SelectionSearch from '../../containers/SelectionSearch';
import Cohort from '../cohort/cohort';
import User from '../user/user';
import useNotificationsSlice from './useNotificationsSlice';
import useUsersSlice from '../user/useUsersSlice';

interface NotificationData {
  message: string;
}

export default function NotifcationTable(props: {
  users: User[];
  cohorts: Cohort[];
}) {
  const [selectUser, setSelectUser] = React.useState<string>('None');
  const [selectCohort, setSelectCohort] = React.useState<string | number>(
    'None'
  );
  const { app } = React.useContext(AppContext);
  const [userFilter, setUserFilter] = React.useState(false);
  const [filterOption, setFilterOption] = React.useState('false');
  const usersSlice = useUsersSlice({ registered: true }, app === 'sa');
  const users = props.users;
  const notificationSlice = useNotificationsSlice();
  const {
    // register,
    handleSubmit,
    // errors,
    control,
    // reset,
  } = useForm<NotificationData>();
  React.useEffect(() => {
    if (userFilter === false) {
      setSelectUser('None');
    }
  }, [userFilter]);
  // if no user is selected all users will be notified
  const handleUserToggle = () => {
    setUserFilter(!userFilter);
  };
  const handleChange = React.useCallback((user: any) => {
    if (user !== null) {
      setSelectUser(user.uuid);
    } else if (user === null) {
      setSelectUser('None');
    }
  }, []);
  const handleCohortChange = React.useCallback((cohort: any) => {
    if (cohort !== null) {
      setSelectCohort(cohort.id);
    } else if (cohort === null) {
      setSelectCohort('None');
    }
  }, []);

  //compares all users with registered users to assign registered or not
  React.useEffect(() => {
    users.forEach((user: any) => {
      user.registered = 'Not Registered';
      if (usersSlice.state.data.length > 0) {
        usersSlice.state.data.forEach(regUser => {
          if (user.uuid === regUser.uuid) {
            user.registered = 'Registered';
          }
        });
      }
    });
  }, [users, usersSlice.state.data]);
  // Swaps between filtering by cohort or user
  const handleFilterToggle = (e: any) => {
    setFilterOption(e.target.value);
  };

  const onSubmit = handleSubmit((formData: any) => {
    let params = {
      notification_text: formData.message,
    };
    notificationSlice.actions.createNotification(
      params,
      selectUser,
      selectCohort,
      app
    );
  });

  return (
    <React.Fragment>
      <Section>
        <SectionHeading
          heading={`Send Notification to ${
            app === 'ss' ? 'Seeking Safety' : 'Self Aware'
          } Users`}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={userFilter}
              onChange={handleUserToggle}
              name="userNameSelected"
            />
          }
          label="User Name Filter"
        />
        {userFilter ? (
          <Grid item>
            {app === 'ss' ? (
              <RadioGroup
                aria-label="filter option"
                name="filter"
                value={filterOption}
                onChange={handleFilterToggle}
              >
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="User"
                />
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Cohort"
                />
              </RadioGroup>
            ) : null}

            {filterOption === 'false' ? (
              <Box width="100%" my={2}>
                <Typography>{'Select User'}</Typography>
                <SelectionSearch
                  label="Search Users"
                  options={users as any}
                  getOptionLabel={option =>
                    `${option.study_id} <${option.registered}>`
                  }
                  onSelect={handleChange}
                />
              </Box>
            ) : (
              <Box width="100%" my={2}>
                <Typography>{'Select Cohort'}</Typography>
                <SelectionSearch
                  label="Search Cohort"
                  options={props.cohorts}
                  getOptionLabel={option => `${option.name}`}
                  onSelect={handleCohortChange}
                />
              </Box>
            )}
          </Grid>
        ) : null}

        <div>
          <form onSubmit={onSubmit}>
            <Typography>{'Message'}</Typography>
            <Controller
              as={
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  defaultValue=" "
                  variant="outlined"
                />
              }
              name="message"
              control={control}
            />
            <Box width="100%" my={1} display="flex" justifyContent="flex-end">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disableElevation
              >
                {'Send Message'}
              </Button>
            </Box>
          </form>
        </div>
      </Section>
    </React.Fragment>
  );
}
