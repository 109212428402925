import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { AppDispatch, AppState } from '../../config/storeConfig';
import { RequestState } from '../../requestState';
import { roleActions } from '../role/roleSlice';
import { topicActions } from '../topic/topicSlice';
import User from '../user/user';
import { loginAPI, LoginArgs, logoutAPI } from './authAPI';

export interface AuthState extends RequestState {
  data: User | null;
}

const initAuthState: AuthState = {
  loading: false,
  errorMsg: null,
  data: null,
};

const login = createAsyncThunk<
  { access_token: string; user: User },
  LoginArgs | undefined,
  { dispatch: AppDispatch; state: AppState; rejectValue: string }
>(
  'auth/login',
  async (args: LoginArgs | undefined, { dispatch, rejectWithValue }) => {
    try {
      const payload = await loginAPI(args);
      dispatch(roleActions.getRoles(true));
      dispatch(topicActions.getTopicsList());
      return payload;
    } catch (reason) {
      return rejectWithValue(reason);
    }
  },
  {
    condition: (credentials, { getState, extra }) => {
      const { auth } = getState();
      if (auth.loading === true || auth.data !== null) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
      return true;
    },
  }
);

const logout = createAsyncThunk<
  void,
  undefined,
  { dispatch: AppDispatch; state: AppState; rejectValue: string }
>('auth/logout', async (args: undefined, { rejectWithValue }) => {
  try {
    return await logoutAPI();
  } catch (reason) {
    return rejectWithValue(reason);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState: initAuthState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.user;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload ?? 'An error occured.';
      })
      .addCase(logout.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.loading = false;
        state.data = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload ?? 'An error occured.';
      });
  },
});

export const selectAuth = createSelector(
  (state: AppState) => state.auth,
  (auth) => auth
);

export const selectAuthUser = createSelector(
  (state: AppState) => state.auth.data,
  (data) => data
);

const asyncActions = {
  login,
  logout,
};

export const authActions = { ...authSlice.actions, ...asyncActions };
export default authSlice.reducer;
