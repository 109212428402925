import React from 'react';
import { HelpCope } from './helpCope';
import { getHelpCopeListAPI } from './helpCopeAPI';
import { useSnackbar } from 'notistack';

interface HelpCopeState {
  isLoading: boolean;
  error: Error | null;
  data: HelpCope[];
}

type HelpCopeAction =
  | { type: 'request/getHelpCopeList' }
  | { type: 'success/getHelpCopeList'; payload: HelpCope[] }
  | { type: 'reject/getHelpCopeList'; payload: Error };

function reducer(state: HelpCopeState, action: HelpCopeAction): HelpCopeState {
  switch (action.type) {
    case 'request/getHelpCopeList':
      return { ...state, isLoading: true };
    case 'success/getHelpCopeList':
      return { ...state, isLoading: false, data: action.payload };
    case 'reject/getHelpCopeList':
      return { ...state, isLoading: false, error: action.payload };
    default:
      throw new Error('Action type does not exists on Help Cope Slice');
  }
}

export default function useHelpCopeSlice(filter: {
  cohort_id?: number;
  user_uuid?: string;
  is_deleted?: boolean;
}) {
  const [state, dispatch] = React.useReducer(reducer, {
    isLoading: false,
    error: null,
    data: [],
  });

  const { cohort_id, user_uuid, is_deleted } = filter;

  const { enqueueSnackbar } = useSnackbar();

  const refresh = React.useCallback(async () => {
    try {
      if (
        cohort_id === undefined &&
        user_uuid === undefined &&
        is_deleted === undefined
      ) {
        const error = new Error(
          'useHelpCopeSlice(filter): filter param must have at least one filter defined'
        );
        dispatch({
          type: 'reject/getHelpCopeList',
          payload: error,
        });
        throw error;
      } else {
        dispatch({ type: 'request/getHelpCopeList' });
        const helpCopeList = await getHelpCopeListAPI({
          cohort_id,
          user_uuid,
          is_deleted,
        });
        dispatch({ type: 'success/getHelpCopeList', payload: helpCopeList });
      }
    } catch (reason) {
      const error = new Error(reason);
      dispatch({
        type: 'reject/getHelpCopeList',
        payload: error,
      });
      enqueueSnackbar(reason, { variant: 'error' });
      throw error;
    }
  }, [dispatch, cohort_id, user_uuid, is_deleted, enqueueSnackbar]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return { state, actions: { refresh } };
}
