import { useSnackbar } from 'notistack';
import React from 'react';

import {
  createScheduledEventAPI,
  getFilteredScheduledEventsAPI,
  updateScheduledEventAPI,
} from '../schedule/scheduleAPI';
import Meeting from './meeting';
import {
  createMeetingAPI,
  CreateMeetingArgs,
  deleteMeetingAPI,
  getMeetingsListAPI,
  updateMeetingAPI,
} from './meetingAPI';

interface MeetingState {
  isLoading: boolean;
  error: Error | null;
  data: Meeting[];
}

export default function useMeetingsSlice(filter?: {
  topic_id?: number;
  cohort_id?: number;
}) {
  const [state, setState] = React.useState<MeetingState>({
    isLoading: false,
    error: null,
    data: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  const cohort_id = filter?.cohort_id;
  const topic_id = filter?.topic_id;

  const createMeeting = React.useCallback(
    async (args: CreateMeetingArgs) => {
      try {
        setState(prevState => ({ ...prevState, isLoading: true }));
        const newMeeting = await createMeetingAPI(args);
        const end = new Date(args.start_time!);
        end.setMinutes(end.getMinutes() + newMeeting.details.duration);
        await createScheduledEventAPI({
          resource_type: 'meeting',
          resource_id: newMeeting.id.toString(),
          start_at: args.start_time,
          end_at: end.toISOString(),
          invitees: [{ invitee_id: cohort_id, invitee_type: 'cohort' }],
        });
        setState(prevState => ({
          ...prevState,
          isLoading: false,
          data: [...prevState.data, newMeeting],
        }));
        enqueueSnackbar('Meeting Created', { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        setState(prevState => ({
          ...prevState,
          isLoading: false,
          error: error,
        }));
        enqueueSnackbar(reason, { variant: 'error' });
        throw error;
      }
    },
    [setState, enqueueSnackbar, cohort_id]
  );

  const updateMeeting = React.useCallback(
    async (id: number, args: CreateMeetingArgs) => {
      try {
        setState(prevState => ({ ...prevState, isLoading: true }));
        const updatedMeeting = await updateMeetingAPI(id, args);
        const meetingEvents = await getFilteredScheduledEventsAPI({
          resource_type: 'meeting',
          resource_id: updatedMeeting.id.toString(),
        });
        const end = new Date(args.start_time!);
        end.setMinutes(end.getMinutes() + updatedMeeting.details.duration);
        await updateScheduledEventAPI(meetingEvents[0].id, {
          start_at: args.start_time,
          end_at: end.toISOString(),
        });
        setState(prevState => {
          const index = prevState.data.findIndex(
            meeting => meeting.id === updatedMeeting.id
          );
          if (index > -1) {
            return {
              ...prevState,
              isLoading: false,
              data: [
                ...prevState.data.slice(0, index),
                updatedMeeting,
                ...prevState.data.slice(index + 1),
              ],
            };
          } else return { ...prevState, isLoading: false };
        });
        enqueueSnackbar('Meeting UPDATED', { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        setState(prevState => ({
          ...prevState,
          isLoading: false,
          error: error,
        }));
        enqueueSnackbar(reason, { variant: 'error' });
        throw error;
      }
    },
    [setState, enqueueSnackbar]
  );

  const deleteMeeting = React.useCallback(
    async (id: number) => {
      try {
        setState(prevState => ({ ...prevState, isLoading: true }));
        await deleteMeetingAPI(id.toString());
        await getFilteredScheduledEventsAPI({
          resource_type: 'meeting',
          resource_id: id.toString(),
        });

        setState(prevState => ({
          ...prevState,
          isLoading: false,
        }));
      } catch (reason) {
        const error = new Error(reason);
        setState(prevSate => ({
          ...prevSate,
          isLoading: false,
          error: error,
        }));
        enqueueSnackbar(reason, { variant: 'error' });
        throw error;
      }
    },
    [setState, enqueueSnackbar]
  );

  const refresh = React.useCallback(async () => {
    try {
      setState(prevState => ({ ...prevState, isLoading: true }));
      const _meetings = await getMeetingsListAPI({ cohort_id, topic_id });
      setState(prevState => ({
        ...prevState,
        isLoading: false,
        data: _meetings,
      }));
    } catch (reason) {
      const error = new Error(reason);
      setState(prevState => ({
        ...prevState,
        isLoading: false,
        error: error,
      }));
      enqueueSnackbar(reason, { variant: 'error' });
      throw error;
    }
  }, [cohort_id, topic_id, setState, enqueueSnackbar]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    state,
    actions: { createMeeting, updateMeeting, deleteMeeting, refresh },
  };
}
