import { Box } from '@material-ui/core';
import MaterialTable from 'material-table';
import React from 'react';

import SectionHeading from '../../components/SectionHeading';
import tableIcons from '../../config/tableIcons';
import User from '../user/user';
import useTrustedPeopleSlice from './useTrustedPeopleSlice';
import RefreshIcon from '@material-ui/icons/Refresh';

interface TrustedPeopleTableProps {
  user: User;
}

export default function TrustedPeopleTable(props: TrustedPeopleTableProps) {
  const { user } = props;
  const trustedPeopleSlice = useTrustedPeopleSlice(user);
  const {
    data: trustedPeople,
    /* error, */ isLoading,
  } = trustedPeopleSlice.state;
  const { refresh } = trustedPeopleSlice.actions;

  return (
    <MaterialTable
      isLoading={isLoading}
      icons={tableIcons}
      components={{ Container: Box }}
      title={<SectionHeading heading="Trusted People" />}
      columns={[
        { title: 'Name', field: 'name' },
        { title: 'Email', field: 'email' },
        { title: 'Cell Phone #', field: 'cellPhone' },
        { title: 'Home Phone #', field: 'homePhone' },
      ]}
      data={trustedPeople.map(person => ({
        name: `${person.first_name} ${person.last_name}`,
        email: person.email,
        cellPhone: person.cell_phone,
        homePhone: person.home_phone,
      }))}
      actions={[
        {
          icon: RefreshIcon,
          tooltip: 'Refresh Data',
          isFreeAction: true,
          onClick: () => refresh(),
        },
      ]}
      options={{
        exportButton: true,
        exportAllData: true,
        exportFileName: `${user.study_id}_trusted_people`,
      }}
    />
  );
}
