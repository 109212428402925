import React from 'react';
import CheckInPrompt from './checkInPrompt';
import { useSnackbar } from 'notistack';
import { getCheckInPromptsAPI, updateCheckInPromptsAPI } from './checkInAPI';

interface CheckInPromptsState {
  isLoading: boolean;
  error: Error | null;
  data: CheckInPrompt[];
}

export default function useCheckInPromptsSlice(type: string) {
  const [state, setState] = React.useState<CheckInPromptsState>({
    isLoading: false,
    error: null,
    data: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  const updateCheckInPrompts = React.useCallback(
    async (prompts: Omit<CheckInPrompt, 'type'>[]) => {
      try {
        setState((prev) => ({ ...prev, isLoading: true }));
        const updatedPrompts = await updateCheckInPromptsAPI(type, {
          check_in_prompts: prompts,
        });
        setState((prev) => ({
          ...prev,
          isLoading: false,
          data: updatedPrompts,
        }));
        enqueueSnackbar('Check-in Prompts UPDATED', { variant: 'success' });
      } catch (reason) {
        const error = new Error(reason);
        setState((prev) => ({ ...prev, isLoading: false, error }));
        enqueueSnackbar(reason, { variant: 'error' });
      }
    },
    [type, setState, enqueueSnackbar]
  );

  const refresh = React.useCallback(async () => {
    if (type === '') return;
    try {
      setState((prev) => ({ ...prev, isLoading: true }));
      const checkInPrompts = await getCheckInPromptsAPI(type);
      setState((prev) => ({ ...prev, isLoading: false, data: checkInPrompts }));
    } catch (reason) {
      const error = new Error(reason);
      setState((prev) => ({ ...prev, isLoading: false, error }));
      enqueueSnackbar(reason, { variant: 'error' });
    }
  }, [setState, type, enqueueSnackbar]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return { state, actions: { refresh, updateCheckInPrompts } };
}
